// Models
import { MatrixInterface } from '../../../../../../models/student';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

// Assets
import Abduction_1 from '../../../../../assets/img/sequenceTargets/abduction_1.png';//-1
import Abduction_2 from '../../../../../assets/img/sequenceTargets/abduction_2.png';//1
import Extension_1 from '../../../../../assets/img/sequenceTargets/extension_1.png';//-1
import Extension_2 from '../../../../../assets/img/sequenceTargets/extension_2.png';//1
import Lateral_1 from '../../../../../assets/img/sequenceTargets/lateral_1.png';//-1
import Lateral_2 from '../../../../../assets/img/sequenceTargets/lateral_2.png';//1
import Muscular_1 from '../../../../../assets/img/sequenceTargets/muscular_1.png';//0
import Muscular_2 from '../../../../../assets/img/sequenceTargets/muscular_3.png';//-1
import Muscular_3 from '../../../../../assets/img/sequenceTargets/muscular_2.png';//1

// Interfaces
interface SequenceTargetsPropsInterface {
    matrix: MatrixInterface
};

const SequenceTargets = ({ matrix }: SequenceTargetsPropsInterface) => {

    const setTextIndicationsByType = (type: keyof MatrixInterface) => I18n.t(`students.sequenceTargetsTexts.${ type }#${ matrix[type] }`);

    return <div className='practice-target-grid'>
        <div className='item withoutImage'>
            <div className='item-content'>
                <p className='text'>{ I18n.t('students.sequenceTargetsTexts.firstPreambule') }</p>
                <p className='secondary-text'>{ setTextIndicationsByType('flexoextensionIndicator') }</p>
            </div>
        </div>
        <div className='item withImage'>
            <div className='item-content'>
                <div className='w-image'>
                    { [-1, 0].includes(matrix.flexoextensionIndicator) && <img src={ Extension_1 } alt='' /> }
                    { [1, 0].includes(matrix.flexoextensionIndicator) && <img src={ Extension_2 } alt='' /> }
                </div>
            </div>
        </div>
        <div className='item withoutImage greyBackground'>
            <div className='item-content'>
                <p className='text'>{ I18n.t('students.sequenceTargetsTexts.secondPreambule') }</p>
                <p className='secondary-text'>{ setTextIndicationsByType('lateralizationIndicator') }</p>
            </div>
        </div>
        <div className='item withImage greyBackground'>
            <div className='item-content'>
                <div className='w-image'>
                    { [-1, 0].includes(matrix.lateralizationIndicator) && <img src={ Lateral_1 } alt='' /> }
                    { [1, 0].includes(matrix.lateralizationIndicator) && <img src={ Lateral_2 } alt='' /> }
                </div>
            </div>
        </div>
        <div className='item withoutImage'>
            <div className='item-content'>
                <p className='text'>{ I18n.t('students.sequenceTargetsTexts.thirdPreambule') }</p>
                <p className='secondary-text'>{ setTextIndicationsByType('abductionAductionIndicator') }</p>
            </div>
        </div>
        <div className='item withImage'>
            <div className='item-content'>
                <div className='w-image'>
                    { [-1, 0].includes(matrix.abductionAductionIndicator) && <img src={ Abduction_1 } alt='' /> }
                    { [1, 0].includes(matrix.abductionAductionIndicator) && <img src={ Abduction_2 } alt='' /> }
                </div>
            </div>
        </div>
        <div className='item withoutImage greyBackground'>
            <div className='item-content'>
                <p className='text'>{ I18n.t('students.sequenceTargetsTexts.fourthPreambule') } </p>
                <p className='secondary-text'>{ setTextIndicationsByType('muscularTrainIndicator') }</p>
            </div>
        </div>
        <div className='item withImage greyBackground'>
            <div className='item-content'>
                <div className='w-image'>
                    <img src={ matrix.muscularTrainIndicator === 0 ? Muscular_1 : matrix.muscularTrainIndicator ? Muscular_2 : Muscular_3 } alt='' />
                </div>
            </div>
        </div>
    </div>;

};

export default SequenceTargets;