// Third party libraries
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Field } from 'redux-form';
import { Icon } from 'semantic-ui-react';

// Components
import InputFile from '../form/inputFile';

// Styled components
import OButton from '../../styled/button';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Assets
import DemoImage from '../../assets/img/yogabot_icon.svg';

export enum MultimediaTypes {
    IMAGE = 'image',
    VIDEO = 'video'
};

type Props = {
    formFieldName: string,
    type: MultimediaTypes,
    src?: string,
    onRemove: (id: string) => void
};

const MultimediaManage = ({ formFieldName, type, src, onRemove }: Props) => {

    const getSrcForPreview = () => !_.isEmpty(_.get(window, formFieldName + '.tempFile.raw', '')) ? _.get(window, formFieldName + '.tempFile.raw', '') : !_.isEmpty(src) ? src : DemoImage;

    const [thumbSrc, setThumbSrc] = useState<string>(getSrcForPreview());

    useEffect(() => setThumbSrc(getSrcForPreview()), [src]);

    const remove = () => {

        _.set(window, formFieldName, undefined);
        onRemove(formFieldName);

    };

    switch (type) {

        case MultimediaTypes.IMAGE:

            return (
                <div className='pictureContent'>
                    <div className='picture'>
                        <img src={ thumbSrc } alt='' onError={ e => _.set(e, 'target.src', DemoImage) }/>
                    </div>
                    <div className='buttons'>
                        <OButton $terciary type='button' $color='#fff' size='small'>
                            <span>{ _.isEmpty(src) ? I18n.t('buttons.actions.addImage') : I18n.t('buttons.actions.change') }</span>
                            <Field className='hide-input' component={ InputFile } name={ formFieldName } accept='image/*' onChangeValue={ (newThumbSrc: string) => setThumbSrc(newThumbSrc) } />
                        </OButton>
                        { !_.isEmpty(src) &&
                            <OButton $primary selected type='button' $color='#FF6767' size='small' onClick={ remove }>
                                <span>{ I18n.t('buttons.actions.remove') }</span>
                            </OButton>
                        }
                    </div>
                    <div className='recommendedSizeAndFormat'>
                        <Icon name='info circle' />
                        <span>{ I18n.t('validations.recommendedSizeAndFormatImage') }</span>
                    </div>
                </div>
            );

        case MultimediaTypes.VIDEO:

            return (
                <div className='videoContent'>
                    <div className='video'>
                        <video src={ thumbSrc } controls controlsList='nodownload noremoteplayback'>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className='buttons'>
                        <OButton $terciary type='button' $color='#fff' size='small'>
                            <span>{ _.isEmpty(src) ? I18n.t('buttons.actions.addVideo') : I18n.t('buttons.actions.change') }</span>
                            <Field className='hide-input' component={ InputFile } name={ formFieldName } accept='video/*' onChangeValue={ (newThumbSrc: string) => setThumbSrc(newThumbSrc) } />
                        </OButton>
                        { !_.isEmpty(src) &&
                            <OButton $primary selected type='button' $color='#FF6767' size='small' onClick={ remove }>
                                <span>{ I18n.t('buttons.actions.remove') }</span>
                            </OButton>
                        }
                    </div>
                    <div className='recommendedSizeAndFormat'>
                        <Icon name='info circle' />
                        <span>{ I18n.t('validations.recommendedSizeAndFormatVideo') }</span>
                    </div>
                </div>
            );

        default:

            return <></>;

    }

};

export default MultimediaManage;