// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Divider, Dropdown, Icon } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Models
import SequenceModel, { AsanaInterface, NewSequenceInterface, RoutineItemInterface, SequenceInterface } from '../../../../../models/sequence';
import StudentModel from '../../../../../models/student';

// Components
import BottomNav from '../../../../components/bottomNav/BottomNav';
import CloneSequenceModalBody from '../../components/cloneSequenceModalBody';
import DurationSelector, { DURATION_SELECT_OPTIONS } from './components/durationSelector';
import GenericModal, { GenericModalProps } from '../../../../components/genericModal';
import List, { ItemInterface, ItemActionInterface } from '../../../../components/list';
import PanelNav from '../../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface, FnInterface } from '../../../../components/nav/SecondarySubmenu';

// Styled components
import { Wrapper } from '../../../../styled/wrappers';
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MainDataInterface {
    sequence: SequenceInterface;
    breadcrumbs: BreadcrumbsInterface[];
    asanas: AsanaInterface[];
};

const SequenceEditRoutine = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mainData, setMainData] = useState<MainDataInterface>({
        sequence: {
            _id: '' + params.sequenceId,
            asanasCount: 0,
            createdAt: '',
            published: false,
            routine: [],
            targets: [],
            types: [],
            categories: [],
            description: { ES: '' },
            title: { ES: '' },
            level: ''
        },
        breadcrumbs: [],
        asanas: []
    });

    const [routine, setRoutine] = useState<ItemInterface[]>([]);

    const [editFns, setEditFns] = useState<{ secondaryFn: FnInterface, itemComponents: { render: (item: ItemInterface) => JSX.Element }[]; itemFns: ItemActionInterface[] }>({
        secondaryFn: { callback: () => {}, label: I18n.t('buttons.actions.edit') },
        itemComponents: [],
        itemFns: []
    });

    const [modal, setModal] = useState<GenericModalProps>({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const [isCustomPractice] = useState<boolean>(_.get(params, 'sequenceId', '').includes('custom_'));

    useEffect(() => {

        const getData = async () => {

            try {

                dispatch(openLoader());

                const data = _.cloneDeep(mainData);
                if (isCustomPractice) {

                    const sequenceId = _.get(params, 'sequenceId', '');
                    const durationId = sequenceId.substring(sequenceId.indexOf('_') + 1, sequenceId.indexOf(':'));
                    const studentId = sequenceId.substring(sequenceId.indexOf(':') + 1);

                    const sequenceCode = _.get(await StudentModel.getStudentBiometricMatrix(studentId), 'sequence', '');

                    data.sequence._id = durationId + sequenceCode + '_01';

                }

                const sequence = await SequenceModel.getSequence(data.sequence._id);

                if (isCustomPractice) {

                    _.each(sequence.routine, (exercise: any) => {

                        exercise.code = exercise.asanaCode;
                        delete exercise.asanaCode;

                    });

                }

                data.sequence = sequence;

                data.breadcrumbs = [{
                    key: 0,
                    label: I18n.t('menu.sequences'),
                    goTo: '/sequences'
                },
                {
                    key: 1,
                    label: isCustomPractice ? I18n.t('sequences.customPractice') : sequence.title.ES,
                    goTo: isCustomPractice ? '' : '/sequences/edit/' + data.sequence._id
                },
                {
                    key: 2,
                    label: I18n.t('sequences.exercises'),
                    goTo: ''
                }];

                data.asanas = await SequenceModel.getAsanas();
                const routineData: ItemInterface[] = [];

                _.each(sequence.routine, (exercise, index) => {

                    const asana = _.find(data.asanas, asana => asana.code === exercise.code);

                    routineData.push({
                        _id: '' + index,
                        header: '' + asana?.name.ES,
                        selectable: false,
                        selected: false,
                        draggable: !isCustomPractice,
                        description: exercise.duration + ' ' + I18n.t('sequences.seconds'),
                        pictureUrl: asana?.image,
                        extraData: {
                            code: exercise.code,
                            duration: exercise.duration
                        }
                    });

                });

                setMainData(data);
                setRoutine(routineData);

                dispatch(closeLoader());

            } catch (e) {

                dispatch(closeLoader());
                setModal({
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                });

            }

        };

        getData();

    }, []);

    useEffect(() => {

        setEditFns({
            secondaryFn: { activeOnDesktop: true, callback: isCustomPractice ? onCloneCustomSequence : onSave, label: I18n.t(isCustomPractice ? 'sequences.saveAsOwn' : 'buttons.actions.save'), disabled: !isCustomPractice && _.isEqual(mainData.sequence.routine, listRoutineToSequenceRoutine(routine)) },
            itemComponents: isCustomPractice ? [] : [{
                render: (item: ItemInterface) =>
                    <Dropdown
                        search={ true }
                        options={ DURATION_SELECT_OPTIONS }
                        value={ item.extraData.duration }
                        selection
                        onChange={ (e, { value }) => changeDuration(+(value || 0), item) }
                        onBlur={ (e, { value }) => changeDuration(+(value || 0), item) }
                    />
            }],
            itemFns: [
                { disableOnMobile: (item: ItemInterface) => isCustomPractice || !!item.selected, disableOnDesktop: () => true, callback: onSelect, icon: 'edit' },
                { disableOnMobile: (item: ItemInterface) => isCustomPractice || !!!item.selected, disableOnDesktop: () => true, callback: onChangeDuration, icon: 'clock outline' },
                { disableOnMobile: (item: ItemInterface) => isCustomPractice || !!!item.selected, disableOnDesktop: () => isCustomPractice, callback: onClone, icon: 'copy outline' },
                { disableOnMobile: (item: ItemInterface) => isCustomPractice || !!!item.selected, disableOnDesktop: () => isCustomPractice, callback: onRemove, icon: 'trash alternate outline' },
                { disableOnMobile: (item: ItemInterface) => isCustomPractice || !!!item.selected, disableOnDesktop: () => true, callback: onSelect, icon: 'close' }
            ]
        });

    }, [routine]);

    const onSelect = (item: ItemInterface) => {

        const routineData = [ ...routine ];
        _.each(routineData, exercise => {

            if (exercise._id === item._id) {

                exercise.selected = !item.selected;

            } else {

                exercise.selected = false;

            }

        });
        setRoutine(routineData);

    };

    const onRemove = (item: ItemInterface) => {

        const removeItem = () => {

            closeModal();

            const routineData = [ ...routine ];

            routineData.splice(parseInt(item._id), 1);

            _.each(routineData, (exercise, index) => exercise._id = '' + index);

            setRoutine(routineData);

        };

        setModal({
            open: true,
            title: I18n.t('buttons.actions.confirm'),
            renderBody: () => <span>{ I18n.t('sequences.removeAsanaConfirmMessage') }</span>,
            renderActions: () => <>
                <OButton type='button' $color='black' $primary onClick={ closeModal }>
                    <span>{ I18n.t('buttons.actions.cancel') }</span>
                </OButton>
                <OButton type='button' $color='white' $terciary onClick={ removeItem }>
                    <span>{ I18n.t('buttons.actions.ok') }</span>
                </OButton>
            </>
        });

    };

    const onClone = (item: ItemInterface) => {

        const data = _.cloneDeep(mainData);
        const routineData = _.cloneDeep(routine);
        routineData.splice(parseInt(item._id), 0, item);
        _.each(routineData, (exercise, index) => {

            exercise._id = '' + index;
            exercise.selected = false;

        });
        const newRoutine: RoutineItemInterface[] = listRoutineToSequenceRoutine(routineData);
        data.sequence.routine = newRoutine;

        setRoutine(routineData);

    };

    const onChangeDuration = (item: ItemInterface) => {

        setModal({
            open: true,
            title: item.header,
            renderBody: () => <DurationSelector initialDuration={ item.extraData.duration } onChangeDuration={ (duration: number) => changeDuration(duration, item) } />
        });

    };

    const changeDuration = (duration: number, item: ItemInterface) => {

        closeModal();

        const routineData = [ ...routine ];
        routineData[parseInt(item._id)].extraData.duration = duration;
        routineData[parseInt(item._id)].description = duration + ' ' + I18n.t('sequences.seconds');
        setRoutine(routineData);

    };

    const onChangeOrder = (droppedItem: any) => {

        if (!droppedItem.destination) {

            return;

        }

        const routineData = [ ...routine ];
        const [ reorderedItem ] = routineData.splice(droppedItem.source.index, 1);
        routineData.splice(droppedItem.destination.index, 0, reorderedItem);
        _.each(routineData, (exercise, index) => {

            exercise._id = '' + index;
            exercise.selected = false;

        });

        setRoutine(routineData);

    };

    const onSave = async () => {

        dispatch(openLoader());

        const data = { ...mainData };
        const newRoutine: RoutineItemInterface[] = listRoutineToSequenceRoutine(routine);
        data.sequence.routine = newRoutine;

        setMainData(data);

        await SequenceModel.updateSequence(mainData.sequence._id, data.sequence);

        dispatch(closeLoader());

    };

    const onCloneCustomSequence = () => {

        const cloneCustomSequence = async (newSequence: NewSequenceInterface) => {

            closeModal();

            dispatch(openLoader());

            try {

                const sequenceCreated = await SequenceModel.createSequence(newSequence);
                sequenceCreated.routine = mainData.sequence.routine;
                await SequenceModel.updateSequence(sequenceCreated._id, sequenceCreated);
                navigate('/sequences');

            } catch (error) {

                setModal({
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                });

            }

            dispatch(closeLoader());
        };

        const newSequence: NewSequenceInterface = {
            categories: [],
            description: { ES: '' },
            level: '01',
            title: { ES: '' }
        };

        setModal({
            open: true,
            title: I18n.t('buttons.actions.clone'),
            renderBody: () => <><span>{ I18n.t('sequences.cloneCustomSequenceModalText') }</span><CloneSequenceModalBody baseSequence={ newSequence } onSubmit={ cloneCustomSequence } /></>
        });

    };

    const listRoutineToSequenceRoutine = (routineModified: ItemInterface[]): RoutineItemInterface[] => {

        const newRoutine: RoutineItemInterface[] = [];
        _.each(routineModified, exercise => newRoutine.push({ code: exercise.extraData.code, duration: exercise.extraData.duration }));

        return newRoutine;

    };

    const goBack = () => navigate(isCustomPractice ? `/students/edit/${ _.get(params, 'sequenceId', '').substring(_.get(params, 'sequenceId', '').indexOf(':') + 1) }` : `/sequences/edit/${ mainData.sequence._id }`);

    const closeModal = () => setModal({ open: false, title: '', renderBody: () => <></>, renderActions: undefined });

    const getRoutineDuration = (): number => {

        let duration = 0;
        _.each(routine, exercise => duration += exercise.extraData.duration);
        return _.round(duration / 60);

    }

    return (
        <Wrapper>
            <GenericModal
                open={ modal.open }
                title={ modal.title }
                renderBody={ modal.renderBody }
                renderActions={ modal.renderActions }
                onClose={ closeModal }
            />
            <PanelNav active='sequences' />
            <Container style={{ paddingTop: '20px' }}>
                <SecondarySubmenu
                    title={ I18n.t('sequences.exercises') }
                    mainFn={{ callback: goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                    breadcrumbs={ mainData.breadcrumbs }
                    secondaryFn={ editFns.secondaryFn }
                />
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='exerciseList'>
                                        { _.size(routine) === 0 &&
                                            <div className='exercisesEmpty'>
                                                <Icon name='info circle' />
                                                <span>{ I18n.t('sequences.exercisesEmpty') }</span>
                                            </div>
                                        }
                                        { _.size(routine) > 0 &&
                                            <>
                                                <div className='sequenceDuration'>
                                                    <span>{ I18n.t('sequences.duration') + ': ' + getRoutineDuration() + ' ' + I18n.t('sequences.minutes') }</span>
                                                </div>
                                                <Divider />
                                            </>
                                        }
                                        <List
                                            draggableList={ !isCustomPractice }
                                            editMode={ false }
                                            items={ routine }
                                            itemComponents={ editFns.itemComponents }
                                            itemFns={ editFns.itemFns }
                                            onDropItemFn={ onChangeOrder }
                                        />
                                        { !isCustomPractice && <>
                                            <Divider />
                                            <div className='addExercise'>
                                                <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ () => navigate(`/sequences/edit/${ mainData.sequence._id }/asanas/add`) }>
                                                    <span>{ I18n.t('sequences.addExercises') }</span>
                                                </OButton>
                                            </div>
                                            <Divider />
                                        </> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='sequences' />
        </Wrapper>
    );

}

export default SequenceEditRoutine;