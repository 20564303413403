// Third party libraries
import { useState } from 'react';
import { Container, Image } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

// Components
import Countdown from './components/countdown';
import Faq from './components/faq';
import Functionality from './components/functionality';
import LandingTopBar from './components/topBar';
import PlanCard from './components/planCard';

// Styled components
import { Wrapper } from '../../styled/wrappers';
import OButton from '../../styled/button';

// Models
import { PeriodicityEnum, PlansEnum } from '../../../models/payment';

// Services
import PaymentPlansService from '../../../utils/PaymentPlansService';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Assets
import Achievement from '../../assets/img/landing/achievement.png';
import Automatization from '../../assets/img/landing/automatization.png';
import BenefitsAsana from '../../assets/img/landing/benefitsAsana.png';
import Engagement from '../../assets/img/landing/engagement.png';
import FlexibilityYourBusiness from '../../assets/img/landing/flexibilityYourBusiness.png';
import Four from '../../assets/img/landing/four.png';
import GainConfidence from '../../assets/img/landing/gainConfidence.png';
import GroupLessons from '../../assets/img/landing/groupLessons.png';
import Growth from '../../assets/img/landing/growth.png';
import GrowthUpValue from '../../assets/img/landing/growthUpValue.png';
import HeaderBackground from '../../assets/img/landing/mainHeader.png';
import HeaderScreenshot from '../../assets/img/landing/appScreenshot1.png';
import IncreaseIncome from '../../assets/img/landing/increaseIncome.png';
import One from '../../assets/img/landing/one.png';
import Organization from '../../assets/img/landing/organization.png';
import PedagogicalManagement from '../../assets/img/landing/pedagogicalManagement.png';
import PowerUp from '../../assets/img/landing/powerUp.png';
import PrivateLessons from '../../assets/img/landing/privateLessons.png';
import SaveTime from '../../assets/img/landing/saveTime.png';
import SequenceBuilder from '../../assets/img/landing/sequenceBuilder.png';
import StudentData from '../../assets/img/landing/studentData.png';
import Three from '../../assets/img/landing/three.png';
import Two from '../../assets/img/landing/two.png';
import YogabotProApp from '../../assets/img/landing/yogabotProApp.png';
import YogabotUserApp from '../../assets/img/landing/yogabotUserApp.png';
import YogaCenter from '../../assets/img/landing/yogaCenter.png';
import WorkshopsBackground from '../../assets/img/landing/workshopsBackground.png';
import { Link } from 'react-router-dom';

const Landing = () => {

    const navigate = useNavigate();

    const [activeFaqQuestion, setActiveFaqQuestion] = useState<number>(0);
    const [countdownExpired, setCountdownExpired] = useState<boolean>(false);
    const [planPeriodicity, setPlanPeriodicity] = useState<PeriodicityEnum>(PeriodicityEnum.Annual);
    const [promotionExpireDate] = useState<string>(PaymentPlansService.getPromotionExpireDateString());

    return <Wrapper className='landing'>
        <LandingTopBar />
        <Container>
            <div className='header'>
                <Image className='headerBackground' src={ HeaderBackground } />
                <div className='headerText'>
                    <span className='title'>{ I18n.t('landing.headerTitle') }</span>
                <br />
                    <span className='text'>{ I18n.t('landing.headerText') }</span>
                    <OButton $terciary type='button' $color='#fff' className='headerButton' onClick={ () => navigate('/auth/register') }>
                        <span>{ I18n.t('landing.checkFree') }</span>
                    </OButton>
                </div>
                <Image className='headerScreenshot' src={ HeaderScreenshot } />
            </div>
            <div className='whatIsYogabot'>
                <span className='title'>{ I18n.t('landing.whatIsYogabot') }</span>
                <br />
                <br />
                <span className='text'>{ I18n.t('landing.yogabotProfessionalIs') }</span>
                <div className='apps'>
                    <div className='app'>
                        <Image src={ YogabotProApp } />
                        <div className='appText'>
                            <span className='title'>{ I18n.t('landing.yogabotProfessionalAppTitle') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.yogabotProfessionalAppText') }</span>
                        </div>
                    </div>
                    <div className='app'>
                        <Image src={ YogabotUserApp } />
                        <div className='appText'>
                            <span className='title'>{ I18n.t('landing.yogabotUserAppTitle') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.yogabotUserAppText') }</span>
                        </div>
                    </div>
                </div>
                <span className='text'><Trans i18nKey='landing.whitYogabotProfessional'><strong>NOT ERASE</strong></Trans></span>
            </div>
            <div className='functionalities' id='functionalities'>
                <span className='title'>{ I18n.t('landing.functionalitiesTitle') }</span>
                <br />
                <br />
                <span className='text'>{ I18n.t('landing.functionalitiesText') }</span>
                <Functionality
                    image={ StudentData }
                    imageOnLeft={ true }
                    text={ I18n.t('landing.specializedFileText') }
                    title={ I18n.t('landing.specializedFile') }
                />
                <Functionality
                    image={ GainConfidence }
                    text={ I18n.t('landing.gainConfidenceText') }
                    title={ I18n.t('landing.gainConfidence') }
                />
                <Functionality
                    image={ SequenceBuilder }
                    imageOnLeft={ true }
                    text={ I18n.t('landing.sequenceBuilderText') }
                    title={ I18n.t('landing.sequenceBuilder') }
                />
                <Functionality
                    image={ Organization }
                    text={ I18n.t('landing.organizationText') }
                    title={ I18n.t('landing.organization') }
                />
                <Functionality
                    image={ Automatization }
                    imageOnLeft={ true }
                    text={ I18n.t('landing.automatizationText') }
                    title={ I18n.t('landing.automatization') }
                />
            </div>
        </Container>
        <div className='engage'>
            <Container>
                <span className='title'>{ I18n.t('landing.engage') }</span>
                <br />
                <br />
                <span className='text'>{ I18n.t('landing.engageText') }</span>
                <div className='advantages'>
                    <div className='advantage'>
                        <Image src={ Achievement } />
                        <div className='advantageText'>
                            <span className='title'>{ I18n.t('landing.achievement') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.achievementText') }</span>
                        </div>
                    </div>
                    <div className='advantage'>
                        <Image src={ Growth } />
                        <div className='advantageText'>
                            <span className='title'>{ I18n.t('landing.growth') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.growthText') }</span>
                        </div>
                    </div>
                    <div className='advantage'>
                        <Image src={ Engagement } />
                        <div className='advantageText'>
                            <span className='title'>{ I18n.t('landing.engagement') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.engagementText') }</span>
                        </div>
                    </div>
                </div>
                <span className='text'>{ I18n.t('landing.engageResult') }</span>
            </Container>
        </div>
        <Container className='increaseIncomeContainer'>
            <div className='increaseIncome'>
                <span className='title'>{ I18n.t('landing.increaseIncome') }</span>
                <br />
                <br />
                <span className='text'>{ I18n.t('landing.increaseIncomeText') }</span>
                <br />
                <span className='text'>{ I18n.t('landing.increaseIncomeText2') }</span>
                <div className='lessons'>
                    <div className='lesson'>
                        <Image src={ PrivateLessons } />
                        <div className='lessonText'>
                            <span className='title'>{ I18n.t('landing.privateLessons') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.privateLessonsText') }</span>
                        </div>
                    </div>
                    <div className='lesson'>
                        <Image src={ GroupLessons } />
                        <div className='lessonText'>
                            <span className='title'>{ I18n.t('landing.groupLessons') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.groupLessonsText') }</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <div className='benefitsAsanaContainer'>
            <Image className='benefitsAsana' src={ BenefitsAsana } />
        </div>
        <Container className='benefitsAndHowItWorksContainer'>
            <div className='benefits'>
                <span className='title'>{ I18n.t('landing.benefits') }</span>
                <div className='benefitList'>
                    <div className='benefit'>
                        <Image src={ PedagogicalManagement } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.pedagogicalManagement') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.pedagogicalManagementText') }</span>
                        </div>
                    </div>
                    <div className='benefit'>
                        <Image src={ FlexibilityYourBusiness } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.flexibilityYourBusiness') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.flexibilityYourBusinessText') }</span>
                        </div>
                    </div>
                    <div className='benefit'>
                        <Image src={ GrowthUpValue } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.growthUpValue') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.growthUpValueText') }</span>
                        </div>
                    </div>
                    <div className='benefit'>
                        <Image src={ PowerUp } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.powerUp') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.powerUpText') }</span>
                        </div>
                    </div>
                    <div className='benefit'>
                        <Image src={ SaveTime } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.saveTime') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.saveTimeText') }</span>
                        </div>
                    </div>
                    <div className='benefit'>
                        <Image src={ IncreaseIncome } />
                        <div className='benefitText'>
                            <span className='title'>{ I18n.t('landing.increaseYourIncome') }</span>
                            <br />
                            <span className='text'>{ I18n.t('landing.increaseYourIncomeText') }</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='howItWorks'>
                <span className='title'>{ I18n.t('landing.howItWorks') }</span>
                <div className='howItWorkList'>
                    <div className='howItWorkItem'>
                        <Image src={ One } />
                        <div className='howItWorkText'>
                            <span className='title'>{ I18n.t('landing.howItWorksText1') }</span>
                        </div>
                    </div>
                    <div className='howItWorkItem'>
                        <Image src={ Two } />
                        <div className='howItWorkText'>
                            <span className='title'>{ I18n.t('landing.howItWorksText2') }</span>
                        </div>
                    </div>
                    <div className='howItWorkItem'>
                        <Image src={ Three } />
                        <div className='howItWorkText'>
                            <span className='title'>{ I18n.t('landing.howItWorksText3') }</span>
                        </div>
                    </div>
                    <div className='howItWorkItem'>
                        <Image src={ Four } />
                        <div className='howItWorkText'>
                            <span className='title'>{ I18n.t('landing.howItWorksText4') }</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <div className='pricePlans' id='rates'>
            <span className='title'>{ I18n.t('landing.specialOffer') }</span>
            <span className='title'>{ I18n.t('landing.specialOfferExpireOn') }</span>
            <Countdown expireDate={ promotionExpireDate } onExpireCallback={ () => setCountdownExpired(true) } />
            <span className={ `text ${ countdownExpired ? 'countdownExpired' : '' }` }>{ I18n.t('landing.ifContract') }</span>
            <span className={ `text ${ countdownExpired ? 'countdownExpired' : '' }` }><Trans i18nKey='landing.ifContractContinue'><strong>NOT ERASE</strong></Trans></span>
            <div className='periodicitySelector'>
                <span className={ `selector ${ planPeriodicity === PeriodicityEnum.Monthly ? 'active' : '' }` } onClick={ () => setPlanPeriodicity(PeriodicityEnum.Monthly) }>{ I18n.t('landing.monthly') }</span>
                <span className={ `selector ${ planPeriodicity === PeriodicityEnum.Annual ? 'active' : '' }` } onClick={ () => setPlanPeriodicity(PeriodicityEnum.Annual) }>{ I18n.t('landing.annual') }</span>
            </div>
            <div className='planCards'>
                <PlanCard periodicity={ planPeriodicity } expiredCountdown={ countdownExpired } plan={ PlansEnum.Starter } contractCallback={ () => navigate(`/auth/register?periodicity=${ planPeriodicity }&plan=${ PlansEnum.Starter }`) } />
                <PlanCard periodicity={ planPeriodicity } expiredCountdown={ countdownExpired } plan={ PlansEnum.Standard } contractCallback={ () => navigate(`/auth/register?periodicity=${ planPeriodicity }&plan=${ PlansEnum.Standard }`) } />
                <PlanCard periodicity={ planPeriodicity } expiredCountdown={ countdownExpired } plan={ PlansEnum.Advanced } contractCallback={ () => navigate(`/auth/register?periodicity=${ planPeriodicity }&plan=${ PlansEnum.Advanced }`) } />
                <PlanCard periodicity={ planPeriodicity } expiredCountdown={ countdownExpired } plan={ PlansEnum.Professional } contractCallback={ () => navigate(`/auth/register?periodicity=${ planPeriodicity }&plan=${ PlansEnum.Professional }`) } />
            </div>
        </div>
        <Container className='beforeFooterLastContainer'>
            <div className='yogaCenter'>
                <Image src={ YogaCenter } />
                <div className='yogaCenterText'>
                    <span className='title'>{ I18n.t('landing.yogaCenter') }</span>
                    <span className='text'><Trans i18nKey='landing.yogaCenterText'><strong>NOT ERASE</strong></Trans></span>
                    <OButton $terciary type='button' $color='#fff' className='contractButton' onClick={ () => navigate('/enterprise') }>
                        <span>{ I18n.t('landing.contact') }</span>
                    </OButton>
                </div>
            </div>
            <div className='workshops'>
                <Image src={ WorkshopsBackground } />
                <div className='workshopsText'>
                    <span className='title'>{ I18n.t('landing.workshopsTitle') }</span>
                    <br />
                    <br />
                    <span className='text'>{ I18n.t('landing.workshopsText') }</span>
                    <br />
                    <OButton $terciary type='button' $color='#fff' className='contractButton' onClick={ () => navigate('/workshops') }>
                        <span>{ I18n.t('landing.goToWorkshops') }</span>
                    </OButton>
                </div>
            </div>
        </Container>
        <div className='faq'>
            <Container>
                <span className='title'>{ I18n.t('landing.faq.title') }</span>
                <br />
                <br />
                <Faq active={ activeFaqQuestion === 1 } answer={ I18n.t('landing.faq.1.answer') } index={ 1 } onExpandCallback={ (index: number) => setActiveFaqQuestion(index) } question={ I18n.t('landing.faq.1.question') } />
                <Faq active={ activeFaqQuestion === 2 } answer={ I18n.t('landing.faq.2.answer') } index={ 2 } onExpandCallback={ (index: number) => setActiveFaqQuestion(index) } question={ I18n.t('landing.faq.2.question') } />
                <Faq active={ activeFaqQuestion === 3 } answer={ I18n.t('landing.faq.3.answer') } index={ 3 } onExpandCallback={ (index: number) => setActiveFaqQuestion(index) } question={ I18n.t('landing.faq.3.question') } />
                <Faq active={ activeFaqQuestion === 4 } answer={ I18n.t('landing.faq.4.answer') } index={ 4 } onExpandCallback={ (index: number) => setActiveFaqQuestion(index) } question={ I18n.t('landing.faq.4.question') } />
            </Container>
        </div>
        <div className='footer'>
            <Container>
                {/* <div className='link'><HashLink to=''>{ I18n.t('landing.footer.pedagogy') }</HashLink></div> */}
                <div className='link'><a href='https://yogabot-project.gitbook.io/pro/legal/aviso-legal' target='_blank' rel='noopener noreferrer'>{ I18n.t('landing.footer.legalAdvise') }</a></div>
                <div className='link'><HashLink to='/#functionalities'>{ I18n.t('landing.footer.functionalities') }</HashLink></div>
                <div className='link'><a href='https://yogabot-project.gitbook.io/pro/sobre-yogabot-profesional/quienes-somos' target='_blank' rel='noopener noreferrer'>{ I18n.t('landing.footer.aboutUs') }</a></div>
                <div className='link'><a href='https://yogabot-project.gitbook.io/pro/legal/terminos-de-uso' target='_blank' rel='noopener noreferrer'>{ I18n.t('landing.footer.termsAndConditions') }</a></div>
                <div className='link'><HashLink to='/#rates'>{ I18n.t('landing.footer.rates') }</HashLink></div>
                <div className='link'><Link to='/workshops'>{ I18n.t('landing.footer.trainYou') }</Link></div>
                <div className='link'><a href='https://yogabot-project.gitbook.io/pro/legal/privacidad' target='_blank' rel='noopener noreferrer'>{ I18n.t('landing.footer.privacyPolicy') }</a></div>
                <div className='link'>&nbsp;</div>
                <div className='link'><a href='https://academianexoyoga.com/' target='_blank' rel='noopener noreferrer'>{ I18n.t('landing.footer.nexoyogaAcademy') }</a></div>
            </Container>
        </div>
    </Wrapper>;

};

export default Landing;