// Third party libraries
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { Icon, Image } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface, USER_PRO_STATUS } from '../../../../../../models/student';

// Components
import { ClosedModal, GenericModalProps } from '../../../../../components/genericModal';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

// Assets
import DemoImage from '../../../../../assets/img/yogabot_icon.svg';

type Props = {
    student?: StudentInterface,
    onAddToGroup: () => void,
    onAssignSequence: () => void,
    onChangeModal: (modal: GenericModalProps) => void,
    onSendMessage: () => void
};

const MainInfo = ({ student, onAddToGroup, onAssignSequence, onChangeModal, onSendMessage }: Props) => {

    const dispatch = useDispatch();

    const onUnlink = () => {

        onChangeModal({
            open: true,
            title: I18n.t('buttons.actions.confirm'),
            renderBody: () => <span><Trans i18nKey='students.unlinkConfirmMessage'><strong></strong></Trans></span>,
            renderActions: () => <>
                <OButton type='button' $color='black' $primary onClick={ closeModal }>
                    <span>{ I18n.t('buttons.actions.cancel') }</span>
                </OButton>
                <OButton type='button' $color='white' $terciary onClick={ () => linkOrUnlinkStudent(USER_PRO_STATUS.UNLINKED, undefined) }>
                    <span>{ I18n.t('buttons.actions.ok') }</span>
                </OButton>
            </>
        });

    };

    const linkOrUnlinkStudent = async (status: USER_PRO_STATUS, reason: string | undefined) => {

        try {

            closeModal();

            dispatch(openLoader());

            //TODO: Si es linkar, comprobar primero la licencia para saber si no ha llegado al máximo de alumnos y dar un mensaje en consecuencia
            await StudentModel.linkOrUnlinkStudent('' + student?._id, status, reason);

        } catch (e) {

            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        } finally {

            dispatch(closeLoader());

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    const getInitials = (text: string) => (text.substring(0, 1) + (text.includes(' ') ? text.substring(text.indexOf(' ') + 1, text.indexOf(' ') + 2) : '')).toUpperCase();

    const onLinkUser = () => linkOrUnlinkStudent(USER_PRO_STATUS.LINKED, undefined);

    const onDenyToLinkUser = () => {}; //TODO: Denegar usuario con popup de razón por lo que lo deniega

    return (
        <div className='userMainInfo'>
            <div className='isPremium mobile' style={{ marginBottom: '15px' }}>
                <span>
                    { I18n.t(student?.isPremium ? 'students.liteAccount' : 'students.premiumAccount') }
                </span>
            </div>
            <Image avatar className={ !!!student?.pictureUrl ? 'broken' : '' } src={ student?.pictureUrl || DemoImage } onError={ (e: any) => { e.target.classList.add('broken'); e.target.src = DemoImage } } />
            <span className={ `initials ${ !!!student?.pictureUrl ? 'visible' : ''} 'avatar'` } >{ getInitials(!!student?.name && !!student?.surname ? student?.name + ' ' + student?.surname : I18n.t('students.noName')) }</span>
            <span className='name'>
                { student?.name || I18n.t('students.noName') }
            </span>
            <span className='surname'>
                { student?.surname }
            </span>
            <div className={ 'status ' + (student?.status === USER_PRO_STATUS.LINKED ? 'linked' : '') } />
            <span className={ 'status ' + (student?.status === USER_PRO_STATUS.LINKED ? 'linked' : '') }>
                { I18n.t(student?.status === USER_PRO_STATUS.LINKED ? 'students.linkedStatus' : 'students.unlinkedStatus') }
            </span>
            <div className='isPremium desktop'>
                <span>
                    { I18n.t(student?.isPremium ? 'students.liteAccount' : 'students.premiumAccount') }
                </span>
            </div>
            <div className='actions'>
                { [USER_PRO_STATUS.PENDING_PRO, USER_PRO_STATUS.UNLINKED, USER_PRO_STATUS.SYSTEM_UNLINKED].includes(_.get(student, 'status', USER_PRO_STATUS.LINKED)) ?
                    <>
                        <OButton icon type='button' $color='var(--brand-positive)' $quarth onClick={ onLinkUser }>
                            <Icon name='check' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.linkUser') }</span></p>
                        </OButton>
                        <OButton icon type='button' $color='var(--brand-error)' $quarth onClick={ onDenyToLinkUser } style={{ top: 0, marginBottom: '15px' }}>
                            <Icon name='times' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.denyToLinkUser') }</span></p>
                        </OButton>
                    </>
                : [USER_PRO_STATUS.LINKED].includes(_.get(student, 'status', USER_PRO_STATUS.UNLINKED)) ?
                    <>
                        <OButton icon type='button' $color='black' $quarth onClick={ onAssignSequence }>
                            <Icon name='tv' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.addSequence') }</span></p>
                        </OButton>
                        <OButton icon type='button' $color='black' $quarth onClick={ onAddToGroup }>
                            <Icon name='group' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.addGroup') }</span></p>
                        </OButton>
                        <OButton className='desktopSendMessage' icon type='button' $color='black' $quarth onClick={ onSendMessage }>
                            <Icon name='mail' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.sendMessage') }</span></p>
                        </OButton>
                        <OButton icon type='button' $color='black' $quarth onClick={ onUnlink }>
                            <Icon name='unlink' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.unlink') }</span></p>
                        </OButton>
                    </>
                : '' }
            </div>
        </div>
    );

};

export default MainInfo;