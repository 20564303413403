import _ from 'lodash';

import AxiosService from '../utils/AxiosService';

import Config from '../config';

// Enums
export enum PlansEnum {
    Advanced = 'Advanced',
    Free = 'Free',
    Professional = 'Professional',
    Standard = 'Standard',
    Starter = 'Starter'
};

export enum PeriodicityEnum {
    Monthly = 'monthly',
    Annual = 'annual'
};

// Interfaces
export interface NewPaymentInterface {
    amount: number;
    brand?: string;
    currency: string;
    description: string;
    last4?: number;
    orderId: string;
    platform: string;
    type: string;
};

export interface PaymentInterface extends NewPaymentInterface {
    _id: string;
    createdAt: string;
    endDate: string;
    receiptNumber: string;
    startDate: string;
    userId: string;
};

export interface NewSubscriptionInterface {
    amount: number;
    currency: string;
    paymentMethodId: any;
    platform: string;
    type: string;
}

export interface SubscriptionInterface {
    _id: string;
    orderId: string;
    provider: string;
    startDate: string;
    type: string;
    userId: string;
};

export interface LicenseInterface {
    _id?: string;
    activeSubscription: boolean;
    endDate?: string;
    maxStudents: number;
    name: PlansEnum;
    startDate?: string;
    userId?: string;
};

const cancelSubscription = async (): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.paymentApi + 'pro/subscription', true), 'data', {});
const createPayment = async (payload: NewPaymentInterface): Promise<PaymentInterface> => _.get(await AxiosService.post(Config.server.api.paymentApi + 'pro/payment', payload, true), 'data', {});
const createSubscription = async (payload: NewSubscriptionInterface): Promise<SubscriptionInterface> => _.get(await AxiosService.post(Config.server.api.paymentApi + 'pro/subscription', payload, true), 'data', {});
const getCurrentLicense = async (): Promise<LicenseInterface> => _.get(await AxiosService.get(Config.server.api.paymentApi + 'pro/license', true), 'data', {});
const getPayments = async (): Promise<PaymentInterface[]> => _.get(await AxiosService.get(Config.server.api.paymentApi + 'pro/payment', true), 'data', {});

const exports = {
    cancelSubscription,
    createPayment,
    createSubscription,
    getCurrentLicense,
    getPayments
};

export default exports;