// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Icon, Image } from 'semantic-ui-react';

// Models
import { LicenseInterface } from '../../../models/payment';
import StudentModel, { StudentInterface, USER_PRO_STATUS } from '../../../models/student';

// Styled components
import * as S from './styled';

// Locales
import I18n from '../../../i18n';

// Assets
import Logo from '../../assets/img/YogabotPro.png';
import OButton from '../../styled/button';

// Interfaces
interface ParamsInterface {
    active: string;
    disableMenu?: boolean;
};

export default function PanelNav({ active, disableMenu = false }: ParamsInterface) {

    const navigate = useNavigate();

    const currentLicense: LicenseInterface = useSelector((state: { license: { currentLicense: LicenseInterface } }) => state.license.currentLicense);
    const [isCurrentLicenseFull, setIsCurrentLicenseFull] = useState<boolean>(false);

    useEffect(() => {

        const getData = async () => {

            const linkedStudents = _.filter(await StudentModel.getStudents(), (proStudent: StudentInterface) => proStudent.status === USER_PRO_STATUS.LINKED);
            setIsCurrentLicenseFull(_.size(linkedStudents) >= currentLicense.maxStudents);

        };

        getData();

    }, [currentLicense.maxStudents]);

    return <>
        <S.Nav>
            <S.Logo>
                <S.NavLink to='/students'>
                    <Image src={ Logo } />
                </S.NavLink>
            </S.Logo>
            { !disableMenu && <>
                <S.NavContent $justify='center'>
                    <S.NavList>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'students' } to='/students'><Icon name='student' />{ I18n.t('menu.students') }</S.NavLink>
                        </S.ListItem>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'groups' } to='/groups'><Icon name='group' />{ I18n.t('menu.groups') }</S.NavLink>
                        </S.ListItem>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'sequences' } to='/sequences'><Icon name='edit outline' />{ I18n.t('menu.sequences') }</S.NavLink>
                        </S.ListItem>
                    </S.NavList>
                </S.NavContent>
                <S.IconGroup>
                    {/* <S.IconLink to='/notifications' >
                        <Icon name='bell'/>
                    </S.IconLink> */}
                    <S.IconLink $activeLink={ active === 'setup' } to='/setup/account'>
                        <Icon name='cog'/>
                    </S.IconLink>
                </S.IconGroup>
            </> }
        </S.Nav>
        { isCurrentLicenseFull && !disableMenu &&
            <S.UploadSubscriptionBanner>
                <span>{ I18n.t('messages.subscriptionExceeded') }</span>
                <OButton $upper $color='#FFF' $terciary size='mini' onClick={ () => navigate('/setup/checkout/plans') }>
                    <span>{ I18n.t('setup.levelUpSubscription') }</span>
                </OButton>
            </S.UploadSubscriptionBanner>
        }
    </>;

}