// Third party libraries
import { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';

// Models
import { PeriodicityEnum, PlansEnum } from '../../../../../../../models/payment';

// Services
import PaymentPlansService, { PaymentPlanInterface } from '../../../../../../../utils/PaymentPlansService';

// Locales
import I18n from '../../../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface ParamsInterface {
    maxStudents?: number;
    onSelect: () => void;
    periodicity: PeriodicityEnum;
    plan: PlansEnum;
    selected: boolean;
};

const HorizontalPlanCard = ({ maxStudents, onSelect = () => {}, periodicity, plan, selected }: ParamsInterface) => {

    const [data, setData] = useState<PaymentPlanInterface>(PaymentPlansService.getPlanData(plan, periodicity));

    useEffect(() => {

        setData(PaymentPlansService.getPlanData(plan, periodicity));

    }, [periodicity, plan]);

    const onClick = () => (!maxStudents || maxStudents < data.maxStudentsToManage) && onSelect();

    return <div className={ `horizontalPlanCard ${ selected ? 'selected' : '' } ${ data.discount === 0 ? 'hideRibbon' : '' } ${ !!maxStudents && maxStudents >= data.maxStudentsToManage ? 'disabled' : '' }` } data-ribbon={ '-' + data.discount + '%' } onClick={ onClick }>
        <Image src={ data.logo } />
        <div className='content'>
            <span className='planTitle'>{ plan }</span>
            <span className='manageText'>{ I18n.t('landing.manageUpToStudents', { maxStudents: data.maxStudentsToManage }) }</span>
            { data.newPlanPrice !== data.planPrice && <span className='oldPrice'>{ '$' + data.planPrice }</span> }
            <div className='priceBox'>
                <span className='newPrice'>{ '$' + (data.newPlanPrice || data.planPrice) }</span>
                <span className='periodicity'>{ '/' + data.periodicityText }</span>&nbsp;&nbsp;&nbsp;
                <span className='forEver'>{ I18n.t(periodicity === PeriodicityEnum.Annual ? 'landing.uniqPayment' : data.discount !== 0 ? 'landing.forEver' : '') }</span>
            </div>
        </div>
    </div>;

}

export default HorizontalPlanCard;