import { useParams } from 'react-router-dom';

const withRouter = Component => {

    const ComponentWithRouterProp = props => {

        const params = useParams();
        return <Component { ...props } router={{ params }} />;

    }

    return ComponentWithRouterProp;

}

const exports = {
    withRouter
}

export default exports;