import { Button, ButtonProps } from 'semantic-ui-react';
import styled, { css } from 'styled-components/macro';

interface OButtonProps extends ButtonProps {
    $primary?: boolean;
    $secondary?: boolean;
    $terciary?: boolean;
    $quarth?: boolean;
    $selected?: boolean;
    $upper?: boolean;
    $color?: string;
};

const OButton = styled(Button)<OButtonProps>`
    &&& {
        font-family: PoppinsRegular;
        font-weight: 400;
        position: relative;
        color: ${ ({ $color }) => $color || '#30425a' };

        ${ ({ $primary }) => $primary &&
            css`
                background-color: #fff;
        `};

        ${ ({ $secondary }) => $secondary &&
            css`
                background-color: var(--brand-primary);
                color: #fff;

                &:after{
                    content: '';
                    border: 2px solid #fff;
                    position: absolute;
                    pointer-events: none;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    border-radius: inherit;
                }
        `};

        ${ ({ $terciary, $color }) => $terciary &&
            css`
                background-color: #1fd5b9;

                &:hover {
                    background-color: #1bb89f;
                }

                &:active, &:focus {
                    color: #fff;
                    background-color: #1bb89f;

                    i {
                        &:before {
                            color: ${ $color || '#fff' };
                        }
                    }
                }
        `};

        ${ ({ $quarth }) => $quarth &&
            css`
                background-color: #F4F4F4;
                font-size: 12px;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
        `};

        ${ ({ $selected, $color }) => $selected &&
            css`
                background-color: #fff;

                i {
                    background-color: transparent!important;
                }

                &:before {
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: solid 2px ${ $color || '#30425a' };
                    border-radius: inherit;
                    background: transparent;
                    pointer-events: none;
                    box-sizing: border-box;
                    position: absolute;
                }
        `};

        ${ ({ $upper }) => $upper &&
            css`
                text-transform: upperCase;
        `};
    }
`;

export default OButton;