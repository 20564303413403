import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import license from './license';
import loader from './loader';

const rootReducer = combineReducers({
    auth,
    form,
    license,
    loader
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;