import { Checkbox as SemanticCheckbox, Form } from 'semantic-ui-react';

interface ParamsInterface {
    fieldClasses?: string,
    label?: string,
    input: {
        value?: string,
        onChange?: (checked: boolean) => void
    },
    config?: any,
    toggle?: boolean
};

const CustomCheckBox = (params: ParamsInterface) => {

    return <Form.Field className={ params.fieldClasses }>
        <div className='inline field'>
            <SemanticCheckbox
                { ...params.config }
                toggle={ params.toggle || false }
                label={ params.label }
                checked={ params.input.value || false }
                onChange={ (e, v) => { !!params.input.onChange && params.input.onChange(v.checked || false) } }
            />
        </div>
    </Form.Field>;

};

export default CustomCheckBox;