// Third party libraries
import _ from 'lodash';
import { ThunkAction } from 'redux-thunk';

// Models
import PaymentModel, { LicenseInterface, PlansEnum } from '../models/payment';

// States
interface Initial_State { currentLicense: LicenseInterface };
const INITIAL_STATE: Initial_State = { currentLicense: { activeSubscription: false, maxStudents: 3, name: PlansEnum.Free }} as const;
type State = Initial_State;

// Action Types
const ACTION_TYPES = { GET_CURRENT_LICENSE: 'GET_CURRENT_LICENSE' } as const;

type ActionTypes = typeof ACTION_TYPES;

type GetCurrentLicense = { type: ActionTypes['GET_CURRENT_LICENSE'], payload: {} };

type Action = GetCurrentLicense;

// Actions
export const getCurrentLicense = (): ThunkAction<void, any, unknown, any> => async (dispatch) => {

    try {

        const response = await PaymentModel.getCurrentLicense();
        dispatch ({ type: ACTION_TYPES.GET_CURRENT_LICENSE, payload: { currentLicense: _.isEmpty(response) ? INITIAL_STATE.currentLicense : response }});

    } catch (errorCode) {

        dispatch ({ type: ACTION_TYPES.GET_CURRENT_LICENSE, payload: INITIAL_STATE});
        throw errorCode;

    }

};

// Reducer
const authReducer = (state: State = INITIAL_STATE, action: Action) => {

    switch (action.type) {

        case ACTION_TYPES.GET_CURRENT_LICENSE:

            return { ...state, ...action.payload };

        default:

            return state;

    }

};

export default authReducer;