import { useEffect } from 'react';
import SecurityModel from '../../../models/security';

const KeepAlive = () => {

    const doCheck = async () => {

        try {

            const data = await SecurityModel.checkJwt();
            window.localStorage.setItem('jwt', data.jwt);

        } catch (error) {

            window.localStorage.removeItem('jwt');
            console.error('error:KeepAlive')

        }

    }

    useEffect(() => {

        const interval = setInterval(() => {

            if (window.localStorage.getItem('jwt')) {

                doCheck();

            }

        }, 1000 * 60 * 30);

        return () => clearInterval(interval);

    }, []);

    return null;

}

export default KeepAlive;