// Third party libraries
import { Container, Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Redux
import { closeSession } from '../../../../redux-store/auth';

// Styled components
import * as S from '../../../components/nav/styled';
import { Wrapper } from '../../../styled/wrappers';

// Locales
import I18n from '../../../../i18n';

// Styles
import './setupNav.scss';

// Interfaces
interface ParamsInterface {
    active: string;
    disableMenu?: boolean;
};

export default function PanelNav({ active, disableMenu = false }: ParamsInterface) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onCloseSession = () => {

        dispatch(closeSession());
        navigate('/auth/login');

    };

    return disableMenu ? <></> : <Wrapper>
        <Container>
            <S.Nav className='setupMenuContainer'>
                <S.NavContent $justify='center' className='setupMenu'>
                    <S.NavList>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'account' } to='/setup/account'>{ I18n.t('menu.account') }</S.NavLink>
                        </S.ListItem>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'security' } to='/setup/security'>{ I18n.t('menu.security') }</S.NavLink>
                        </S.ListItem>
                        <S.ListItem>
                            <S.NavLink $activeLink={ active === 'subscription' } to='/setup/subscription'>{ I18n.t('menu.subscription') }</S.NavLink>
                        </S.ListItem>
                    </S.NavList>
                </S.NavContent>
                <S.IconGroup style={{ width: '35px' }}>
                    <S.IconLink to='/auth/login' onClick={ onCloseSession }>
                        <Icon name='log out'/>
                    </S.IconLink>
                </S.IconGroup>
            </S.Nav>
        </Container>
    </Wrapper>;

}