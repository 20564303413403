// Third party libraries
import { Container, Divider, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

// Styled components
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';
import _ from 'lodash';

const Workshops = () => {

    const navigate = useNavigate();

    const renderWorkshop = (count: number, videoUrl: string = '') => {

        return <div className='workshop'>
            <br />
            <span className='title'>{ 'Workshop ' + count }</span>
            <br />
            <span className='subtitle'>{ I18n.t(`landing.workshopsTexts.workshop${ count }.title`) }</span>
            <br />
            <span className='description'>{ I18n.t(`landing.workshopsTexts.workshop${ count }.description`) }</span>
            <br />
            { _.isEmpty(videoUrl) ?
                <span className='comingSoon'>{ I18n.t('landing.workshopsTexts.videoComingSoon') }</span> :
                <iframe src={ videoUrl } frameBorder='0' allow='autoplay; fullscreen' allowFullScreen></iframe>
            }
            <br />
            <br />
        </div>;

    }

    return <Container className='workshopsContainer'>
        <OButton $primary type='button' $color='#00B595' className='goBackButton' onClick={ () => navigate('/') }>
            <Icon name='arrow left' />
            <span>{ I18n.t('buttons.actions.goBack') }</span>
        </OButton>
        <span className='title'>{ I18n.t('landing.workshopsTexts.title') }</span>
        <br />
        <br />
        { renderWorkshop(1, 'https://player.vimeo.com/video/748935007?h=15b9570e17&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479') }
        <Divider />
        { renderWorkshop(2, 'https://player.vimeo.com/video/750692863?h=15b9570e17&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479') }
        <Divider />
        { renderWorkshop(3, 'https://player.vimeo.com/video/754879442?h=15b9570e17&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479') }
        <Divider />
        { renderWorkshop(4) }
    </Container>

// CTA:
// Video que grabamos.
// +
// Imagen curso
// +
// Botón CTA

};

export default Workshops;