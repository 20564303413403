import _ from 'lodash';
import React from 'react';
import { Form, Input as SemanticInput, Label, SemanticCOLORS } from 'semantic-ui-react';
import { convertToNumeric, trimString } from '../../../../config/validations';

interface ParamsInterface {
    restrictions?: [{
        numeric?: boolean,
        canBeFloat?: boolean,
        trim?: boolean
    }],
    fieldClasses?: string,
    label?: string,
    colorLabel?: SemanticCOLORS,
    meta: {
        touched?: boolean,
        invalid?: boolean,
        error?: string
    },
    input: {
        value?: string,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    },
    placeholder?: string,
    disabled?: boolean,
    type?: string
};

const CustomInput = (params: ParamsInterface) => {

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (_.get(params, 'restrictions[0].trim', false) ) {

            event.target.value = trimString(event.target.value);

        }

        if (_.get(params, 'restrictions[0].canBeFloat', false) || _.get(params, 'restrictions[0].numeric', false)) {

            event.target.value = convertToNumeric(event.target.value, _.get(params, 'restrictions[0].canBeFloat', false));

        }

        !!params.input.onChange && params.input.onChange(event);

    }

    return <Form.Field className={ params.fieldClasses } error={ params.meta.touched && params.meta.invalid }>
        <label className='primary-font regular'>{ params.label }</label>
        <SemanticInput
            disabled={ params.disabled || false }
            { ...params.input }
            placeholder={ params.placeholder }
            onChange={ onChange }
            type={ params.type || 'text' }
        />
        { params.meta.touched && params.meta.invalid && <Label basic color={ params.colorLabel || 'red' } pointing>{ params.meta.error }</Label> }
    </Form.Field>;

}

export default CustomInput;