// Third party libraries
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'moment';
import { Divider, Form, Input } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface } from '../../../../../../models/student';

// Styled components
import OButton from '../../../../../styled/button';

// Components
import { ClosedModal, GenericModalProps } from '../../../../../components/genericModal';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

type Props = {
    student?: StudentInterface,
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
};

const Notes = ({ student, onForceUpdate, onChangeModal }: Props) => {

    //TODO: Make a new page for notes historic and only show last three here

    const dispatch = useDispatch();
    const [note, setNote] = useState('');

    const handleSubmit = async () => {

        try {

            dispatch(openLoader());

            await StudentModel.updateStudent('' + student?._id, '', note);
            setNote('');
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <>
                    <OButton type='button' $color='white' $terciary onClick={ closeModal }>
                        <span>{ I18n.t('buttons.actions.understood') }</span>
                    </OButton>
                </>
            });

        }

    };

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <div className='userNotes'>
            <span className='title'>{ I18n.t('students.notes') }</span>
            <div className='box'>
                { _.size(student?.notes) === 0 ? <><span className='subtitle'>{ I18n.t('students.withoutNotes') }</span><Divider /></> :
                    student?.notes.map(note => <React.Fragment key={ note.createdAt }>
                        <span className='subtitle'>{ Moment(note.createdAt).format('DD/MM/YYYY - HH:mm') }</span>
                        <span className='text'>{ note.text }</span>
                        <Divider />
                    </React.Fragment>)
                }
                <Form onSubmit={ handleSubmit }>
                    <Form.Field>
                        <Input placeholder={ I18n.t('students.newNote') } value={ note } onChange={ event => setNote(event?.target.value) } />
                    </Form.Field>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(note) }>
                        <span>{ I18n.t('buttons.actions.save') }</span>
                    </OButton>
                </Form>
            </div>
        </div>
    );

}

export default Notes;