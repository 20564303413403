// Third party libraries
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Icon, Modal } from 'semantic-ui-react';

// Components
import CircleProgress from './circleProgress';

// Styled components
import OButton from '../../../styled/button';
import { FlexBox } from '../../../styled/wrappers';

// Locales
import I18n from '../../../../i18n';

// Styles
import './inviteStudent.scss';

type Props = {
    key?: string;
    open: boolean;
    currentStudents: number;
    totalStudents: number;
    licenseType: string;
    userSession: {
        _id: string;
        name: string;
        surname: string;
    };
    onClose: () => void
};

const InviteStudent = ({ open, onClose, currentStudents, totalStudents, licenseType, userSession }: Props) => {

    const navigate = useNavigate();

    const [linkCopied, setLinkCopied] = useState(false);

    const shareLink = `https://yogabot.app/#/auth/register?pro=${ userSession._id }`;
    const userFullName = userSession.name + ' ' + userSession.surname;

    const onWhatsappShare = () => {

        window.open(`https://api.whatsapp.com/send?text=${ encodeURIComponent('' + I18n.t('students.inviteModal.invitationTexts.whatsapp', { userFullName })) + encodeURIComponent(shareLink) }`);
        onClose();

    };

    const onEmailShare = () => {

        window.open(`mailto:?subject=${ encodeURIComponent('' + I18n.t('students.inviteModal.invitationTexts.email.subject', { userFullName })) }&body=${ encodeURIComponent('' + I18n.t('students.inviteModal.invitationTexts.email.text', { userFullName })) + shareLink }`);
        onClose();

    };

    const onCopyToClipboard = () => {

        navigator.clipboard.writeText(shareLink);
        setLinkCopied(true);

    }

    const onLevelUpAccount = () => {

        onClose();
        navigate('/setup/checkout/plans');

    };

    return (
        <Modal open={ open } size='mini' closeIcon onClose={ onClose }>
            <Modal.Header>{ I18n.t('students.invite') }</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            <b>{ licenseType }</b>
                        </p>
                        <p>
                            { I18n.t('students.inviteModal.studentLimitText', { studentLimit: totalStudents }) }
                        </p>
                        <p>
                            { I18n.t('students.inviteModal.needMore') }
                        </p>
                        <OButton compact type='button' $color='var(--brand-secondary)' $primary disabled={ totalStudents === 100 } onClick={ onLevelUpAccount }>
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.inviteModal.levelUpYourAccount') }</span></p>
                        </OButton>
                        <Divider />
                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <CircleProgress total={ totalStudents } current={ currentStudents } text={ I18n.t('students.inviteModal.students') } large />
                        </div>
                        <p>
                            <b>{ I18n.t('students.inviteModal.invitationLink') }</b>
                        </p>
                        <div style={{ backgroundColor: '#F4F4F4', lineHeight: '30px' }}>
                            <span className='caption regular'>{ linkCopied ? I18n.t('students.inviteModal.linkCopied') : shareLink }</span>
                        </div>
                    </div>
                    <FlexBox $justifyContent='space-between' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <OButton icon type='button' $color='var(--brand-secondary)' $quarth style={{ width: '33%' }} onClick={ onCopyToClipboard }>
                            <Icon name='copy' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.inviteModal.copyLink') }</span></p>
                        </OButton>
                        <OButton icon type='button' $color='var(--brand-secondary)' $quarth style={{ width: '33%' }} onClick={ () => onEmailShare() }>
                            <Icon name='mail' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.inviteModal.sendByEmail') }</span></p>
                        </OButton>
                        <OButton icon type='button' $color='var(--brand-secondary)' $quarth style={{ width: '33%' }} onClick={ () => onWhatsappShare() }>
                            <Icon name='whatsapp square' size='big' />
                            <p style={{ marginTop: '10px' }}><span>{ I18n.t('students.inviteModal.shareOnWhatsapp') }</span></p>
                        </OButton>
                    </FlexBox>
                    {/* <OButton fluid $upper type='button' $color='#fff' $terciary onClick={ () => onClose() }>
                        <span>{ I18n.t('students.inviteModal.sendAutomaticInvitation') }</span>
                    </OButton> */}
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );

}

export default InviteStudent;