// Third party libraries
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { List } from 'semantic-ui-react';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface CustomSequenceListPropsInterface {
    studentId: string
};

const CustomSequenceList = ({ studentId }: CustomSequenceListPropsInterface) => {

    const navigate = useNavigate();

    return <div className='proPracticesBox'>
        <List divided relaxed>
            { _.map([-1, 0, 1], durationId =>
                <List.Item key={ durationId }>
                    <List.Content>
                        <List.Header>{ I18n.t(`students.${ durationId === -1 ? 'shortDuration' : durationId === 0 ? 'mediumDuration' : 'largeDuration' }`) }</List.Header>
                        <List.Description>{ I18n.t('students.durationBetween', { maxMinutes: durationId === -1 ? 25 : durationId === 0 ? 45 : 70, minMinutes: durationId === -1 ? 20 : durationId === 0 ? 25 : 45 }) }</List.Description>
                    </List.Content>
                    <OButton
                        $terciary
                        $color='#fff'
                        type='button'
                        onClick={ () => navigate(`/sequences/edit/custom_${ durationId }:${ studentId }/asanas`) }
                    >
                        <span>{ I18n.t('students.seeCustomSequence') }</span>
                    </OButton>
                </List.Item>
            ) }
        </List>
    </div>;

};

export default CustomSequenceList;