// Third party libraries
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Moment from 'moment';

// Redux
import { getProfile } from '../../redux-store/auth';
import { getCurrentLicense } from '../../redux-store/license';
import { closeLoader, openLoader } from '../../redux-store/loader';

// Models
import { LicenseInterface } from '../../models/payment';

// Components
import EnterpriseContact from './landing/enterprise';
import Groups from './groups';
import GroupEdit from './groups/edit';
import Landing from './landing';
import Login from './auth/login/Login';
import Page404 from './404';
import RecoveryStep1 from './auth/recovery/Recovery';
import Register from './auth/register';
import RegisterCheckout from './auth/register/checkout';
import Sequences from './sequences';
import SequenceEdit from './sequences/edit';
import SequenceEditAsanas from './sequences/edit/asanas';
import SequenceEditAsanasAdd from './sequences/edit/asanas/add';
import SequenceEditGroups from './sequences/edit/groups';
import SequenceEditObjectives from './sequences/edit/objectives';
import SequenceEditStudents from './sequences/edit/students';
import SetupAccount from './setup/account';
import SetupCheckout from './setup/checkout';
import SetupCheckoutPlans from './setup/checkout/plans';
import SetupSecurity from './setup/security';
import SetupSubscription from './setup/subscription';
import StudentEdit from './students/edit';
import Students from './students';
import Workshops from './landing/workshops';

const RouteManagement = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const search = useLocation().search;
    const [searchParams] = useSearchParams();

    const [loaded, setLoaded] = useState<boolean>(false);
    const currentLicense = useSelector((state: { license: { currentLicense: LicenseInterface } }) => state.license.currentLicense);

    useEffect(() => {

        const checkJwt = async () => {

            try {

                dispatch(openLoader());

                const userIdRef = new URLSearchParams(search).get('userIdRef');
                if (userIdRef) {

                    window.localStorage.setItem('userIdRef', userIdRef);

                }

                const urlJwt = new URLSearchParams(search).get('urlJwt');
                const jwt = window.localStorage.getItem('jwt');
                if (urlJwt && Moment(JSON.parse(atob(urlJwt.split('.')[1])).exp * 1000).diff(Moment(), 'hour') > 1) {

                    window.localStorage.setItem('jwt', urlJwt);
                    await loadUserData();

                } else if (jwt && Moment(JSON.parse(atob(jwt.split('.')[1])).exp * 1000).diff(Moment(), 'hour') > 1) {

                    await loadUserData();

                } else {

                    window.localStorage.removeItem('jwt');

                }

            } catch (codeError) {

                console.error('App: codeError', codeError);

            } finally {

                setLoaded(true);
                dispatch(closeLoader());

            }

        }

        checkJwt();

    }, []);

    const ProtectedRoute = ({ ...props }) => {

        const jwt = window.localStorage.getItem('jwt');
        if (jwt && Moment(JSON.parse(atob(jwt.split('.')[1])).exp * 1000).startOf('minute').isAfter(Moment().startOf('minute'))) {

            if (window.location.hash.includes('/expiredLicense/') || (!!currentLicense.endDate && Moment(currentLicense.endDate).startOf('day').isSameOrAfter(Moment().startOf('day')))) {

                return props.children;

            } else {

                return <Navigate to='/expiredLicense/plans' replace />;

            }

        } else {

            return <Navigate to='/auth/login' replace />;

        }

    };

    const loadUserData = async () => {

        await dispatch(getProfile());
        await dispatch(getCurrentLicense());

    }

    return !loaded ? <></> : (
        <Routes>
            <Route path='/' element={ <Landing /> } />
            <Route path='/enterprise' element={ <EnterpriseContact /> } />
            <Route path='/workshops' element={ <Workshops /> } />
            <Route path='/auth/login' element={ <Login navigate={ navigate } /> } />
            <Route path='/auth/register' element={ <Register navigate={ navigate } search={ searchParams } /> } />
            <Route path='/auth/register/checkout' element={ <RegisterCheckout /> } />
            <Route path='/auth/recovery' element={ <RecoveryStep1 navigate={ navigate } /> } />
            { /********* Protected routes ************************/ }
            <Route path='/students' element={ <ProtectedRoute><Students navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/students/edit/:studentId' element={ <ProtectedRoute><StudentEdit navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/groups' element={ <ProtectedRoute><Groups navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/groups/new' element={ <ProtectedRoute><GroupEdit navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/groups/edit/:groupId' element={ <ProtectedRoute><GroupEdit navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/sequences' element={ <ProtectedRoute><Sequences navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/sequences/new' element={ <ProtectedRoute><SequenceEdit navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId' element={ <ProtectedRoute><SequenceEdit navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId/asanas' element={ <ProtectedRoute><SequenceEditAsanas /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId/asanas/add' element={ <ProtectedRoute><SequenceEditAsanasAdd /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId/groups' element={ <ProtectedRoute><SequenceEditGroups /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId/objectives' element={ <ProtectedRoute><SequenceEditObjectives navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/sequences/edit/:sequenceId/students' element={ <ProtectedRoute><SequenceEditStudents /></ProtectedRoute> } />
            <Route path='/setup/account' element={ <ProtectedRoute><SetupAccount navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/setup/checkout' element={ <ProtectedRoute><SetupCheckout /></ProtectedRoute> } />
            <Route path='/setup/checkout/plans' element={ <ProtectedRoute><SetupCheckoutPlans /></ProtectedRoute> } />
            <Route path='/setup/security' element={ <ProtectedRoute><SetupSecurity navigate={ navigate } /></ProtectedRoute> } />
            <Route path='/setup/subscription' element={ <ProtectedRoute><SetupSubscription /></ProtectedRoute> } />
            { /********* Expired License routes ************************/ }
            <Route path='/expiredLicense/checkout' element={ <ProtectedRoute><SetupCheckout /></ProtectedRoute> } />
            <Route path='/expiredLicense/plans' element={ <ProtectedRoute><SetupCheckoutPlans /></ProtectedRoute> } />
            <Route path='*' element={ <Page404 /> } />
        </Routes>
    );

}

export default RouteManagement;