// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container, Dropdown, Form } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Models
import CommonModel, { ListDataInterface } from '../../../../../models/common';
import SequenceModel, { EmptySequence, SequenceInterface } from '../../../../../models/sequence';

// Components
import BottomNav from '../../../../components/bottomNav/BottomNav';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../../components/genericModal';
import PanelNav from '../../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../../components/nav/SecondarySubmenu';

// Styled components
import { Wrapper } from '../../../../styled/wrappers';
import OButton from '../../../../styled/button';

// Utils
import HooksService from '../../../../../utils/HooksService';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface SequenceObjectivesProps extends MapDispatchProps {
    navigate?: any;
    router?: any;
};

interface FormInterface {
    mainObjectiveLevel1: string;
    mainObjectiveLevel2: string;
    mainObjectiveLevel3?: string;
    secondaryObjectiveLevel1: string;
    secondaryObjectiveLevel2?: string;
    secondaryObjectiveLevel3?: string;
    terciaryObjectiveLevel1: string;
    terciaryObjectiveLevel2?: string;
    terciaryObjectiveLevel3?: string;
};

interface SelectInterface {
    key: string;
    text: string;
    value: string;
};

interface SequenceObjectivesState {
    breadcrumbs: BreadcrumbsInterface[];
    sequenceId?: string;
    sequence: SequenceInterface;
    mainLevel1Options: SelectInterface[];
    mainLevel2Options: SelectInterface[];
    mainLevel3Options: SelectInterface[];
    secondaryLevel1Options: SelectInterface[];
    secondaryLevel2Options: SelectInterface[];
    secondaryLevel3Options: SelectInterface[];
    terciaryLevel1Options: SelectInterface[];
    terciaryLevel2Options: SelectInterface[];
    terciaryLevel3Options: SelectInterface[];
    injuries: any[];
    objectives: any[];
    modal: GenericModalProps;
    form: FormInterface;
};

class SequenceObjectives extends React.Component<SequenceObjectivesProps, SequenceObjectivesState> {

    constructor(props: SequenceObjectivesProps) {

        super(props);

        this.state = {
            sequenceId: this.props.router.params.sequenceId,
            sequence: EmptySequence,
            breadcrumbs: [],
            mainLevel1Options: [],
            mainLevel2Options: [],
            mainLevel3Options: [],
            secondaryLevel1Options: [],
            secondaryLevel2Options: [],
            secondaryLevel3Options: [],
            terciaryLevel1Options: [],
            terciaryLevel2Options: [],
            terciaryLevel3Options: [],
            injuries: [],
            objectives: [],
            form: {
                mainObjectiveLevel1: '',
                mainObjectiveLevel2: '',
                mainObjectiveLevel3: undefined,
                secondaryObjectiveLevel1: '0',
                secondaryObjectiveLevel2: undefined,
                secondaryObjectiveLevel3: undefined,
                terciaryObjectiveLevel1: '0',
                terciaryObjectiveLevel2: undefined,
                terciaryObjectiveLevel3: undefined
            },
            modal: ClosedModal
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;
        const { sequenceId } = this.state;
        let { form } = this.state;

        try {

            openLoader();

            const sequence = await SequenceModel.getSequence('' + sequenceId);

            const breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.sequences'),
                goTo: '/sequences'
            },
            {
                key: 1,
                label: sequence.title.ES,
                goTo: '/sequences/edit/' + sequenceId
            },
            {
                key: 2,
                label: I18n.t('sequences.objectives'),
                goTo: ''
            }];

            _.each(_.get(sequence, 'targets', []), (target, index) => {

                let firstLevel = target.substring(0, target.indexOf('.'));
                let secondLevel = target.substring(target.indexOf('.') + 1);
                if (firstLevel === 'improveComplaints') {

                    firstLevel = firstLevel + '.' + secondLevel.substring(0, target.indexOf('.'));
                    secondLevel = secondLevel.substring(target.indexOf('.') + 1);

                }
                const hasThirdLevel = secondLevel.includes('.');
                let thirdLevel = '';
                if (hasThirdLevel) {

                    thirdLevel = secondLevel.substring(secondLevel.indexOf('.') + 1);
                    secondLevel = secondLevel.substring(0, secondLevel.indexOf('.'));

                }

                switch (index) {

                    case 0:

                        form.mainObjectiveLevel1 = firstLevel;
                        form.mainObjectiveLevel2 = secondLevel;
                        form.mainObjectiveLevel3 = thirdLevel;
                        break;

                    case 1:

                        form.secondaryObjectiveLevel1 = firstLevel;
                        form.secondaryObjectiveLevel2 = secondLevel;
                        form.secondaryObjectiveLevel3 = thirdLevel;
                        break;

                    case 2:

                        form.terciaryObjectiveLevel1 = firstLevel;
                        form.terciaryObjectiveLevel2 = secondLevel;
                        form.terciaryObjectiveLevel3 = thirdLevel;

                }

            });

            const injuries: ListDataInterface = await CommonModel.getListData('userInjuries');
            const objectives: ListDataInterface = await CommonModel.getListData('userObjectives');

            const mainLevel1Options: SelectInterface[] = [];
            const secondaryLevel1Options: SelectInterface[] = [{ key: '0', value: '0', text: I18n.t('sequences.none') }];
            const terciaryLevel1Options: SelectInterface[] = [{ key: '0', value: '0', text: I18n.t('sequences.none') }];
            _.each(_.get(injuries, 'datas'), item => {

                mainLevel1Options.push({ key: item.value, value: item.value, text: I18n.t('objectivesDescriptions.improveComplaints.label') + ': ' + I18n.t(`injuriesDescriptions.${ item.value }.label`) });
                secondaryLevel1Options.push({ key: item.value, value: item.value, text: I18n.t('objectivesDescriptions.improveComplaints.label') + ': ' + I18n.t(`injuriesDescriptions.${ item.value }.label`) });
                terciaryLevel1Options.push({ key: item.value, value: item.value, text: I18n.t('objectivesDescriptions.improveComplaints.label') + ': ' + I18n.t(`injuriesDescriptions.${ item.value }.label`) });

            });
            _.each(_.get(objectives, 'datas'), item => {

                if (item.value !== 'improveComplaints') {

                    mainLevel1Options.push({ key: item.value, value: item.value, text: I18n.t(`objectivesDescriptions.${ item.value }.label`) });
                    secondaryLevel1Options.push({ key: item.value, value: item.value, text: I18n.t(`objectivesDescriptions.${ item.value }.label`) });
                    terciaryLevel1Options.push({ key: item.value, value: item.value, text: I18n.t(`objectivesDescriptions.${ item.value }.label`) });

                }

            });

            this.setState({
                injuries: injuries.datas,
                objectives: objectives.datas,
                sequence,
                mainLevel1Options,
                secondaryLevel1Options,
                terciaryLevel1Options,
                form,
                breadcrumbs
            });

            this.onSelectChange('mainObjectiveLevel1', form.mainObjectiveLevel1 !== '' ? form.mainObjectiveLevel1 : _.first(mainLevel1Options)?.value);
            this.onSelectChange('secondaryObjectiveLevel1', form.secondaryObjectiveLevel1);
            this.onSelectChange('terciaryObjectiveLevel1', form.terciaryObjectiveLevel1);

            closeLoader();

        } catch (e) {

            closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

    };

    getSecondLevelOptions = (firstLevel: string | undefined): SelectInterface[] => {

        const { injuries, objectives } = this.state;
        switch (firstLevel) {

            case 'makeMoreFlexible':
            case 'toneUp':

                const objectives1Selected = _.find(objectives, objective => objective.value === firstLevel);
                const objectivesLevel2Options: SelectInterface[] = [];
                if (!_.isEmpty(objectives1Selected) && !_.isEmpty(objectives1Selected.childs || [])) {

                    _.each(objectives1Selected.childs, item => objectivesLevel2Options.push({
                        key: item.value,
                        value: item.value,
                        text: I18n.t(`objectivesDescriptions.${ firstLevel }.childs.${ item.value }`)
                    }));

                }
                return objectivesLevel2Options;

            case 'lumbar':
            case 'cervical':
            case 'shoulders':
            case 'knees':
            case 'pelvis':

                const injuries1Selected = _.find(injuries, injury => injury.value === firstLevel);
                const injuriesLevel2Options: SelectInterface[] = [];
                if (!_.isEmpty(injuries1Selected) && !_.isEmpty(injuries1Selected.childs || [])) {

                    _.each(injuries1Selected.childs, item => injuriesLevel2Options.push({
                        key: item.value,
                        value: item.value,
                        text: I18n.t(`injuriesDescriptions.${ firstLevel }.childs.${ item.value }.label`)
                    }));

                }
                return injuriesLevel2Options;

            default:

                return [];

        }

    }

    getThirdLevelOptions = (firstLevel: string, secondLevel: string): SelectInterface[] => {

        const { injuries } = this.state;
        switch (firstLevel) {

            case 'lumbar':
            case 'cervical':
            case 'shoulders':
            case 'knees':
            case 'pelvis':

                const injuries1Selected = _.find(injuries, injury => injury.value === firstLevel);
                const injuries2Selected = _.find(injuries1Selected.childs, injury => injury.value === secondLevel);
                const injuriesLevel3Options: SelectInterface[] = [];
                if (!_.isEmpty(injuries2Selected) && !_.isEmpty(injuries2Selected.childs || [])) {

                    _.each(injuries2Selected.childs, item => injuriesLevel3Options.push({
                        key: item.value,
                        value: item.value,
                        text: I18n.t(`injuriesDescriptions.${ firstLevel }.childs.${ secondLevel }.childs.${ item.value }`)
                    }));

                }
                return injuriesLevel3Options;

            case 'makeMoreFlexible':
            case 'toneUp':
            default:

                return [];

        }

    }

    onSelectChange = (selectName: string, value: undefined | string) => {

        let { form } = this.state;

        let mainLevel2Options: SelectInterface[] = [];
        let mainLevel3Options: SelectInterface[] = [];
        let secondaryLevel2Options: SelectInterface[] = [];
        let secondaryLevel3Options: SelectInterface[] = [];
        let terciaryLevel2Options: SelectInterface[] = [];
        let terciaryLevel3Options: SelectInterface[] = [];

        switch (selectName) {

            case 'mainObjectiveLevel1':

                mainLevel2Options = this.getSecondLevelOptions(value);
                form.mainObjectiveLevel1 = !!value ? value : '';
                form.mainObjectiveLevel2 = '';
                form.mainObjectiveLevel3 = undefined;
                this.setState({ mainLevel2Options, mainLevel3Options, form });
                this.onSelectChange('mainObjectiveLevel2', _.first(mainLevel2Options)?.value);
                break;

            case 'mainObjectiveLevel2':

                mainLevel3Options = this.getThirdLevelOptions(form.mainObjectiveLevel1, '' + value);
                form.mainObjectiveLevel2 = !!value ? value : '';
                form.mainObjectiveLevel3 = undefined;
                this.setState({ mainLevel3Options, form });
                this.onSelectChange('mainObjectiveLevel3', _.isEmpty(mainLevel3Options) ? undefined : _.first(mainLevel3Options)?.value);
                break;

            case 'mainObjectiveLevel3':

                form.mainObjectiveLevel3 = value;
                this.setState({ form });
                break;

            case 'secondaryObjectiveLevel1':

                secondaryLevel2Options = this.getSecondLevelOptions(value);
                form.secondaryObjectiveLevel1 = _.isEmpty(secondaryLevel2Options) ? '0' : '' + value;
                form.secondaryObjectiveLevel2 = undefined;
                form.secondaryObjectiveLevel3 = undefined;
                this.setState({ secondaryLevel2Options, secondaryLevel3Options, form });
                this.onSelectChange('secondaryObjectiveLevel2', _.isEmpty(secondaryLevel2Options) ? undefined : _.first(secondaryLevel2Options)?.value);
                break;

            case 'secondaryObjectiveLevel2':

                secondaryLevel3Options = this.getThirdLevelOptions('' + form.secondaryObjectiveLevel1, '' + value);
                form.secondaryObjectiveLevel2 = value;
                form.secondaryObjectiveLevel3 = undefined;
                this.setState({ secondaryLevel3Options, form });
                this.onSelectChange('secondaryObjectiveLevel3', _.isEmpty(secondaryLevel3Options) ? '' : _.first(secondaryLevel3Options)?.value);
                break;

            case 'secondaryObjectiveLevel3':

                form.secondaryObjectiveLevel3 = value;
                this.setState({ form });
                break;

            case 'terciaryObjectiveLevel1':

                terciaryLevel2Options = this.getSecondLevelOptions(value);
                form.terciaryObjectiveLevel1 = _.isEmpty(terciaryLevel2Options) ? '0' : !!value ? value : '';
                form.terciaryObjectiveLevel2 = undefined;
                form.terciaryObjectiveLevel3 = undefined;
                this.setState({ terciaryLevel2Options, terciaryLevel3Options, form });
                this.onSelectChange('terciaryObjectiveLevel2', _.isEmpty(terciaryLevel2Options) ? undefined : _.first(terciaryLevel2Options)?.value);
                break;

            case 'terciaryObjectiveLevel2':

                terciaryLevel3Options = this.getThirdLevelOptions('' + form.terciaryObjectiveLevel1, '' + value);
                form.terciaryObjectiveLevel2 = value;
                form.terciaryObjectiveLevel3 = undefined;
                this.setState({ terciaryLevel3Options, form });
                this.onSelectChange('terciaryObjectiveLevel3', _.isEmpty(terciaryLevel3Options) ? '' : _.first(terciaryLevel3Options)?.value);
                break;

            case 'terciaryObjectiveLevel3':

                form.terciaryObjectiveLevel3 = value;
                this.setState({ form });
                break;

        }

    }

    getTargetFromSelect = (firstLevel: string, secondLevel: string | undefined, thirdLevel: string | undefined): string | undefined => !!firstLevel && firstLevel !== '0' ? firstLevel + '.' + secondLevel + (!!thirdLevel ? '.' + thirdLevel : '') : undefined;

    onSubmit = async () => {

        const { openLoader, closeLoader } = this.props;
        const { form: { mainObjectiveLevel1, mainObjectiveLevel2, mainObjectiveLevel3, secondaryObjectiveLevel1, secondaryObjectiveLevel2, secondaryObjectiveLevel3, terciaryObjectiveLevel1, terciaryObjectiveLevel2, terciaryObjectiveLevel3 }} = this.state;
        let { sequence } = this.state;

        openLoader();

        try {

            sequence.targets = [];

            const mainTarget = this.getTargetFromSelect(mainObjectiveLevel1, mainObjectiveLevel2, mainObjectiveLevel3);
            if (mainTarget) {

                sequence.targets.push(mainTarget);

            }

            const secondaryTarget = this.getTargetFromSelect(secondaryObjectiveLevel1, secondaryObjectiveLevel2, secondaryObjectiveLevel3);
            if (secondaryTarget) {

                sequence.targets.push(secondaryTarget);

            }

            const terciaryTarget = this.getTargetFromSelect(terciaryObjectiveLevel1, terciaryObjectiveLevel2, terciaryObjectiveLevel3);
            if (terciaryTarget) {

                sequence.targets.push(terciaryTarget);

            }

            await SequenceModel.updateSequence(sequence._id, sequence);

            closeLoader();

            this.goBack();

        } catch (e) {

            console.error(e);
            closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        } finally {

            closeLoader();

        }

    }

    goBack = () => this.props.navigate(`/sequences/edit/${ this.state.sequenceId }`);

    closeModal = () => this.setState({ modal: ClosedModal });

    render() {

        const {
            breadcrumbs,
            modal,
            mainLevel1Options, mainLevel2Options, mainLevel3Options,
            secondaryLevel1Options, secondaryLevel2Options, secondaryLevel3Options,
            terciaryLevel1Options, terciaryLevel2Options, terciaryLevel3Options,
            form } = this.state;

        return (
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='sequences' />
                <Container style={{ paddingTop: '20px' }}>
                    <SecondarySubmenu
                        title={ I18n.t('sequences.sequenceInfo') }
                        mainFn={{ callback: this.goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                        breadcrumbs={ breadcrumbs }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <div className='sequenceObjectivesEdit'>
                                            <Form name='sequenceObjectivesForm' noValidate onSubmit={ this.onSubmit }>
                                                <Form.Field>
                                                    <label className='primary-font regular'>{ I18n.t('sequences.mainObjective') }</label>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('mainObjectiveLevel1', '' + value) }
                                                        options={ mainLevel1Options }
                                                        value={ form.mainObjectiveLevel1 }
                                                        selection
                                                        fluid
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('mainObjectiveLevel2', '' + value) }
                                                        options={ mainLevel2Options }
                                                        value={ form.mainObjectiveLevel2 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t('sequences.selectFirstMainType') }
                                                        disabled={ _.size(mainLevel2Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('mainObjectiveLevel3', '' + value) }
                                                        options={ mainLevel3Options }
                                                        value={ form.mainObjectiveLevel3 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t(form.mainObjectiveLevel2 === undefined ? 'sequences.selectFirstSecondaryType' : 'sequences.nothingToAdd') }
                                                        disabled={ _.size(mainLevel3Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label className='primary-font regular'>{ I18n.t('sequences.secondaryObjective') }</label>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('secondaryObjectiveLevel1', '' + value) }
                                                        options={ secondaryLevel1Options }
                                                        value={ form.secondaryObjectiveLevel1 }
                                                        selection
                                                        fluid
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('secondaryObjectiveLevel2', '' + value) }
                                                        options={ secondaryLevel2Options }
                                                        value={ form.secondaryObjectiveLevel2 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t('sequences.selectFirstMainType') }
                                                        disabled={ _.size(secondaryLevel2Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('secondaryObjectiveLevel3', '' + value) }
                                                        options={ secondaryLevel3Options }
                                                        value={ form.secondaryObjectiveLevel3 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t(form.secondaryObjectiveLevel2 === undefined ? 'sequences.selectFirstSecondaryType' : 'sequences.nothingToAdd') }
                                                        disabled={ _.size(secondaryLevel3Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label className='primary-font regular'>{ I18n.t('sequences.terciaryObjective') }</label>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('terciaryObjectiveLevel1', '' + value) }
                                                        options={ terciaryLevel1Options }
                                                        value={ form.terciaryObjectiveLevel1 }
                                                        selection
                                                        fluid
                                                        disabled={ form.secondaryObjectiveLevel1 === '0' }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('terciaryObjectiveLevel2', '' + value) }
                                                        options={ terciaryLevel2Options }
                                                        value={ form.terciaryObjectiveLevel2 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t('sequences.selectFirstMainType') }
                                                        disabled={ _.size(terciaryLevel2Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Dropdown onChange={ (e, { value }) => this.onSelectChange('terciaryObjectiveLevel3', '' + value) }
                                                        options={ terciaryLevel3Options }
                                                        value={ form.terciaryObjectiveLevel3 }
                                                        selection
                                                        fluid
                                                        placeholder={ I18n.t(form.terciaryObjectiveLevel2 === undefined ? 'sequences.selectFirstSecondaryType' : 'sequences.nothingToAdd') }
                                                        disabled={ _.size(terciaryLevel3Options) === 0 }
                                                    />
                                                </Form.Field>
                                                <OButton
                                                    $upper
                                                    type='submit'
                                                    $color='#FFF'
                                                    $terciary
                                                    fluid
                                                >
                                                    <span>{ I18n.t('buttons.actions.save') }</span>
                                                </OButton>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='sequences' />
            </Wrapper>
        );

    }

}

export default HooksService.withRouter(connect(null, { openLoader, closeLoader })(SequenceObjectives));