// Third party libraries
import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';

// Components
import { FormSelectOptionsInterface } from '../../../../../components/form/select';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Fill CONST
const durationSelectOptions: FormSelectOptionsInterface[] = [];
let i = 0;
while (i <= 600) {

    if (i <= 60 && i % 5 === 0) {

        durationSelectOptions.push({
            key: i,
            text: i + ' ' + I18n.t('sequences.seconds'),
            value: i
        });

    } else if (i > 60 && i <= 180 && i % 15 === 0) {

        durationSelectOptions.push({
            key: i,
            text: i + ' ' + I18n.t('sequences.seconds'),
            value: i
        });

    } else if (i > 180 && i < 300 && i % 30 === 0) {

        durationSelectOptions.push({
            key: i,
            text: i + ' ' + I18n.t('sequences.seconds'),
            value: i
        });

    } else if (i >= 300 && i % 60 === 0) {

        durationSelectOptions.push({
            key: i,
            text: (i / 60) + ' ' + I18n.t('sequences.minutes'),
            value: i
        });

    }

    i++;

};

export const DURATION_SELECT_OPTIONS = durationSelectOptions;

type Props = {
    initialDuration: number,
    onChangeDuration: (initialDuration: number) => void
};

const DurationSelector = ({ initialDuration, onChangeDuration }: Props) => {

    const [duration, setDuration] = useState<number>(initialDuration);
    const [durationSelectOptions, setDurationSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const durationSelectOptions: FormSelectOptionsInterface[] = [];
        let i = 0;
        while (i <= 600) {

            if (i <= 60 && i % 5 === 0) {

                durationSelectOptions.push({
                    key: i,
                    text: i + ' ' + I18n.t('sequences.seconds'),
                    value: i
                });

            } else if (i > 60 && i <= 180 && i % 15 === 0) {

                durationSelectOptions.push({
                    key: i,
                    text: i + ' ' + I18n.t('sequences.seconds'),
                    value: i
                });

            } else if (i > 180 && i < 300 && i % 30 === 0) {

                durationSelectOptions.push({
                    key: i,
                    text: i + ' ' + I18n.t('sequences.seconds'),
                    value: i
                });

            } else if (i >= 300 && i % 60 === 0) {

                durationSelectOptions.push({
                    key: i,
                    text: (i / 60) + ' ' + I18n.t('sequences.minutes'),
                    value: i
                });

            }

            i++;

        }

        setDurationSelectOptions(durationSelectOptions);

    }, []);

    useEffect(() => {

        setDuration(initialDuration);

    }, [initialDuration]);

    const handleChangeDuration = () => {

        onChangeDuration(duration);

    };

    return (
        <Form onSubmit={ handleChangeDuration }>
            <Form.Field style={{ minHeight: '150px' }}>
                <Dropdown
                    options={ durationSelectOptions }
                    value={ duration }
                    selection
                    fluid
                    onChange={ (e, { value }) => setDuration(parseInt('' + value)) }
                    onBlur={ (e, { value }) => setDuration(parseInt('' + value)) }
                    style={{ marginBottom: '10px' }}
                />
                <div>
                    <OButton fluid type='submit' $color='white' $terciary >
                        <span>{ I18n.t('buttons.actions.save') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );

}

export default DurationSelector;