// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import QueryString from 'query-string';

// Components
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../components/genericModal';
import Messager from '../../../components/messager/Messager';

// Styled components
import { LoginRemember, ForgotPassword } from './styled';
import { AuthBox, AuthCol, AuthCTARegister, AuthHeader, AuthRow, AuthSeparator, AuthTitle } from '../styled';
import OButton from '../../../styled/button';

// Models
import { CredentialsInterface } from '../../../../models/security';

// Redux
import { loginUser, closeSession } from '../../../../redux-store/auth';
import { getCurrentLicense } from '../../../../redux-store/license';
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Locales
import I18n from '../../../../i18n';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    loginUser: any;
    closeSession: any;
};

interface LoginProps extends MapDispatchProps {
    getCurrentLicense?: any;
    location?: any;
    navigate?: any;
    handleSubmit?: any;
};

interface LoginState {
    hasError: boolean;
    codeError: number | string;
    modal: GenericModalProps;
};

class Login extends React.Component<LoginProps & InjectedFormProps<{}, LoginProps>, LoginState> {

    constructor(props: LoginProps & InjectedFormProps<{}, LoginProps>) {

        super(props);

        this.state = {
            hasError: false,
            codeError: -1,
            modal: ClosedModal
        };

    }

    componentDidMount() {

        this.checkExpiredSession();

    }

    checkExpiredSession = () => {

        const { closeSession } = this.props;

        if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = QueryString.parse(UrlQueryStrings);

            if (queryValues.expired) {

                this.setState({
                    modal: {
                        open: true,
                        title: I18n.t('popups.alert'),
                        renderBody: () => <span>{ I18n.t('popups.sessionExpired') }</span>,
                        renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                    }
                });

            } else if (queryValues.logout) {

                closeSession();

            }

        }

    }

    closeModal = () => this.setState({ modal: ClosedModal });

    onForgotPassword = () => this.props.navigate(`/auth/recovery`);

    onRegister = () => this.props.navigate('/auth/register');

    onLogin = async (credentials: CredentialsInterface) => {

        const { openLoader, closeLoader, getCurrentLicense, navigate, loginUser } = this.props;
        const { email, password, remember } = credentials;
        const formValues = { email: _.trim(email), password, remember };

        try {

            openLoader();
            this.setState({ hasError: false, codeError: -1 });
            await loginUser(formValues);
            await getCurrentLicense();
            navigate('/students');

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });

        } finally {

            closeLoader();

        }

    };

    render() {

        const { codeError, hasError, modal } = this.state;

        return (
            <AuthRow>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{I18n.t('login.title')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <Messager code={ codeError } active={ hasError } $variant={ 'incorrect' } />
                        <Form name='loginForm' onSubmit={ this.props.handleSubmit(this.onLogin) } noValidate>
                            <AuthSeparator />
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('login.email') }
                                name='email'
                                fieldClasses='y-input default small'
                                label={ I18n.t('login.email') }
                                restrictions={ [{ trim: true }] }
                            />
                            <Field
                                component={ CustomInput }
                                placeholder={ '' }
                                name='password'
                                type='password'
                                fieldClasses='y-input default small'
                                label={ I18n.t('login.password') }
                            />
                            <LoginRemember>
                                <div>
                                    <Field
                                        component={ CustomCheckBox }
                                        name='remember'
                                        fieldClasses='y-checkbox'
                                        label={ I18n.t('login.remember') }
                                    />
                                </div>
                                <ForgotPassword onClick={ this.onForgotPassword }>
                                    <span className='labeling regular c-pointer'>{ I18n.t('login.forgotYourPassword') }</span>
                                </ForgotPassword>
                            </LoginRemember>
                            <AuthSeparator />
                            <OButton type='submit' fluid $upper $color='#FFF' $terciary>
                                <span>{ I18n.t('buttons.actions.login') }</span>
                            </OButton>
                            <AuthSeparator />
                            <AuthCTARegister>
                                <span className='labeling regular'>{ I18n.t('login.noAccountYet') }</span>
                                <span className='labeling regular brand c-pointer' onClick={ this.onRegister }>{ I18n.t('login.registerNow') }</span>
                            </AuthCTARegister>
                        </Form>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
        );

    }

}

const validate = (formValues: CredentialsInterface) => {

    const errors: { email?: string; password?: string } = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

const form = reduxForm<{}, LoginProps>({
    form: 'loginForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        remember: false
    }
})(Login);

export default connect(null, { closeLoader, closeSession, getCurrentLicense, loginUser, openLoader })(form);