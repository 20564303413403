// Third party libraries
import Moment from 'moment';

// Models
import { PeriodicityEnum, PlansEnum } from '../models/payment';

// Assets
import AdvancedLogo from '../ui/assets/img/landing/advancedLogo.png';
import ProfessionalLogo from '../ui/assets/img/landing/professionalLogo.png';
import StandardLogo from '../ui/assets/img/landing/standardLogo.png';
import StarterLogo from '../ui/assets/img/landing/starterLogo.png';

// Locales
import I18n from '../i18n';

export interface PaymentPlanInterface {
    discount: number;
    logo: any;
    maxStudentsToManage: number;
    periodicityText: string;
    planPrice: number;
    newPlanPrice: number;
    totalAmount: number;
};

const offerExpireDate: string = Moment('16-10-2022', 'DD-MM-YYYY').format();

const getPlanDiscount = (periodicity: PeriodicityEnum): number => {

    if ((isPromotionExpired() && periodicity === PeriodicityEnum.Annual) || (!isPromotionExpired() && periodicity === PeriodicityEnum.Monthly)) { // Annual after promotion or montly on promotion

        return 25;

    } else if (isPromotionExpired()) { // Monthly after promotion

        return 0;

    } else { // Annual on promotion

        return 50;

    }

};

const getPlanData = (plan: PlansEnum, periodicity: PeriodicityEnum): PaymentPlanInterface => {

    const planData : PaymentPlanInterface = {
        discount: getPlanDiscount(periodicity),
        logo: undefined,
        maxStudentsToManage: 0,
        periodicityText: I18n.t(`landing.monthlyPeriod`),
        planPrice: 0,
        newPlanPrice: 0,
        totalAmount: 0
    };

    switch (plan) {

        case PlansEnum.Advanced:

            planData.logo = AdvancedLogo;
            planData.maxStudentsToManage = 70;
            planData.planPrice = 69;
            break;

        case PlansEnum.Professional:

            planData.logo = ProfessionalLogo;
            planData.maxStudentsToManage = 100;
            planData.planPrice =  99;
            break;

        case PlansEnum.Starter:

            planData.logo = StarterLogo;
            planData.maxStudentsToManage = 10;
            planData.planPrice = 19;
            break;

        case PlansEnum.Standard:

            planData.logo = StandardLogo;
            planData.maxStudentsToManage = 40;
            planData.planPrice = 39;
            break;

    }

    planData.newPlanPrice = planData.planPrice * (100 - planData.discount) / 100;
    planData.totalAmount = planData.newPlanPrice * (periodicity === PeriodicityEnum.Annual ? 12 : 1);

    return planData;

};

const getPromotionExpireDateString = (): string => offerExpireDate;

const isPromotionExpired = (): boolean => Moment().isAfter(Moment(offerExpireDate));

const exports = {
    getPlanData,
    getPromotionExpireDateString
};

export default exports;