import React from 'react';
import { Form, TextArea, Label, SemanticCOLORS } from 'semantic-ui-react';

interface ParamsInterface {
    fieldClasses?: string,
    label?: string,
    colorLabel?: SemanticCOLORS,
    meta: {
        touched?: boolean,
        invalid?: boolean,
        error?: string
    },
    input: {
        onChange?: any
    },
    placeholder?: string,
    disabled?: boolean
};

const CustomTextArea = (params: ParamsInterface) => {

    return (
        <Form.Field className={ params.fieldClasses } error={ params.meta.touched && params.meta.invalid }>
            <label className='primary-font regular'>{ params.label }</label>
            <TextArea
                { ...params.input }
                disabled={params.disabled || false}
                placeholder={ params.placeholder }
                onChange={ e => params.input.onChange(e) }
            />
            { params.meta.touched && params.meta.invalid && <Label basic color={ params.colorLabel || 'red' } pointing>{ params.meta.error }</Label> }
        </Form.Field>
    );

}

export default CustomTextArea;