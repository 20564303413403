// Third party libraries
import { ThunkAction } from 'redux-thunk';

// Models
import SecurityModel, { CredentialsInterface, ProfileInterface } from '../models/security';

// States
interface AuthState { userSession: ProfileInterface | {}};
const INITIAL_STATE: AuthState = { userSession: {} } as const;

type UnloggedUser = typeof INITIAL_STATE;

type LoggedInUser = { userSession: ProfileInterface };

type State = UnloggedUser | LoggedInUser;

// Action Types
const ACTION_TYPES = {
    LOGIN_USER: 'LOGIN_USER',
    REGISTER_USER: 'REGISTER_USER',
    CLOSE_USER_SESSION: 'CLOSE_USER_SESSION',
    GET_USER_PROFILE:  'GET_USER_PROFILE',
    UPDATE_USER_PROFILE:  'UPDATE_USER_PROFILE'
} as const;

type ActionTypes = typeof ACTION_TYPES;

type LoginAction = {
    type: ActionTypes['LOGIN_USER'],
    payload: {}
};

type RegisterAction = {
    type: ActionTypes['REGISTER_USER'],
    payload: {}
};

type LogoutAction = {
    type: ActionTypes['CLOSE_USER_SESSION']
};

type RefreshUserSessionAction = {
    type: ActionTypes['GET_USER_PROFILE'],
    payload: {}
};

type UpdateUserAction = {
    type: ActionTypes['UPDATE_USER_PROFILE'],
    payload: {}
};

type Action = LoginAction | RegisterAction | LogoutAction | RefreshUserSessionAction | UpdateUserAction;

// Actions
export const getProfile = (): ThunkAction<void, any, unknown, any> => async dispatch => {

    try {

        const response = await SecurityModel.getProfile();
        dispatch ({ type: ACTION_TYPES.GET_USER_PROFILE, payload: { userSession: response } });

    } catch (errorCode) {

        dispatch ({ type: ACTION_TYPES.GET_USER_PROFILE, payload: {} });
        throw errorCode;

    }

};

export const loginUser = (credentials: CredentialsInterface): ThunkAction<void, any, unknown, any> => async dispatch => {

    try {

        const response = await SecurityModel.login(credentials);
        window.localStorage.setItem('jwt', response.data.jwt);
        dispatch ({ type: ACTION_TYPES.LOGIN_USER, payload: { userSession: response.data.user }});

    } catch (errorCode) {

        dispatch ({ type: ACTION_TYPES.LOGIN_USER, payload: { userSession: {} }});
        throw errorCode;

    }

};


export const registerUser = (credentials: CredentialsInterface): ThunkAction<void, any, unknown, any> => async dispatch => {

    try {

        const response = await SecurityModel.register(credentials);
        window.localStorage.setItem('jwt', response.data.jwt);
        dispatch ({ type: ACTION_TYPES.REGISTER_USER, payload: {userSession: response.data.user} });

    } catch (errorCode) {

        dispatch ({ type: ACTION_TYPES.REGISTER_USER, payload: { userSession: {} }});
        throw errorCode;

    }

};

export const updateProfile = (userData: ProfileInterface): ThunkAction<void, any, unknown, any> => async dispatch => {

    try {

        const data = await SecurityModel.updateProfile(userData);
        dispatch ({ type: ACTION_TYPES.UPDATE_USER_PROFILE, payload: { userSession: data.item } });

    } catch (errorCode) {

        dispatch ({ type: ACTION_TYPES.UPDATE_USER_PROFILE, payload: { userSession: {} }});
        throw errorCode;

    }

};

export const closeSession = () => {

    if (window.localStorage.getItem('jwt')) {

        window.localStorage.removeItem('jwt');

    }

    return { type: ACTION_TYPES.CLOSE_USER_SESSION }

}

// Reducer
const authReducer = (state: State = INITIAL_STATE, action: Action) => {

    switch (action.type) {

        case ACTION_TYPES.LOGIN_USER:
        case ACTION_TYPES.REGISTER_USER:
        case ACTION_TYPES.GET_USER_PROFILE:
        case ACTION_TYPES.UPDATE_USER_PROFILE:

            return { ...state, ...action.payload };

        case ACTION_TYPES.CLOSE_USER_SESSION:

            return INITIAL_STATE;

        default:

            return state;

    }

};

export default authReducer;