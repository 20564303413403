import _ from 'lodash';
import AxiosService from '../utils/AxiosService';
import Config from '../config';

export interface ListDataInterface {
    name: string;
    _id?: string;
    datas: any[];
    description?: string;
};

const getListData = async (listId: string): Promise<ListDataInterface> => _.get(await AxiosService.get(Config.server.api.securityApi + `core/list/${ listId }`, false), 'data', {});

export default {
    getListData
};