// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import SequenceModel, { EmptySequence, NewSequenceInterface, SequenceInterface } from '../../../models/sequence';

// Components
import BottomNav from '../../components/bottomNav/BottomNav';
import CloneSequenceModalBody from './components/cloneSequenceModalBody';
import CustomList, { ItemActionInterface, ItemInterface } from '../../components/list';
import GenericModal, { ClosedModal, GenericModalProps } from '../../components/genericModal';
import OButton from '../../styled/button';
import PanelNav from '../../components/nav/PanelNav';
import Submenu from '../../components/nav/Submenu';

// Styled components
import { Wrapper } from '../../styled/wrappers';

// Locales
import I18n from '../../../i18n';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface SequencesProps extends MapDispatchProps {
    navigate?: any;
};

interface SequenceListItem extends SequenceInterface {
    selected?: boolean;
}

interface SequencesState {
    filteredSequences: SequenceListItem[];
    filterValue: string;
    itemFns: ItemActionInterface[];
    modal: GenericModalProps;
    sequences: SequenceInterface[];
    sequenceList: ItemInterface[];
};

class Sequences extends React.Component<SequencesProps, SequencesState> {

    constructor(props: SequencesProps) {

        super(props);

        this.state = {
            filteredSequences: [],
            filterValue: '',
            itemFns: [{
                callback: this.onCloneSequence,
                icon: 'copy outline'
            }],
            modal: ClosedModal,
            sequences: [],
            sequenceList: []
        };

    }

    componentDidMount() {

        this.getData();

    }

    dataToList = (list: any[], selectable: boolean): ItemInterface[] => {

        const returnedList: ItemInterface[] = [];
        _.each(list, item => {

            returnedList.push({
                _id: item._id,
                selectable,
                selected: !!item.selected,
                pictureUrl: item.thumbUrl,
                header: item.title.ES.toUpperCase(),
                description: _.size(item.routine) + ' ' + I18n.t('sequences.exercises')
            });

        });
        return returnedList;

    };

    getData = async () => {

        this.props.openLoader();

        const sequences: SequenceInterface[] = await SequenceModel.getSequences();
        const filteredSequences = _.orderBy(sequences, 'name', 'desc');

        const sequenceList = this.dataToList(filteredSequences, false);

        this.setState({ filteredSequences, sequences, sequenceList });

        this.props.closeLoader();

    };

    onCloneSequence = (item: ItemInterface) => {

        const { closeLoader, openLoader } = this.props;

        const cloneSequence = async (newSequence: NewSequenceInterface | SequenceInterface) => {

            this.closeModal();

            openLoader();

            try {

                const sequenceCreated = await SequenceModel.createSequence(newSequence);
                await SequenceModel.updateSequence(sequenceCreated._id, newSequence as SequenceInterface);
                this.getData();

            } catch (error) {

                this.setState({ modal: {
                    open: true,
                    title: I18n.t('buttons.actions.error'),
                    renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                    renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
                }});

            }

            closeLoader();
        };

        const { sequences } = this.state;
        const baseSequence: NewSequenceInterface | SequenceInterface = _.find(sequences, sequence => sequence._id === item._id) || EmptySequence;

        this.setState({ modal: {
            open: true,
            title: I18n.t('buttons.actions.clone'),
            renderBody: () => <><span>{ I18n.t('sequences.cloneSequenceModalText') }</span><CloneSequenceModalBody baseSequence={ baseSequence } onSubmit={ cloneSequence } /></>
        }});

    };

    onFilter = (filterValue: string) => {

        let filteredSequences = [ ...this.state.sequences ];
        let sequenceList = [ ...this.state.sequenceList ];

        if (filterValue) {

            filteredSequences = _.filter(filteredSequences, sequence => sequence?.title?.ES?.toUpperCase().includes(filterValue.toUpperCase()))
            sequenceList = this.dataToList(filteredSequences, false);

        }

        this.setState({ filteredSequences, filterValue, sequenceList })

    }

    closeModal = () => this.setState({ modal: ClosedModal });

    render() {

        const { navigate } = this.props;
        const { filterValue, itemFns, modal, sequenceList } = this.state;

        return (
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='sequences' />
                <Container style={{ paddingTop: '20px' }}>
                    <Submenu
                        filterFn={ this.onFilter }
                        filterValue={ filterValue }
                        mainAction={{ title: I18n.t('sequences.newSequence'), callback: () => navigate('/sequences/new') }}
                        title={ I18n.t('menu.sequences') }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <CustomList
                                            items={ sequenceList }
                                            itemFns={ itemFns }
                                            onClickItemFn={ (item: ItemInterface) => navigate(`/sequences/edit/${ item._id }`) }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='sequences' />
            </Wrapper>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(Sequences);