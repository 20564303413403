// Third party libraries
import { Container, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

// Styled components
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

const EnterpriseContact = () => {

    const navigate = useNavigate();

    return <div className='enterpriseContact'>
        <Container className='enterpriseContactContainer'>
            <OButton $primary type='button' $color='#00B595' className='goBackButton' onClick={ () => navigate('/') }>
                <Icon name='arrow left' />
                <span>{ I18n.t('buttons.actions.goBack') }</span>
            </OButton>
            <span className='title'>{ I18n.t('landing.yogaCenter') }</span>
            <br />
            <br />
            <span className='bodyText'>{ I18n.t('landing.enterpriseTexts.firstLine') }</span>
            <br />
            <span className='bodyText'>{ I18n.t('landing.enterpriseTexts.secondLine') }</span>
            <br />
            <span className='bodyText'>{ I18n.t('landing.enterpriseTexts.namaste') }</span>
        </Container>
        <iframe src='https://survey.zohopublic.com/zs/wCzVVh' frameBorder='0' marginWidth={ 0 } marginHeight={ 0 } scrolling='auto' allow='geolocation'></iframe>
        <OButton $terciary type='button' $color='#fff' className='lastGoBackButton' onClick={ () => navigate('/') }>
            <span>{ I18n.t('buttons.actions.goBack') }</span>
        </OButton>
    </div>;

}

export default EnterpriseContact;