// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

// Components
import CustomInput from '../../../components/form/input';
import Messager from '../../../components/messager/Messager';

// Styled components
import { AuthBox, AuthCol, AuthRow} from '../styled';
import { AuthCTALogin, AuthHeader, AuthSeparator, AuthTitle } from '../styled';
import OButton from '../../../styled/button';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Models
import SecurityModel, { RecoveryInterface } from '../../../../models/security';

// Locales
import I18n from '../../../../i18n';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface RecoveryProps extends MapDispatchProps {
    location?: any;
    navigate?: any;
    handleSubmit?: any;
};

interface RecoveryState {
    hasError: boolean;
    codeError: number | string;
    correct: boolean;
};

class Recovery extends React.Component<RecoveryProps & InjectedFormProps<{}, RecoveryProps>, RecoveryState> {

    constructor (props: RecoveryProps & InjectedFormProps<{}, RecoveryProps>) {

        super (props);

        this.state = {
            codeError: -1,
            hasError: false,
            correct: false
        }

    }

    onRecovery = async (payload: RecoveryInterface) => {

        const { closeLoader, openLoader } = this.props;

        try {

            openLoader();
            await SecurityModel.recoveryPassword({ email: payload.email });
            this.setState({ correct: true, hasError: false });

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-recovery' });

        } finally {

            closeLoader();

        }

    };

    gotoLogin = () => this.props.navigate(`/auth/login`);

    render() {

        const { codeError, hasError, correct } = this.state;
        const { valid, handleSubmit } = this.props

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{ I18n.t('recovery.recoveryYourPassword') }</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className='paragraph regular'>{ I18n.t('recovery.recoveryMessage') }</p>
                        <AuthSeparator />
                        <Form name='recoveryForm' onSubmit={ handleSubmit(this.onRecovery) }>
                            <Messager code={ codeError } active={ hasError } $variant='incorrect' />
                            <Messager code='correct-recovery' active={ correct } $variant='correct' />
                            <AuthSeparator>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('recovery.email') }
                                    name='email'
                                    fieldClasses='y-input default small'
                                    label={ I18n.t('recovery.email') }
                                    restrictions={ [{ trim: true }] }
                                />
                            </AuthSeparator>
                            <AuthSeparator />
                            <OButton $upper type='submit' fluid $color='#FFF' $terciary disabled={ !valid } >
                                <span>{ I18n.t('recovery.recoveryPassword') }</span>
                            </OButton>
                            <AuthSeparator />
                            <AuthCTALogin style={{ textAlign: 'center' }}>
                                <span className='labeling regular brand c-pointer' onClick={ this.gotoLogin }>{ I18n.t('buttons.actions.cancel') }</span>
                            </AuthCTALogin>
                        </Form>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
        );

    }

}

const validate = (formValues: RecoveryInterface ) => {

    const errors: { email?: string; } = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

const form = reduxForm<{}, RecoveryProps>({
    form: 'recoveryForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: ''
    }
})(Recovery);

export default connect(null, { openLoader, closeLoader })(form);