export {};

const config = {
    debugMode: true,
    server: {
        api: {
            biometricMatrixApi: process.env.REACT_APP_BIOMETRICMATRIX_API || 'https://biometricmatrix-dev.yogabot.app/biometricmatrix/',
            filesApi: process.env.REACT_APP_FILES_API || 'https://files-dev.yogabot.app/files/',
            groupApi: process.env.REACT_APP_GROUP_API || 'https://security-dev.yogabot.app/security/',
            paymentApi: process.env.REACT_APP_PAYMENT_API || 'https://payments-dev.yogabot.app/payment/',
            studentApi: process.env.REACT_APP_STUDENT_API || 'https://security-dev.yogabot.app/security/',
            securityApi: process.env.REACT_APP_SECURITY_API || 'https://security-dev.yogabot.app/security/',
            sessionsApi: process.env.REACT_APP_SESSIONS_API || 'https://sessions-dev.yogabot.app/session/',
            timeout: 20000,
            headTimeout: 2000
        }
    },
    paypal: { clientId: process.env.REACT_APP_PRO_PAYPAL_CLIENT_ID || 'AW-odrm1g-wVN_8IvUvqB5njaTGm4Q2Z6icSKcLeMANYvkzgCOZVHac2jMdT_8C7CTBNoDNEVU2f1uvO' }, //TODO: Poner la correcta
    stripe: { publicKey: process.env.REACT_APP_PRO_STRIPE_PUBLIC_KEY || 'pk_test_51LaIfvBB31PWv5DmgaZ4yyoKmgZBnc6nx9I9nVkU9OIRxDqMR5maRZX2QTOtQtbs2bIid8gE1mtvLTDh41kNMHgf00mxFoRS8Z' }
};

export default config;