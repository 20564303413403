import styled, { css } from 'styled-components/macro';

import OButton from './button';
import { alignCenter, alignStart, column, flex, justifyCenter, wrap } from './css';
import { device } from './mbreakpoints';

type Props = {
    forRead?: boolean;
    xL?: boolean;
    fWrap?: boolean;
    alignCenter?: boolean;
    justifyCenter?: boolean;
    flexStart?: boolean;
    fHeight?: number;
    column?: string;
    $wrap?: string;
    $alignItems?: string;
    $justifyContent?: string;
    $direction?: string;
    reverse?: boolean;
    hero?: boolean;
    alternative?: boolean;
    $spacex?: boolean;
    flexEnd?: boolean;
    equal?: boolean;
    two?: boolean;
    three?: boolean;
    fourth?: boolean;
    growed?: boolean;
    flex?: boolean;
    auto?: boolean;
    aicenter?: boolean;
    jccenter?: boolean;
};

const Wrapper = styled.div`
    min-height:100%;
    padding-bottom:48px;

    @media(min-width:768px) {
        padding-bottom:0;
    }
`;

const Inner = styled.div<Props>`
    width:100%;
    max-width: ${ p => p.forRead ? `100ch` : `var(--max-width-container)`};
    margin: 0 auto;
    padding-right: var(--padding-regular-x);
    padding-left: var(--padding-regular-x);
    position:relative;

    ${({ hero }) => hero && css`
        height:100%;
        display:flex;
        align-items:center;
    `};
`;

const Separator = styled.div<Props>`
    padding-bottom: ${ p => (p.xL ? 'var(--padding-large-y)' : 'var(--padding-regular-y)') }
`;

const Box = styled.div`
    background-color:var(--brand-primary);
    padding: var(--padding-regular-y) var(--padding-regular-x);
`;

const FlexWrapper = styled.div<Props>`
    ${ flex };
    ${ p => p.fWrap && wrap };
    ${ p => p.alignCenter && alignCenter };
    ${ p => p.justifyCenter && justifyCenter };
    ${ p => p.flexStart && alignStart };
    height:${ p => p.fHeight ? p.fHeight : 'auto'};
    flex-direction:${ p => (p.column ? column : 'column') };

    ${({ alternative }) => alternative && css`
        @media ${ device.tablet } {
            background: var(--brand-primary);
            padding: 2rem;
            border-radius: 10px;
        }
    `};
`;

const FlexBox = styled.div<Props>`
    display:flex;
    flex-wrap: ${ p => p.$wrap ? p.$wrap : 'no-wrap'};
    align-items: ${ p => p.$alignItems ? p.$alignItems : 'stretch' };
    justify-content: ${ p => p.$justifyContent ? p.$justifyContent : 'stretch' };
    flex-direction: ${ p => p.$direction ? p.$direction : 'row' };

    ${({ $spacex }) => $spacex && css`
        & > * + *{
            margin-right: calc(0.375rem * 0);
            margin-left: calc(0.375rem * calc(1 - 0));
        }
    `}

    @media ${ device.tablet } {
        &.mobileActions {
            display: none;
        }
    }
`;

const ItemPicture = styled.div`
    flex-shrink:1;
    flex-grow:0;
    min-width:0;
    flex-basis: 100%;
    margin: 20px;
    @media ${ device.tablet } {
        flex-basis:377px;
        max-width:377px;
      }

    img {
        width:100%;
        object-fit:contain;
    }
`;

const ButtonsWrapper = styled.div`
    display:flex;
    justify-content:center;
`;

const ButtonWrapper = styled.div<Props>`
    display: flex;
    margin-top: 2.5rem;
    justify-content:${ ({ reverse }) => reverse ? 'flex-start' : 'flex-end'};

    ${ OButton } {
        flex: 1 1 0%;
        @media ${ device.mobileL } {
            flex: 0 1 22.7rem;
        }
    }
`;

const Row = styled.div<Props>`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${ ({ flexEnd }) => flexEnd ? 'flex-end' : 'space-between' };
    margin-bottom: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0px;
    }
`;

const Col = styled.div<Props>`
    ${ ({ equal }) => equal &&
        css`
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 100%;
            min-width: 0;
        `};

    ${ ({ two }) => two &&
        css`
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 48%;
            min-width: 0;
        `};

    ${ ({ three }) => three &&
        css`
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 32%;
            min-width: 0;
        `};

    ${ ({ fourth }) => fourth &&
        css`
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 23%;
            min-width: 0;
        `};

    ${ ({ growed }) => growed &&
        css`
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            min-width: 0;
        `};

    ${ ({ flex }) => flex &&
        css`
            display: flex;
            min-width: 0;
        `};

    ${ ({ auto }) => auto &&
        css`
            flex: 0 0 auto;
            width: auto;
            max-width: none;
        `};

    ${ ({ aicenter }) => aicenter &&
        css`
            align-items: center;
        `};

    ${ ({ jccenter }) => jccenter &&
        css`
            justify-content: center;
        `};

    @media (max-width:768px) {
        margin-bottom:30px;
    }
`;

export {
    Box,
    ButtonWrapper,
    ButtonsWrapper,
    Col,
    Inner,
    FlexBox,
    FlexWrapper,
    ItemPicture,
    Row,
    Separator,
    Wrapper
};