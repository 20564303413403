// Third party libraries
import _ from 'lodash';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// Styled components
import * as S from './styled';

type Props = {
    active?: string;
    anySelected?: boolean;
    disableMenu?: boolean;
    editMode?: boolean;
    groupActions?: {
        callback: () => void;
        icon: SemanticICONS;
        label: string;
    }[];
};

const BottomNav = ({ active, anySelected = false, disableMenu = false, editMode = false, groupActions = [] }: Props) => {

    return <>
        { !anySelected || !editMode || _.size(groupActions) === 0 ? '' :
            <S.BottomAction>{
                groupActions.map((action, index) => <S.BottomActionItem key={ action.label + '_' + index } role='tab' onClick={ action.callback }>{ action.label }</S.BottomActionItem>)
            }</S.BottomAction>
        }
        { !disableMenu &&
            <S.BottomNav role='tablist'>
                <S.BottomNavItem role='tab' $active={ active === 'students' } as={ Link } to='/students'>
                    <Icon name='student' />
                </S.BottomNavItem>
                <S.BottomNavItem role='tab' $active={ active === 'groups' } as={ Link } to='/groups'>
                    <Icon name='group' />
                </S.BottomNavItem>
                <S.BottomNavItem role='tab' $active={ active === 'sequences' } as={ Link } to='/sequences'>
                    <Icon name='edit outline' />
                </S.BottomNavItem>
            </S.BottomNav>
        }
    </>;

}

export default BottomNav;