// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Icon, Image, Segment, SemanticICONS, Table } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Models
import PaymentModel, { LicenseInterface, PaymentInterface, PeriodicityEnum, PlansEnum } from '../../../../models/payment';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import PanelNav from '../../../components/nav/PanelNav';
import SetupNav from '../components/SetupNav';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Services
import PaymentPlansService from '../../../../utils/PaymentPlansService';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

const Setup = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentLicense, setCurrentLicense] = useState<LicenseInterface>({ activeSubscription: false, maxStudents: 3, name: PlansEnum.Free });
    const [payments, setPayments] = useState<PaymentInterface[]>([]);

    useEffect(() => {

        const getData = async () => {

            dispatch(openLoader());

            try {

                const currentLicenseData = await PaymentModel.getCurrentLicense();
                if (!!currentLicenseData) {

                    setCurrentLicense(currentLicenseData);

                }

                const paymentList = await PaymentModel.getPayments();
                setPayments(paymentList);

            } catch (e) { } finally {

                dispatch(closeLoader());

            }

        };

        getData();

    }, []);

    //TODO: Descargar recibos

    const setCurrencyAndAmount = (amount: number, currency: string) => {

        switch (currency) {

            case 'USD':

                return `$${ _.round(amount, 2).toFixed(2) }`;

            case 'EUR':

                return `${ _.round(amount, 2).toFixed(2) }€`;

            default:

                return '';

        }

    }

    const setPaymentMethod = (platform: string, last4: number | undefined, brand: string | undefined) => {

        if (platform === 'stripe') {

            if (!!brand && ['amex', 'mastercard', 'visa'].includes(brand)) {

                return <><Icon name={ 'cc ' + brand as SemanticICONS } />&nbsp;<span>•••• •••• •••• { last4 }</span></>;

            } else {

                return <><Icon name='credit card outline' />&nbsp;<span>•••• •••• •••• { last4 }</span></>;

            }

        }

        return <Icon name='cc paypal' />;

    }

    const setDatePeriod = (startDate: string, endDate: string | undefined) => !!!endDate ? Moment(startDate).format('DD/MM/YYYY') : Moment(startDate).format('DD/MM/YYYY') + ' - ' + Moment(endDate).format('DD/MM/YYYY');

    const onLevelUpAccount = () => navigate('/setup/checkout/plans');

    const onCancel = async () => {

        dispatch(openLoader());

        try {

            await PaymentModel.cancelSubscription();

        } catch (e) { } finally {

            dispatch(closeLoader());

        }

    }

    return (
        <Wrapper>
            <PanelNav active='setup' />
            <SetupNav active='subscription' />
            <Container style={{ paddingTop: '20px' }}>
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='setupSubscription'>
                                        <Segment>
                                            <h3>{ I18n.t('menu.subscription') }</h3>
                                            <div style={{ marginBottom: '15px' }}>
                                                <Image src={ PaymentPlansService.getPlanData(currentLicense.name as PlansEnum, PeriodicityEnum.Annual).logo } avatar size='tiny' />
                                                <div className='licenseType'>
                                                    <span className='title'>{ I18n.t('setup.licenseType') }</span>
                                                    <span className='text'>{ currentLicense.name }</span>
                                                </div>
                                                <div className='studentMax'>
                                                    <span className='title'>{ I18n.t('setup.studentAllowedCount') }</span>
                                                    <span className='text'>{ currentLicense.maxStudents }</span>
                                                </div>
                                                <div className='expiredDate'>
                                                    <span className='title'>{ I18n.t('setup.expiredDate') }</span>
                                                    <span className='text'>{ Moment(currentLicense.endDate).format('DD/MM/YYYY') }</span>
                                                </div>
                                            </div>
                                            <OButton className='first' type='button' $color='#FFF' $terciary onClick={ onLevelUpAccount } disabled={ currentLicense.maxStudents === 100 }>
                                                <span>{ I18n.t('setup.levelUpSubscription') }</span>
                                            </OButton>
                                            <OButton type='button' $selected $color='#1bb89f' onClick={ onCancel } disabled={ currentLicense.activeSubscription }>
                                                <span>{ I18n.t('setup.cancelSubscription') }</span>
                                            </OButton>
                                        </Segment>
                                        <Segment>
                                            <h3>{ I18n.t('setup.payments') }</h3>
                                            { _.size(payments) > 0 ?
                                                <Table celled padded>
                                                    <Table.Header>
                                                        <Table.Row textAlign='center'>
                                                            <Table.HeaderCell className='hideOnMobile'>{ I18n.t('setup.receiptNumber') }</Table.HeaderCell>
                                                            <Table.HeaderCell>{ I18n.t('setup.date') }</Table.HeaderCell>
                                                            <Table.HeaderCell>{ I18n.t('setup.description') }</Table.HeaderCell>
                                                            <Table.HeaderCell className='hideOnMobile'>{ I18n.t('setup.servicePeriod') }</Table.HeaderCell>
                                                            <Table.HeaderCell className='hideOnMobile'>{ I18n.t('setup.paymentMethod') }</Table.HeaderCell>
                                                            <Table.HeaderCell>{ I18n.t('setup.total') }</Table.HeaderCell>
                                                            {/* <Table.HeaderCell>{ I18n.t('setup.receiptDownload') }</Table.HeaderCell> */}
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        { payments.map((payment: PaymentInterface) =>
                                                            <Table.Row key={ payment._id }>
                                                                <Table.Cell textAlign='center' className='hideOnMobile'>{ payment.receiptNumber }</Table.Cell>
                                                                <Table.Cell textAlign='center'>{ Moment(payment.createdAt).format('DD/MM/YYYY') }</Table.Cell>
                                                                <Table.Cell>{ payment.description }</Table.Cell>
                                                                <Table.Cell className='hideOnMobile'>{ setDatePeriod(payment.startDate, payment.endDate) }</Table.Cell>
                                                                <Table.Cell textAlign='center' className='hideOnMobile'>{ setPaymentMethod(payment.platform, payment.last4, payment.brand) }</Table.Cell>
                                                                <Table.Cell textAlign='right'>{ setCurrencyAndAmount(payment.amount, payment.currency) }</Table.Cell>
                                                                {/* <Table.Cell textAlign='center' className='c-pointer'><Icon name='download' /></Table.Cell> */}
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                                : <span>{ I18n.t('setup.noPayments') }</span>
                                            }
                                        </Segment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='setup' />
        </Wrapper>
    );

}

export default Setup;