// States
const INITIAL_STATE = { open: false } as const;

type ClosedState = typeof INITIAL_STATE;

type OpenedState = { open: true };

type State = ClosedState | OpenedState;

// Action Types
const ACTION_TYPES = {
    OPEN_LOADER: 'OPEN_LOADER',
    CLOSE_LOADER: 'CLOSE_LOADER'
} as const;

type ActionTypes = typeof ACTION_TYPES;

type OpenAction = {
    type: ActionTypes['OPEN_LOADER']
};

type CloseAction = {
    type: ActionTypes['CLOSE_LOADER']
};

type Action = OpenAction | CloseAction;

// Actions
export const openLoader = () => ({ type: ACTION_TYPES.OPEN_LOADER });
export const closeLoader = () => ({ type: ACTION_TYPES.CLOSE_LOADER });

// Reducer
const loaderReducer = (state: State = INITIAL_STATE, action: Action) => {

    switch (action.type) {

        case ACTION_TYPES.OPEN_LOADER:

            return { open: true };

        case ACTION_TYPES.CLOSE_LOADER:

            return INITIAL_STATE;

        default:

            return state;

    }

};

export default loaderReducer;