// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Moment from 'moment';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface ParamsInterface {
    expireDate: string;
    onExpireCallback?: () => void;
};

const Countdown = ({ expireDate, onExpireCallback = () => {} }: ParamsInterface) => {

    const [countdownText, setCountdownText] = useState<string>('');
    let countdownInterval: ReturnType<typeof setInterval> | undefined = undefined;

    useEffect(() => {

        !!countdownInterval && clearInterval(countdownInterval);

        const expireDateMoment = Moment(expireDate);

        countdownInterval = setInterval(() => {

            const now = Moment();
            let secondsToExpireDate = _.round(expireDateMoment.diff(now, 'seconds'));
            if (secondsToExpireDate < 0) {

                !!countdownInterval && clearInterval(countdownInterval);
                setCountdownText(I18n.t('landing.expiredTime'));
                onExpireCallback();

            } else {

                const seconds = secondsToExpireDate % 60;
                secondsToExpireDate -= seconds;
                const minutes = (secondsToExpireDate / 60) % 60;
                secondsToExpireDate -= (minutes * 60);
                const hours = (secondsToExpireDate / 60 / 60) % 24;
                secondsToExpireDate -= (hours * 60 * 60);
                const days = secondsToExpireDate / 60 / 60 / 24;
                setCountdownText(`${ days }d ${ hours }h ${ minutes }m ${ seconds }s`);

            }

        }, 500);

    }, [expireDate]);

    return <span className={ `countdown ${ countdownText === I18n.t('landing.expiredTime') ? 'expired' : '' }` }>{ countdownText }</span>;

};

export default Countdown;