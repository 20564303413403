import styled from 'styled-components/macro';

import { AlignCenter, flexbox, flexWrap, justifySpaceBetween } from '../../styled/helpers';

const LoginRemember = styled.div`
    ${ flexbox };
    ${ flexWrap };
    ${ justifySpaceBetween };
    ${ AlignCenter };
`;

const ForgotPassword = styled.div`
    span {
        cursor:pointer;
        padding-left:10px;
    }
`;

export {
    LoginRemember,
    ForgotPassword
}