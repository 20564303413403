import React from 'react';
import _ from 'lodash';
import { FrameCircle, FrameCirclePercent, CircleSvg, CircleShape, CircleNumber } from './styles';

interface CircleProgressProps {
    total: number;
    current: number;
    large?: boolean;
    radius?: number;
    text: string;
};

const CircleProgress = ({ current, total, large = false, radius = 60, text = '' }: CircleProgressProps) => {

    const CIRCUMFERENCE = (2 * Math.PI * radius);
    const PERCENT = current / total;

    return (
        <FrameCircle>
            <FrameCirclePercent large={ large }>
                <CircleSvg>
                    <CircleShape large={ large } r={ radius } cx={ radius } cy={ radius }></CircleShape>
                    <CircleShape r={ radius } cx={ radius } cy={ radius } style={{ stroke: '#00B595' }} large={ large } dashoffset={ _.round(CIRCUMFERENCE) }>
                        <animate attributeType='XML' begin='1s' attributeName='stroke-dashoffset' from={ _.round(CIRCUMFERENCE) } to={ _.round(CIRCUMFERENCE) * (1 - PERCENT) } dur='2s' fill='freeze'/>
                    </CircleShape>
                </CircleSvg>
                <CircleNumber>
                    <span style={{ color: '#00B595' }} className='heading-3'>{ text }</span>
                    <br/><span style={{ color: '#00B595' }} className='heading-3'>{ current + '/' + total }</span>
                </CircleNumber>
            </FrameCirclePercent>
        </FrameCircle>
    );

}

export default CircleProgress;