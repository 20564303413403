import _ from 'lodash';
import I18n from '../../i18n';

/** ************************************** Validations *************************************** */

export const required = (value: any) => (value || value === 0 ? undefined : I18n.t('validations.required'));
export const fileRequired = (value: any) => (_.get(value, 'fileName', '') !== '' ? undefined : I18n.t('validations.required'));
export const hexadecimal = (value: any) => ((/^#[0-9A-F]{6}$/i).test(value) ? undefined : I18n.t('form.validation.hexadecimal'));
export const minValue = (min: number) => (value: any) => (min <= value ? undefined : I18n.t('form.validation.minValue', { min }));
export const maxValue = (max: number) => (value: any) => (value <= max ? undefined : I18n.t('form.validation.maxValue', { max }));
export const minLength = (min: number) => (value: any) => (!value || min < value.length ? undefined : I18n.t('form.validation.minLength', { min }));
export const maxLength = (max: number) => (value: any) => (!value || value.length < max ? undefined : I18n.t('form.validation.maxLength', { max }));

/** ************************************** Restrictions ************************************** */

export const convertToNumeric = (value: any, canBeFloat = false) => {

    if (value) {

        let result = canBeFloat ? value.replace(/[^0-9.-]/g, '') : value.replace(/[^0-9-]/g, '');

        if (result.split('.').length > 2 || result.split('-').length > 2) {

            result = result.substring(0, result.length - 1);

        }

        return result;

    }

    return '';

};

export const trimString = (value: any) => {

    if (value) {

        const result = value.replace(/\s/g, '');

        return result;

    }

    return '';

};