// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Input } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import SequenceModel, { AsanaInterface, SequenceInterface } from '../../../../../../models/sequence';

// Components
import BottomNav from '../../../../../components/bottomNav/BottomNav';
import List, { ItemInterface } from '../../../../../components/list';
import PanelNav from '../../../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../../../components/nav/SecondarySubmenu';

// Styled components
import { Wrapper } from '../../../../../styled/wrappers';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface AsanaListInterface extends ItemInterface {
    code: string;
};

interface MainDataInterface {
    sequence: SequenceInterface;
    breadcrumbs: BreadcrumbsInterface[];
    asanas: AsanaListInterface[];
};

interface FilteredDataInterface {
    standingAsanas: ItemInterface[];
    seatedAsanas: ItemInterface[];
    proneAsanas: ItemInterface[];
    supineAsanas: ItemInterface[];
    invertedAsanas: ItemInterface[];
};

const SequenceEditRoutineAdd = () => {

    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filterValue, setFilterValue] = useState<string>('');

    const [mainData, setMainData] = useState<MainDataInterface>({
        sequence: {
            _id: '' + params.sequenceId,
            asanasCount: 0,
            createdAt: '',
            published: false,
            routine: [],
            targets: [],
            types: [],
            categories: [],
            description: { ES: '' },
            title: { ES: '' },
            level: ''
        },
        breadcrumbs: [],
        asanas: []
    });

    const [filteredData, setFilteredData] = useState<FilteredDataInterface>({
        standingAsanas: [],
        seatedAsanas: [],
        proneAsanas: [],
        supineAsanas: [],
        invertedAsanas: []
    });

    const prepareAsanaList = (asanas: AsanaListInterface[]) => {

        const data: FilteredDataInterface = {
            standingAsanas: [],
            seatedAsanas: [],
            proneAsanas: [],
            supineAsanas: [],
            invertedAsanas: []
        };

        _.each(_.filter(asanas, ({ header }) => header.toUpperCase().includes(filterValue.toUpperCase())), asana => {

            switch (asana.code.substring(2, 3)) {

                case 'P':

                    data.standingAsanas.push(_.omit(asana, ['code']));
                    break;

                case 'S':

                    data.seatedAsanas.push(_.omit(asana, ['code']));
                    break;

                case 'D':

                    data.proneAsanas.push(_.omit(asana, ['code']));
                    break;

                case 'L':

                    data.supineAsanas.push(_.omit(asana, ['code']));
                    break;

                case 'V':

                    data.invertedAsanas.push(_.omit(asana, ['code']));
                    break;

            }

        });

        setFilteredData(data);

    };

    useEffect(() => {

        const getData = async () => {

            dispatch(openLoader());

            const sequence = await SequenceModel.getSequence('' + params.sequenceId);

            const data = mainData;
            data.sequence = sequence;

            data.breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.sequences'),
                goTo: '/sequences'
            },
            {
                key: 1,
                label: sequence.title.ES,
                goTo: '/sequences/edit/' + params.sequenceId
            },
            {
                key: 2,
                label: I18n.t('sequences.exercises'),
                goTo: '/sequences/edit/' + params.sequenceId + '/asanas'
            },
            {
                key: 3,
                label: I18n.t('sequences.addExercises'),
                goTo: ''
            }];

            const asanaDBList: AsanaInterface[] = await SequenceModel.getAsanas();
            const asanaList: AsanaListInterface[] = [];
            _.each(asanaDBList, asana => {

                if (asana.isBeginner) {

                    asanaList.push({
                        _id: asana._id,
                        header: _.trim(asana.name.ES),
                        pictureUrl: asana.image,
                        selectable: true,
                        selected: false,
                        code: asana.code
                    });

                }

            });
            data.asanas = _.sortBy(asanaList, 'header');

            setMainData(data);
            prepareAsanaList(data.asanas);

            dispatch(closeLoader());

        };

        getData();

    }, []);

    useEffect(() => prepareAsanaList(mainData.asanas), [filterValue]);

    const onFilter = (value: string) => setFilterValue(value);

    const onSelect = (_id: string, checked: boolean) => {

        const asanas = [ ...mainData.asanas ];
        _.each(asanas, asana => {

            if (asana._id === _id) {

                asana.selected = checked;

            }

        });

        prepareAsanaList(asanas);

    };

    const onContinue = async () => {

        const sequence = mainData.sequence;
        _.each(mainData.asanas, asana => {

            if (!!asana.selected) {

                sequence.routine.push({ code: asana.code, duration: 30 });

            }

        });

        dispatch(openLoader());
        await SequenceModel.updateSequence('' + params.sequenceId, sequence);
        dispatch(closeLoader());
        navigate(`/sequences/edit/${ params.sequenceId }/asanas`);

    };

    const goBack = () => navigate(`/sequences/edit/${ params.sequenceId }/asanas`);

    return (
        <Wrapper>
            <PanelNav active='sequences' />
            <Container style={{ paddingTop: '20px' }}>
                <SecondarySubmenu
                    title={ I18n.t('sequences.addExercises') }
                    mainFn={{ callback: goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                    breadcrumbs={ mainData.breadcrumbs }
                    secondaryFn={{ callback: onContinue, label: I18n.t('buttons.actions.continue'), activeOnDesktop: true }}
                />
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='addExercise'>
                                        <Input
                                            placeholder={ I18n.t('submenu.searchPlaceholder') }
                                            type='text'
                                            onChange={ (e, { value }) => onFilter(value) }
                                            icon='search'
                                            fluid
                                        />
                                        <div className='exerciseList'>
                                            <div className='posturalGroupTitle'>
                                                <span>{ I18n.t('sequences.posturalGroups.standing').toUpperCase() }</span>
                                            </div>
                                            <List
                                                items={ filteredData.standingAsanas || [] }
                                                editMode={ true }
                                                onSelectItemFn={ onSelect }
                                            />
                                            <div className='posturalGroupTitle'>
                                                <span>{ I18n.t('sequences.posturalGroups.seated').toUpperCase() }</span>
                                            </div>
                                            <List
                                                items={ filteredData.seatedAsanas }
                                                editMode={ true }
                                                onSelectItemFn={ onSelect }
                                            />
                                            <div className='posturalGroupTitle'>
                                                <span>{ I18n.t('sequences.posturalGroups.prone').toUpperCase() }</span>
                                            </div>
                                            <List
                                                items={ filteredData.proneAsanas }
                                                editMode={ true }
                                                onSelectItemFn={ onSelect }
                                            />
                                            <div className='posturalGroupTitle'>
                                                <span>{ I18n.t('sequences.posturalGroups.supine').toUpperCase() }</span>
                                            </div>
                                            <List
                                                items={ filteredData.supineAsanas }
                                                editMode={ true }
                                                onSelectItemFn={ onSelect }
                                            />
                                            <div className='posturalGroupTitle'>
                                                <span>{ I18n.t('sequences.posturalGroups.inverted').toUpperCase() }</span>
                                            </div>
                                            <List
                                                items={ filteredData.invertedAsanas }
                                                editMode={ true }
                                                onSelectItemFn={ onSelect }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='sequences' />
        </Wrapper>
    );

}

export default SequenceEditRoutineAdd;