// Third party libraries
import { Icon } from 'semantic-ui-react';

// Components
import CustomList, { ItemActionInterface, ItemInterface } from '../list';

// Styled components
import OButton from '../../styled/button';

// Styles
import './index.scss';

type Props = {
    title: string;
    items: ItemInterface[],
    itemFns?: ItemActionInterface[],
    onClickItemFn?: (item: ItemInterface) => void
    onAddNewItemFn?: () => void,
    onAddNewItemLabel?: string;
};

const AssignedList = ({ title, items, itemFns, onClickItemFn = () => {}, onAddNewItemFn = () => {}, onAddNewItemLabel }: Props) => {

    return (
        <div className='userAssignedList'>
            <span className='title'>{ title }</span>
            <div className='box'>
                <span className='title'>{ title }</span>
                <CustomList
                    items={ items }
                    onClickItemFn={ (item: ItemInterface) => onClickItemFn(item) }
                    itemFns={ itemFns }
                />
                { !!!onAddNewItemLabel ? '' :
                    <div className='addButton'>
                        <OButton circular $primary icon $color={ 'var(--brand-secondary)' }><Icon name='plus' /></OButton>
                        <OButton className='textButton' $primary $color={ 'var(--brand-secondary)' } onClick={ onAddNewItemFn } ><span>{ onAddNewItemLabel }</span></OButton>
                    </div>
                }
            </div>
        </div>
    );

}

export default AssignedList;