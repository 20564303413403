import { Link, LinkProps } from 'react-router-dom';
import styled, {css} from 'styled-components/macro';

import { device } from '../../../styled/mbreakpoints';

type Props = {
    $active?: boolean;
    $justify?: string;
    $activeLink?: boolean;
};

const Nav = styled.nav<Props>`
    background-color: white;
    padding: 1.2rem 1.5rem;
    position: sticky;
    height: 55px;
    top: 0px;
    z-index: 10;
    border-bottom: 1px solid var(--brand-primary-extra-light);

    ${ ({ $active }) => $active && css`
        @media (max-width: 767px) {
            padding: 1.42rem 1.5rem;
        }
    `};
`;

const NavContent = styled.div<Props>`
    max-width: var(--max-width-container);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: ${ ({ $justify }) => $justify ? $justify : 'space-between' };
    align-items: center;
    padding-left: 110px;
`;

const Logo = styled.div`
    position: absolute;
    width: 200px;
    top: 15px;
`;

const NavList = styled.ul`
    display: none;
    padding: 0;
    list-style: none;
    text-align: center;
    align-items: center;

    @media ${ device.tablet } {
        display: flex;
    }
`;

const ListItem = styled.li`
    position: relative;

    & + & {
        margin-left: 2rem;
    }
`;

const NavLink = styled(Link)<Props & LinkProps>`
    color: var(--brand-primary);
    font-size: 1rem;
    letter-spacing: 1.5px;
    text-decoration: none;
    font-family: PoppinsRegular;
    transition: color .3s;
    font-weight: 400;

    i {
        padding-right: 20px;
    }

    ${ ({ $activeLink }) => $activeLink && css`
        color: var(--brand-secondary);
        border-bottom: 3px solid var(--brand-secondary);
        padding-bottom: 16px;
    `};

    &:hover, &:focus {
        color: var(--brand-secondary);
    }

    &.submenu {
        color: var(--brand-primary);
        ${ ({ $activeLink }) => $activeLink && css`
            color: var(--brand-secondary);
        `};

        &:hover, &:focus {
            color: var(--brand-secondary);
        }
    }
`;

const IconGroup = styled.div`
    position: absolute;
    width: 85px;
    top: 18px;
    right: 0;
`;

const IconLink = styled(Link)<Props & LinkProps>`
    position: relative;
    margin-left: 10px;

    &: first-child {
        margin-left: 0;
    }

    i {
        font-size: 20px;
        height: auto;

        &:before {
            transition: color .3s;
            color: var(--brand-primary);
        }
    }

    ${({ $activeLink }) => $activeLink && css`
        i {
            &:before {
                color: var(--brand-secondary);
                border-bottom: 3px solid var(--brand-secondary);
                padding-bottom: 16px;
            }
        }

        &:hover, &:focus {
            i {
                &:before {
                    color: var(--brand-secondary);
                }
            }
        }
    `};
`;

const SubMenu = styled.div`
    .mobileSubmenu {
        display: block;
        margin-bottom: 20px;

        @media ${ device.tablet } {
            display: none;
        }
    }

    .desktopSubmenu {
        display: none;
        margin-bottom: 20px;

        @media ${ device.tablet } {
            display: block;
        }

        .multipleSelectionBar {
            margin-top: 1.5em;
            height: 52px;
            border-bottom: 1px solid var(--border-color);
            border-top: 1px solid var(--border-color);

            .ui.checkbox {
                padding: 1.15em 0.5em;
            }

            div.actions {
                display: inline;
                margin-left: 25px;

                i {
                    cursor: pointer;
                    font-size: 1.25em;
                    margin-right: 15px;
                }
            }
        }

        &.secondary {
            max-width: 700px;
            margin: auto;
        }
    }

    span.title {
        font-size: 2rem;
        font-weight: 700;
        line-height: 38.73px;
    }

    div.filterComponent {
        width: 100%;

        .ui.icon.input {
            width: 100%;
        }

        @media ${ device.tablet } {
            width: 255px;
        }
    }
`;

const UploadSubscriptionBanner = styled.div`
    background-color: var(--brand-primary-extra-light-secondary);
    padding: 1.2rem 1.5rem;
    top: 0px;
    z-index: 10;
    text-align: center;
    color: var(--dark-grey-2);

    span {
        line-height: 1.5em;
    }

    button.ui.button {
        margin-left: 20px;
        margin-top: 5px;
    }
`;

export {
    Nav,
    NavContent,
    NavList,
    ListItem,
    NavLink,
    IconGroup,
    IconLink,
    SubMenu,
    Logo,
    UploadSubscriptionBanner
};