// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface } from '../../../../../models/student';

// Components
import { FormSelectOptionsInterface } from '../../../../components/form/select';
import { ClosedModal, GenericModalProps } from '../../../../components/genericModal';

// Styled components
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

type Props = {
    groupId: string;
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
    students: StudentInterface[],
};

const AddToGroup = ({ groupId, onChangeModal, onForceUpdate, students }: Props) => {

    const dispatch = useDispatch();
    const [studentId, setStudentId] = useState<string>('');

    const [studentToAssignSelectOptions, setStudentToAssignSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const data: FormSelectOptionsInterface[] = [];
        _.each(students, student => {

            data.push({
                key: student._id,
                text: student.name + ' ' + student.surname,
                value: student._id
            });

        });

        setStudentToAssignSelectOptions(data);

    }, [groupId, students])

    const handleAddStudentSubmit = async () => {

        try {

            closeModal();
            dispatch(openLoader());

            await StudentModel.assignToGroup('' + studentId, groupId);

            setStudentId('');
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <Form onSubmit={ handleAddStudentSubmit }>
            <Form.Field>
                <span style={{ paddingBottom: '10px', display: 'block' }}>
                    <strong>{ I18n.t('groups.chooseStudent') }</strong>
                </span>
                { _.isEmpty(studentToAssignSelectOptions) ?
                    <span style={{ paddingBottom: '10px', display: 'block', color: 'red' }}>
                        <strong>{ I18n.t('groups.emptyStudentList') }</strong>
                    </span>
                    :
                    <Dropdown
                        search={ true }
                        options={ studentToAssignSelectOptions }
                        value={ studentId }
                        selection
                        fluid
                        onChange={ (e, { value }) => setStudentId('' + value) }
                        onBlur={ (e, { value }) => setStudentId('' + value) }
                        style={{ marginBottom: '10px' }}
                    />
                }
                <div>
                    <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(studentId) }>
                        <span>{ I18n.t('groups.addStudent') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );

}

export default AddToGroup;