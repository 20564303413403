// Third party libraries
import _ from 'lodash';
import { useState } from 'react';
import { Container, Image } from 'semantic-ui-react';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

// Styled components
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Assets
import YogabotLogo from '../../../../assets/img/YogabotPro.png';

const LandingTopBar = () => {

    const navigate = useNavigate();

    const [className, setClassName] = useState<string>('');

    const scrollFunction = (scrollTop: number, max: number) => scrollTop > max && className !== 'fixed' ? setClassName('fixed') : scrollTop <= max && className === 'fixed' && setClassName('');

    const throttledScrollFunction = _.throttle(() => scrollFunction(document.documentElement.scrollTop, 110), 250, { leading: true, trailing: true });

    window.onscroll = () => throttledScrollFunction();

    return (
        <div className='topMenuContainer'>
            <Container>
                <div className={ `topMenu ${ className }` }>
                    <div className='logoBox'>
                        <Image src={ YogabotLogo } className='logo' />
                    </div>
                    <div className='options'>
                        {/* <div className='link'><HashLink to=''>{ I18n.t('landing.footer.pedagogy') }</HashLink></div> */}
                        <div className='link'><HashLink to='/#functionalities'>{ I18n.t('landing.footer.functionalities') }</HashLink></div>
                        <div className='link'><HashLink to='/#rates'>{ I18n.t('landing.footer.rates') }</HashLink></div>
                    </div>
                    <div className='buttons'>
                        <OButton $primary type='button' $color='#1fd5b9' onClick={ () => navigate('/auth/login') }>
                            <span>{ I18n.t('landing.login') }</span>
                        </OButton>
                        <OButton $terciary type='button' $color='#fff' onClick={ () => navigate('/auth/register') }>
                            <span>{ I18n.t('landing.checkFree') }</span>
                        </OButton>
                    </div>
                </div>
            </Container>
        </div>
    );

}

export default LandingTopBar;