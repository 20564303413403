// Third party libraries
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';

// Components
import BottomNav from '../../../../components/bottomNav/BottomNav';
import HorizontalPlanCard from './components/horizontalPlanCard';
import PanelNav from '../../../../components/nav/PanelNav';
import SetupNav from '../../components/SetupNav';

// Styled components
import { Wrapper } from '../../../../styled/wrappers';

// Models
import PaymentModel, { PeriodicityEnum, PlansEnum } from '../../../../../models/payment';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

const Plans = () => {

    const navigate = useNavigate();

    const [periodicity, setPeriodicity] = useState<PeriodicityEnum>(PeriodicityEnum.Annual);
    const [plan] = useState<PlansEnum | undefined>();
    const [maxStudents, setMaxStudents] = useState<number>(3);
    const [expiredLicense, setExpiredLicense] = useState<boolean>(window.location.hash.includes('/expiredLicense/'));

    useEffect(() => {

        PaymentModel.getCurrentLicense().then(currentLicense => {

            const isExpiredLicense: boolean = !!!currentLicense.endDate || Moment(currentLicense.endDate).startOf('day').isBefore(Moment().startOf('day'));
            if (!isExpiredLicense) {

                setMaxStudents(currentLicense.maxStudents);

            } else if (!window.location.hash.includes('/expiredLicense/')) {

                navigate('/expiredLicense/plans');
                setExpiredLicense(true);

            }

        });

    }, []);

    const onSelectPlan = (planSelected: PlansEnum) => navigate(`/${ expiredLicense ? 'expiredLicense' : 'setup' }/checkout?periodicity=${ periodicity }&plan=${ planSelected }`);

    //TODO: Ampliar suscripción -- Calcular los plazos para los planes superiores al actual (regla de 3, si sobra tanto dinero, a cuánto equivaldría en el plan nuevo --> ese número de días irá a Stripe como días de trial)

    const renderExpiredLicenseMessage = () => <div className='expiredLicenseText'>
        <h2>{ I18n.t('setup.expiredLicenseTexts.title') }</h2>
        <br />
        <span>{ I18n.t('setup.expiredLicenseTexts.description') }</span>
        <br />
        <br />
        <ul>
            <li>{ I18n.t('setup.expiredLicenseTexts.reason1') }</li>
            <li>{ I18n.t('setup.expiredLicenseTexts.reason2') }</li>
            <li>{ I18n.t('setup.expiredLicenseTexts.reason3') }</li>
        </ul>
        <br />
        <span>{ I18n.t('setup.expiredLicenseTexts.whatCanYouDo') }</span>
        <br />
        <br />
        <span dangerouslySetInnerHTML={{ __html:I18n.t('setup.expiredLicenseTexts.contractOrContact') }} />
    </div>;

    return <Wrapper>
        <PanelNav active='setup' disableMenu={ expiredLicense } />
        <SetupNav active='subscription' disableMenu={ expiredLicense } />
        <Container style={{ paddingTop: '20px' }}>
            <div className='inner'>
                <div className='p-flex'>
                    <div className='p-content'>
                        <div className='a-wrapper'>
                            <div className='a-f-wrapper'>
                                <div className='plans'>
                                    { expiredLicense && renderExpiredLicenseMessage() }
                                    <Segment>
                                        <div className='periodicitySelector'>
                                            <span className={ `selector ${ periodicity === PeriodicityEnum.Monthly ? 'active' : '' }` } onClick={ () => setPeriodicity(PeriodicityEnum.Monthly) }>{ I18n.t('landing.monthly') }</span>
                                            <span className={ `selector ${ periodicity === PeriodicityEnum.Annual ? 'active' : '' }` } onClick={ () => setPeriodicity(PeriodicityEnum.Annual) }>{ I18n.t('landing.annual') }</span>
                                        </div>
                                        <HorizontalPlanCard maxStudents={ maxStudents } plan={ PlansEnum.Starter } periodicity={ periodicity } selected={ plan === PlansEnum.Starter } onSelect={ () => onSelectPlan(PlansEnum.Starter) } />
                                        <HorizontalPlanCard maxStudents={ maxStudents } plan={ PlansEnum.Standard } periodicity={ periodicity } selected={ plan === PlansEnum.Standard } onSelect={ () => onSelectPlan(PlansEnum.Standard) } />
                                        <HorizontalPlanCard maxStudents={ maxStudents } plan={ PlansEnum.Advanced } periodicity={ periodicity } selected={ plan === PlansEnum.Advanced } onSelect={ () => onSelectPlan(PlansEnum.Advanced) } />
                                        <HorizontalPlanCard maxStudents={ maxStudents } plan={ PlansEnum.Professional } periodicity={ periodicity } selected={ plan === PlansEnum.Professional } onSelect={ () => onSelectPlan(PlansEnum.Professional) } />
                                    </Segment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
        <BottomNav active='setup' disableMenu={ expiredLicense } />
    </Wrapper>;

}

export default Plans;