// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { autofill, Field, InjectedFormProps, initialize, reduxForm } from 'redux-form';
import { Container, Form, Icon, Radio, Segment } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openToast } from '../../../../redux-store/toast';

// Models
import FilesModel from '../../../../models/files';
import SequenceModel, { NewSequenceInterface, SequenceInterface } from '../../../../models/sequence';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import CustomInput from '../../../components/form/input';
import CustomSelect from '../../../components/form/select';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../components/genericModal';
import MultimediaManage, { MultimediaTypes } from '../../../components/multimediaManage';
import PanelNav from '../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../components/nav/SecondarySubmenu';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Utils
import HooksService from '../../../../utils/HooksService';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    reduxFormState: any;
};

interface SequenceEditProps extends MapDispatchProps {
    handleSubmit?: any;
    invalid?: boolean;
    navigate?: any;
    dispatch?: any;
    router?: any;
};

interface FormInterface {
    description: string;
    level: string;
    title: string;
    categories: string[];
    thumbUrl?: string;
    videoUrl?: string;
};

interface SelectInterface {
    key: string;
    text: string;
    value: string;
};

interface SequenceEditState {
    sequenceLevels: SelectInterface[],
    sequenceCategories: SelectInterface[],
    breadcrumbs: BreadcrumbsInterface[];
    sequenceId?: string;
    sequence?: SequenceInterface;
    assignedStudentsCount: number;
    assignedGroupsCount: number;
    modal: GenericModalProps;
};

class SequenceEdit extends React.Component<SequenceEditProps & InjectedFormProps<{}, SequenceEditProps>, SequenceEditState> {

    constructor(props: SequenceEditProps & InjectedFormProps<{}, SequenceEditProps>) {

        super(props);

        this.state = {
            sequenceLevels: [{
                key: '00',
                text: I18n.t('sequences.levels.00'),
                value: '00'
            }, {
                key: '01',
                text: I18n.t('sequences.levels.01'),
                value: '01'
            }, {
                key: '02',
                text: I18n.t('sequences.levels.02'),
                value: '02'
            }, {
                key: '03',
                text: I18n.t('sequences.levels.03'),
                value: '03'
            }, {
                key: '04',
                text: I18n.t('sequences.levels.04'),
                value: '04'
            }],
            sequenceCategories: [],
            sequenceId: this.props.router.params.sequenceId,
            sequence: undefined,
            breadcrumbs: [],
            assignedStudentsCount: 0,
            assignedGroupsCount: 0,
            modal: ClosedModal
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader, dispatch } = this.props;
        let initialValues: FormInterface = {
            description: '',
            level: '00',
            title: '',
            categories: []
        };

        openLoader();

        const { sequenceId } = this.state;

        const sequenceCategoriesList = await SequenceModel.getSequenceCategories();
        const sequenceCategories: SelectInterface[] = [];
        _.each(sequenceCategoriesList, category => sequenceCategories.push({ key: category._id, text: category.label, value: category._id }));

        if (!_.isEmpty(sequenceId)) {

            const sequence = await SequenceModel.getSequence('' + sequenceId);
            initialValues = {
                description: sequence.description.ES,
                level: sequence.level,
                title: sequence.title.ES,
                categories: sequence.categories,
                thumbUrl: sequence.thumbUrl,
                videoUrl: sequence.videoUrl
            };

            const breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.sequences'),
                goTo: '/sequences'
            },
            {
                key: 1,
                label: initialValues?.title,
                goTo: ''
            }];

            const assignedStudentsCount = _.size(await SequenceModel.getSequenceStudents('' + sequenceId));

            const assignedGroupsCount = _.size(await SequenceModel.getSequenceGroups('' + sequenceId));

            this.setState({ assignedGroupsCount, assignedStudentsCount, breadcrumbs, sequence, sequenceCategories });

        } else {

            const breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.sequences'),
                goTo: '/sequences'
            },
            {
                key: 1,
                label: I18n.t('sequences.newSequence'),
                goTo: ''
            }];

            this.setState({ breadcrumbs, sequenceCategories });

        }

        dispatch(initialize(
            'sequenceForm',
            initialValues
        ));

        closeLoader();

    };

    onSubmit = async (values: FormInterface) => {

        const { openLoader, closeLoader } = this.props;
        const { sequenceId } = this.state;

        openLoader();

        const tempData = _.cloneDeep(values);
        let hasError = false;

        try {

            let sequence = {
                title: {
                    ES: tempData.title
                },
                description: {
                    ES: tempData.description
                },
                level: tempData.level,
                categories: tempData.categories,
                thumbUrl: tempData.thumbUrl,
                videoUrl: tempData.videoUrl
            };

            if (sequenceId) {

                const tempImageUrlFile = _.get(window, 'thumbUrl.tempFile', {});
                if (!_.isEmpty(tempImageUrlFile)) {

                    const formData = new FormData();
                    formData.append('fileData', tempImageUrlFile.ref);
                    formData.append('path', 'sequence/' + sequenceId);

                    try {

                        const response = await FilesModel.uploadFile(formData, 'image');

                        if (_.get(response,'data.url', false)) {

                            sequence.thumbUrl = response.data.url;

                        }

                    } catch (error) {

                        delete sequence.thumbUrl;

                    }

                }

                const tempVideoUrlFile = _.get(window, 'videoUrl.tempFile', {});
                if (!_.isEmpty(tempVideoUrlFile)) {

                    const formData = new FormData();
                    formData.append('fileData', tempVideoUrlFile.ref);
                    formData.append('path', 'sequence/' + sequenceId);

                    try {

                        const response = await FilesModel.uploadFile(formData, 'video');

                        if (_.get(response, 'data.url', false)) {

                            sequence.videoUrl = response.data.url;

                        }

                    } catch (error) {

                        delete sequence.videoUrl;

                    }

                }

                const sequenceToUpdate: SequenceInterface = _.merge(_.omit(this.state.sequence, ['title', 'description', 'level', 'categories', 'thumbUrl', 'videoUrl']), sequence);
                await SequenceModel.updateSequence(sequenceId, sequenceToUpdate);

            } else {

                await SequenceModel.createSequence(sequence);

            }
            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });
            hasError = true;

        } finally {

            !hasError && _.set(window, 'thumbUrl', undefined);
            !hasError && _.set(window, 'videoUrl', undefined);
            closeLoader();

        }

    }

    goBack = () => this.props.navigate('/sequences');

    isReadyToPublish = (): boolean => {

        const { sequence } = this.state;

        return !_.isEmpty(sequence?.title) &&
            !_.isEmpty(sequence?.description) &&
            !_.isEmpty(sequence?.level) &&
            !_.isEmpty(sequence?.routine);

    };

    onPublish = async () => {

        const { sequence } = this.state;
        const { openLoader, closeLoader } = this.props;

        if (!sequence?.published && !this.isReadyToPublish()) {

            return;

        }

        openLoader();
        try {

            await SequenceModel.publishSequence('' + sequence?._id);
            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });

        } finally {

            closeLoader();

        }

    };

    onPreview = () => {}

    onRemove = () => {

        this.setState({ modal: {
            open: true,
            title: I18n.t('buttons.actions.confirm'),
            renderBody: () => <span>{ I18n.t('sequences.removeSequenceConfirmMessage') }</span>,
            renderActions: () => <>
                <OButton type='button' $color='black' $primary onClick={ this.closeModal }>
                    <span>{ I18n.t('buttons.actions.cancel') }</span>
                </OButton>
                <OButton type='button' $color='white' $terciary onClick={ this.remove }>
                    <span>{ I18n.t('buttons.actions.ok') }</span>
                </OButton>
            </>
        }});

    };

    remove = async () => {

        const { sequence } = this.state;
        const { openLoader, closeLoader } = this.props;

        this.closeModal();

        openLoader();
        try {

            await SequenceModel.removeSequence('' + sequence?._id);
            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });

        } finally {

            closeLoader();

        }

    };

    closeModal = () => this.setState({ modal: ClosedModal });

    onRemoveFile = (path: string) => this.props.dispatch(autofill('sequenceForm', path, ''));

    render() {

        const { reduxFormState } = this.props;
        const { assignedGroupsCount, assignedStudentsCount, breadcrumbs, modal, sequence, sequenceId, sequenceLevels, sequenceCategories } = this.state;

        return (
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='sequences' />
                <Container style={{ paddingTop: '20px' }}>
                    <SecondarySubmenu
                        title={ I18n.t('sequences.sequenceInfo') }
                        mainFn={{ callback: this.goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                        breadcrumbs={ breadcrumbs }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <div className='sequenceEdit'>
                                            <Segment>
                                                <Radio toggle disabled={ !this.isReadyToPublish() && !sequence?.published }  onClick={ this.onPublish } checked={ sequence?.published } />
                                                <span className='inline title'>{ I18n.t('sequences.status') }</span>
                                                <span className={ `inline status ${ sequence?.published ? 'published' : 'draft' }` }>{ I18n.t(`sequences.${ sequence?.published ? 'published' : 'draft' }`) }</span>
                                                <span className='inline statusDescription'>{ sequence?.published ? '' : I18n.t('sequences.draftText') }</span>
                                            </Segment>
                                            { _.isEmpty(sequenceId) ? <></> :
                                                <>
                                                    <div className='pictureBox'>
                                                        <label className='primary-font regular'>{ I18n.t('sequences.picture') }</label>
                                                        <MultimediaManage formFieldName='thumbUrl' type={ MultimediaTypes.IMAGE } src={ reduxFormState.thumbUrl } onRemove={ this.onRemoveFile } />
                                                    </div>
                                                    <div className='videoBox'>
                                                        <label className='primary-font regular'>{ I18n.t('sequences.video') }</label>
                                                        <MultimediaManage formFieldName='videoUrl' type={ MultimediaTypes.VIDEO } src={ reduxFormState.videoUrl } onRemove={ this.onRemoveFile } />
                                                    </div>
                                                </>
                                            }
                                            <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name='sequenceForm'>
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('sequences.title') }
                                                    name='title'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('sequences.title') }
                                                />
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('sequences.description') }
                                                    name='description'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('sequences.description') }
                                                />
                                                <Field
                                                    component={ CustomSelect }
                                                    placeholder={ I18n.t('sequences.level') }
                                                    name='level'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('sequences.level') }
                                                    options={ sequenceLevels }
                                                />
                                                <Field
                                                    component={ CustomSelect }
                                                    placeholder={ I18n.t('sequences.categories') }
                                                    name='categories'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('sequences.categories') }
                                                    options={ sequenceCategories }
                                                    multiple={ true }
                                                />
                                                <OButton $upper fluid type='submit' $color='#FFF' $terciary disabled={ this.props.invalid }>
                                                    <span>{ I18n.t('buttons.actions.save') }</span>
                                                </OButton>
                                            </Form>
                                            { _.isEmpty(sequence) ? '' :
                                                <div className='subsections'>
                                                    <div className='subsection' onClick={ () => this.props.navigate(`/sequences/edit/${ sequenceId }/asanas`) }>
                                                        <span className='title'>{ I18n.t('sequences.routine') }</span>
                                                        <div className='counter'>
                                                            <span>{ 'x ' + _.size(sequence?.routine) }</span>
                                                            <Icon name='angle right' />
                                                        </div>
                                                    </div>
                                                    <div className='subsection' onClick={ () => this.props.navigate(`/sequences/edit/${ sequenceId }/students`) }>
                                                        <span className='title'>{ I18n.t('sequences.assignedStudents') }</span>
                                                        <div className='counter'>
                                                            <span>{ 'x ' + assignedStudentsCount }</span>
                                                            <Icon name='angle right' />
                                                        </div>
                                                    </div>
                                                    <div className='subsection' onClick={ () => this.props.navigate(`/sequences/edit/${ sequenceId }/groups`) }>
                                                        <span className='title'>{ I18n.t('sequences.assignedGroups') }</span>
                                                        <div className='counter'>
                                                            <span>{ 'x ' + assignedGroupsCount }</span>
                                                            <Icon name='angle right' />
                                                        </div>
                                                    </div>
                                                    <div className='subsection' onClick={ () => this.props.navigate(`/sequences/edit/${ sequenceId }/objectives`) }>
                                                        <span className='title'>{ I18n.t('sequences.objectives') }</span>
                                                        <div className='counter'>
                                                            <span>{ 'x ' + _.size(sequence?.targets) }</span>
                                                            <Icon name='angle right' />
                                                        </div>
                                                    </div>
                                                    <div className='subsection' style={{ marginTop: '55px' }} onClick={ this.onPreview }>
                                                        <span className={ `title ${ /* _.isEmpty(sequence?.routine) //TODO: Implementar */ true ? 'disable' : '' }`}>{ I18n.t('sequences.preview') }</span>
                                                    </div>
                                                    <div className='subsection' onClick={ this.onRemove }>
                                                        <span className='title red
                                                        '>{ I18n.t('buttons.actions.remove') }</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='sequences' />
            </Wrapper>
        );

    }

}

const validate = (formValues: NewSequenceInterface) => {

    const errors = {
        title: undefined,
        description: undefined
    };

    if (_.isEmpty(formValues?.title)) {

        errors.title = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues?.description)) {

        errors.description = I18n.t('validations.required');

    }

    return errors;

};

const form = reduxForm<{}, SequenceEditProps>({
    form: 'sequenceForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(SequenceEdit);

export default HooksService.withRouter(connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.sequenceForm.values', {}))

    return { reduxFormState };

}, { openLoader, closeLoader })(form));