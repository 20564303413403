import { createHashHistory, HashHistory } from 'history';

const history: HashHistory = createHashHistory();

history.listen(() => {

    window.scrollTo(0, 0);

});

export default history;