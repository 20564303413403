import { toast } from 'react-toastify';

interface ParamsInterface {
    message: string,
    type: string
};

// Actions
export const openToast = (params: ParamsInterface) => {

    //toast[params.type](params.message, { className: `r-toast-${ params.type }` });
    toast(params.message, { className: `r-toast-${ params.type }` });

};