// Third party libraries
import _ from 'lodash';
import { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Components
import HorizontalPlanCard from '../../../setup/checkout/plans/components/horizontalPlanCard';

// Styled components
import OButton from '../../../../styled/button';

// Models
import { PeriodicityEnum, PlansEnum } from '../../../../../models/payment';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './index.scss';

// Assets
import FlexibilityYourBusiness from '../../../../assets/img/landing/flexibilityYourBusiness.png'
import GrowthUpValue from '../../../../assets/img/landing/growthUpValue.png'
import IncreaseIncome from '../../../../assets/img/landing/increaseIncome.png'
import RegisterBackground from '../../../../assets/img/registerBackground.png';
import YogabotLogo from '../../../../assets/img/YogabotPro.png';

interface RegisterCheckoutProps {

};

const RegisterCheckout = ({}: RegisterCheckoutProps) => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [periodicity, setPeriodicity] = useState<PeriodicityEnum | null>(searchParams.get('periodicity') as PeriodicityEnum);
    const [plan] = useState<PlansEnum | null>(searchParams.get('plan') as PlansEnum);

    return <div className='registerCheckout'>
        <Image src={ RegisterBackground } className='background' />
        <div className='registerTab'>
            <div className='registerSelectPlan'>
                <div className='header'>
                    <Image src={ YogabotLogo } className='logo' />
                    <span className='stepText'>{ I18n.t('register.stepText', { currentStep: 2, totalSteps: 2 }) }</span>
                    <span className='title'>{ I18n.t('register.selectYourPlan') }</span>
                </div>
                <div className='periodicitySelector'>
                    <span className={ `selector ${ periodicity === PeriodicityEnum.Monthly ? 'active' : '' }` } onClick={ () => setPeriodicity(PeriodicityEnum.Monthly) }>{ I18n.t('landing.monthly') }</span>
                    <span className={ `selector ${ periodicity !== PeriodicityEnum.Monthly ? 'active' : '' }` } onClick={ () => setPeriodicity(PeriodicityEnum.Annual) }>{ I18n.t('landing.annual') }</span>
                </div>
                <HorizontalPlanCard plan={ PlansEnum.Starter } periodicity={ periodicity || PeriodicityEnum.Annual } selected={ plan === PlansEnum.Starter } onSelect={ () => navigate(`/setup/checkout?periodicity=${ periodicity }&plan=${ PlansEnum.Starter }`) } />
                <HorizontalPlanCard plan={ PlansEnum.Standard } periodicity={ periodicity || PeriodicityEnum.Annual } selected={ plan === PlansEnum.Standard } onSelect={ () => navigate(`/setup/checkout?periodicity=${ periodicity }&plan=${ PlansEnum.Standard }`) } />
                <HorizontalPlanCard plan={ PlansEnum.Advanced } periodicity={ periodicity || PeriodicityEnum.Annual } selected={ plan === PlansEnum.Advanced } onSelect={ () => navigate(`/setup/checkout?periodicity=${ periodicity }&plan=${ PlansEnum.Advanced }`) } />
                <HorizontalPlanCard plan={ PlansEnum.Professional } periodicity={ periodicity || PeriodicityEnum.Annual } selected={ plan === PlansEnum.Professional } onSelect={ () => navigate(`/setup/checkout?periodicity=${ periodicity }&plan=${ PlansEnum.Professional }`) } />
            </div>
        </div>
        <div className='commercialTips'>
            <div className='benefit'>
                <Image src={ FlexibilityYourBusiness } />
                <div className='benefitText'>
                    <span className='title'>{ I18n.t('landing.flexibilityYourBusiness') }</span>
                    <br />
                    <span className='text'>{ I18n.t('landing.flexibilityYourBusinessText') }</span>
                </div>
            </div>
            <div className='benefit'>
                <Image src={ GrowthUpValue } />
                <div className='benefitText'>
                    <span className='title'>{ I18n.t('landing.growthUpValue') }</span>
                    <br />
                    <span className='text'>{ I18n.t('landing.growthUpValueText') }</span>
                </div>
            </div>
            <div className='benefit'>
                <Image src={ IncreaseIncome } />
                <div className='benefitText'>
                    <span className='title'>{ I18n.t('landing.increaseYourIncome') }</span>
                    <br />
                    <span className='text'>{ I18n.t('landing.increaseYourIncomeText') }</span>
                </div>
            </div>
        </div>
    </div>;

}

export default RegisterCheckout;