// Third party libraries
import { useDispatch } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface } from '../../../../../../models/student';

// Components
import { ClosedModal, GenericModalProps } from '../../../../../components/genericModal';

// Styled components
import OButton from '../../../../../styled/button';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

type Props = {
    student?: StudentInterface,
    onChangeModal: (modal: GenericModalProps) => void,
    onGetData: () => void
};

const TrackingOptions = ({ onChangeModal, onGetData, student }: Props) => {

    const dispatch = useDispatch();

    const onChangeCustomTrackingValue = async (checked: boolean = false) => {

        try {

            dispatch(openLoader());
            await StudentModel.updateCustomTracking('' + student?._id, checked);
            await onGetData();

        } catch (e) {

            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        } finally {

            dispatch(closeLoader());

        }

    };

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <div className='userTrackingOptions'>
            <span className='title'>{ I18n.t('students.customTrackingOptions') }</span>
            <Checkbox
                toggle
                label={ I18n.t('students.customTracking') }
                checked={ student?.customTracking }
                onChange={ (e, { checked }) => onChangeCustomTrackingValue(checked) }
            />
            <span className='description'>{ I18n.t('students.customTrackingDescription') }</span>
        </div>
    );

};

export default TrackingOptions;