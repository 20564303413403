// Third party libraries
import { useNavigate } from 'react-router-dom';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Assets
import NotFoundImage from '../../assets/img/page404Background.png';

const NotFoundPage = () => {

    const navigate = useNavigate();

    const isLogged = () => !!window.localStorage.getItem('jwt');

    return <div className='wrap'>
        <div className='wrap-grid'>
            <div className='left-content'>
                <div className='first-title'><p>{ I18n.t('404.title') }</p></div>
                <div className='first-description'><p>{ I18n.t('404.description') }</p></div>
                <div className='second-title'><p>{ I18n.t('404.question') }</p></div>
                <div className='wrap-list'>
                    { isLogged() && <div className='list-item' onClick={ () => navigate('/students') } dangerouslySetInnerHTML={{ __html: I18n.t('404.firstItemIsLogged') }} ></div> }
                    { !isLogged() && <div className='list-item' onClick={ () => navigate('/auth/login') } dangerouslySetInnerHTML={{ __html: I18n.t('404.firstItem') }}></div> }
                    { !isLogged() && <div className='list-item' onClick={ () => navigate('/auth/register') } dangerouslySetInnerHTML={{ __html: I18n.t('404.secondItem') }} ></div> }
                </div>
            </div>
            <div className='right-content'>
                <img src={ NotFoundImage } alt='' />
            </div>
        </div>
    </div>;

};

export default NotFoundPage