import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --brand-primary: black;
        --brand-primary-light: #98a3af;
        --brand-primary-extra-light: #ccd1d7;
        --brand-primary-extra-light-secondary: #F2F2F2;
        --brand-secondary: #1fd5b9;
        --brand-secondary-rgb: 31, 213, 185;
        --brand-secondary-dark: #1bb89f;

        --brand-black: #000000;
        --brand-white: #ffffff;
        --dark-grey: #E5E5E5;
        --dark-grey-2: #686c71;
        --dark-grey-3: #84898f;
        --dark-grey-4: #eaecef;
        --light-grey-1: #F1F4F4;
        --light-grey-2: #959090;

        --red-color: #FF6767;
        --brand-error: #e20d0d;
        --red-color-secondary: #EB5757;
        --brand-positive: #2dcc70;
        --brand-alarm: #ffcf00;
        --blue-link: #3598d4;

        --border-color: #cbcbcb;
        --border-google-color: #4285F4;
        --border-facebook-color: #143482;
        --font-primary: PoppinsBold;
        --font-secondary: PoppinsRegular;

        --max-width-container: 1440px;

        --margin-regular-x: 20px;
        --padding-regular-x: 20px;
        --padding-large-x: 32px;
        --padding-large-y: 32px;
        --padding-regular-y: 20px;

        @media screen and (min-width: 425px) {
            --margin-regular-x: 20px;
            --padding-regular-x: 32px;
            --padding-large-x: 64px;
            --padding-regular-y: 32px;
            --padding-large-y: 64px;
        }

        --floating-top-separation: 120px;
        --overflow-mobile-img: 70px;

        --border-radius-default : 18px;
    }
`;

export default GlobalStyle;