// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';

// Assets
import loaderImage from '../../assets/img/loader.svg';

interface ILoaderProps { open: boolean };
interface ILoaderState { };

class Loader extends React.Component<ILoaderProps, ILoaderState> {

    render() {

        return this.props.open ? (
            <Dimmer active style={{ position: 'fixed' }}>
                <img src={ loaderImage } alt='loader' />
            </Dimmer>
        ) : '';

    }

}

const mapStateToProps = (state: any) => _.cloneDeep(state.loader);

export default connect(mapStateToProps)(Loader);