// Third party libraries
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

// Components
import Loader from './components/loader';
import KeepAlive from './components/keepAlive';

// Routes
import CustomRoute from './routes';

// Locales
import I18n from '../i18n';

// Global styles
import GlobalStyle from './styled/global';

const App = () => {

    return (
        <I18nextProvider i18n={ I18n }>
            <HashRouter>
                <CustomRoute />
            </HashRouter>
            <GlobalStyle />
            <Loader open={ false } />
            <KeepAlive />
        </I18nextProvider>
    );

}

export default App;