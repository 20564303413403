// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { autofill, Field, InjectedFormProps, initialize, reduxForm } from 'redux-form';
import { Container, Form, Segment } from 'semantic-ui-react';
import ISO6391 from 'iso-639-1';

// Redux
import { updateProfile } from '../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openToast } from '../../../../redux-store/toast';

// Models
import FilesModel from '../../../../models/files';
import SecurityModel, { ProfileInterface } from '../../../../models/security';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import CustomInput from '../../../components/form/input';
import CustomSelect, { FormSelectOptionsInterface } from '../../../components/form/select';
import CustomTextArea from '../../../components/form/textarea';
import MultimediaManage, { MultimediaTypes } from '../../../components/multimediaManage';
import PanelNav from '../../../components/nav/PanelNav';
import SetupNav from '../components/SetupNav';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Constants
import Countries from './countries';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface FormProfileInterface extends ProfileInterface {
    instagram?: string;
    facebook?: string;
    web?: string;
    genderSelected?: string;
};

interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    reduxFormState: any;
    updateProfile: any;
};

interface ProfileProps extends MapDispatchProps {
    handleSubmit?: any;
    invalid?: boolean;
    navigate?: any;
    dispatch?: any;
};

interface ProfileState {
    countrySelectOptions: FormSelectOptionsInterface[];
    genderSelectOptions: FormSelectOptionsInterface[];
    languageSelectOptions: FormSelectOptionsInterface[];
    profile: ProfileInterface;
};

class ProfileData extends React.Component<ProfileProps & InjectedFormProps<{}, ProfileProps>, ProfileState> {

    constructor(props: ProfileProps & InjectedFormProps<{}, ProfileProps>) {

        super(props);

        this.state = {
            countrySelectOptions: [],
            genderSelectOptions: [{
                key: '1',
                text: I18n.t('setup.genders.1'),
                value: '1'
            }, {
                key: '0',
                text: I18n.t('setup.genders.0'),
                value: '0'
            }],
            languageSelectOptions: [],
            profile: { email: '', _id: '' }
        };

        _.each(Countries, (country, key) => this.state.countrySelectOptions.push({ key, text: country, value: key }));
        _.each(ISO6391.getLanguages(ISO6391.getAllCodes()), language => this.state.languageSelectOptions.push({ key: language.code, text: language.nativeName, value: language.code }));

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { closeLoader, dispatch, openLoader } = this.props;

        openLoader();

        try {

            const profile = await SecurityModel.getProfile();
            const initialValues = _.merge(
                _.pick(profile, ['about', 'city', 'comercialName', 'country', 'email', 'gender', 'name', 'pictureUrl', 'surname', '_id', 'videoUrl', 'languages']),
                { instagram: profile.ssmm?.instagram, facebook: profile.ssmm?.facebook, web: profile.ssmm?.web, genderSelected: '' + profile?.gender }
            );

            this.setState({ profile });

            dispatch(initialize(
                'profileForm',
                initialValues
            ));

        } catch (e) { } finally {

            closeLoader();

        }

    };

    onSubmit = async (values: FormProfileInterface) => {

        const { closeLoader, openLoader, updateProfile } = this.props;

        let hasError: boolean = false;

        try {

            openLoader();

            const tempData = _.cloneDeep(values);

            const tempPictureUrlFile = _.get(window, 'pictureUrl.tempFile', {});
            if (!_.isEmpty(tempPictureUrlFile)) {

                const formData = new FormData();
                formData.append('avatar', tempPictureUrlFile.ref);

                try {

                    const response = await FilesModel.uploadImage(formData);

                    if (_.get(response,'data.url', false)) {

                        tempData.pictureUrl = response.data.url;

                    }

                } catch (error) {

                    delete tempData.pictureUrl;

                }

            }

            const tempVideoUrlFile = _.get(window, 'videoUrl.tempFile', {});
            if (!_.isEmpty(tempVideoUrlFile)) {

                const formData = new FormData();
                formData.append('fileData', tempVideoUrlFile.ref);
                formData.append('path', 'presentationVideo');

                try {

                    const response = await FilesModel.uploadFile(formData, 'video');

                    if (_.get(response, 'data.url', false)) {

                        tempData.videoUrl = response.data.url;

                    }

                } catch (error) {

                    delete tempData.videoUrl;

                }

            }

            _.set(tempData, 'ssmm.facebook', tempData.facebook);
            _.set(tempData, 'ssmm.instagram', tempData.instagram);
            _.set(tempData, 'ssmm.web', tempData.web);
            _.set(tempData, 'gender', parseInt(tempData.genderSelected || '0'));

            delete tempData.facebook;
            delete tempData.instagram;
            delete tempData.web;
            delete tempData.genderSelected;

            await updateProfile(tempData);
            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });
            hasError = true;

        } finally {

            !hasError && _.set(window, 'pictureUrl', undefined);
            !hasError && _.set(window, 'videoUrl', undefined);
            closeLoader();

        }

    }

    onRemove = (path: string) => this.props.dispatch(autofill('profileForm', path, ''));

    goBack = () => this.props.navigate('/setup');

    render() {

        const { reduxFormState } = this.props;

        const { countrySelectOptions, genderSelectOptions, languageSelectOptions } = this.state;

        return (
            <Wrapper>
                <PanelNav active='setup' />
                <SetupNav active='account' />
                <Container style={{ paddingTop: '20px' }}>
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <div className='setupAccount'>
                                            <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name='profileForm'>
                                                <Segment>
                                                    <h3>{ I18n.t('setup.personalData') }</h3>
                                                    <div className='pictureBox'>
                                                        <label className='primary-font regular'>{ I18n.t('setup.picture') }</label>
                                                        <MultimediaManage formFieldName='pictureUrl' type={ MultimediaTypes.IMAGE } src={ reduxFormState.pictureUrl } onRemove={ this.onRemove } />
                                                    </div>
                                                    <div className='videoBox'>
                                                        <label className='primary-font regular'>{ I18n.t('setup.video') }</label>
                                                        <MultimediaManage formFieldName='videoUrl' type={ MultimediaTypes.VIDEO } src={ reduxFormState.videoUrl } onRemove={ this.onRemove } />
                                                    </div>
                                                    <Form.Group widths='equal'>
                                                        <Field
                                                            component={ CustomInput }
                                                            placeholder={ I18n.t('setup.name') }
                                                            name='name'
                                                            fieldClasses='label-primary'
                                                            label={ I18n.t('setup.name') }
                                                            fluid
                                                        />
                                                        <Field
                                                            component={ CustomInput }
                                                            placeholder={ I18n.t('setup.surname') }
                                                            name='surname'
                                                            fieldClasses='label-primary'
                                                            label={ I18n.t('setup.surname') }
                                                            fluid
                                                        />
                                                    </Form.Group>
                                                    <Form.Group widths='equal'>
                                                        <Field
                                                            component={ CustomSelect }
                                                            placeholder={ I18n.t('setup.country') }
                                                            name='country'
                                                            fieldClasses='label-primary'
                                                            label={ I18n.t('setup.country') }
                                                            search={ true }
                                                            options={ countrySelectOptions }
                                                            fluid
                                                        />
                                                        <Field
                                                            component={ CustomInput }
                                                            placeholder={ I18n.t('setup.city') }
                                                            name='city'
                                                            fieldClasses='label-primary'
                                                            label={ I18n.t('setup.city') }
                                                            fluid
                                                        />
                                                    </Form.Group>
                                                    <Field
                                                        component={ CustomSelect }
                                                        placeholder={ I18n.t('setup.gender') }
                                                        name='genderSelected'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.gender') }
                                                        options={ genderSelectOptions }
                                                    />
                                                    <Field
                                                        component={ CustomTextArea }
                                                        placeholder={ I18n.t('setup.about') }
                                                        name='about'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.about') }
                                                    />
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.comercialName') }
                                                        name='comercialName'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.comercialName') }
                                                    />
                                                    <Field
                                                        component={ CustomSelect }
                                                        placeholder={ I18n.t('setup.languages') }
                                                        name='languages'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.languages') }
                                                        search={ true }
                                                        multiple={ true }
                                                        options={ languageSelectOptions }
                                                    />
                                                    <Form.Group widths='equal'>
                                                        <Field
                                                            component={ CustomInput }
                                                            placeholder={ 'Facebook' }
                                                            name='facebook'
                                                            fieldClasses='label-primary'
                                                            label={ 'Facebook' }
                                                        />
                                                        <Field
                                                            component={ CustomInput }
                                                            placeholder={ 'Instagram' }
                                                            name='instagram'
                                                            fieldClasses='label-primary'
                                                            label={ 'Instagram' }
                                                        />
                                                    </Form.Group>
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.webPage') }
                                                        name='web'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.webPage') }
                                                    />
                                                </Segment>
                                                <Segment>
                                                    <h3>{ I18n.t('setup.billingData') }</h3>
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.socialNameOrFullName') }
                                                        name='socialNameOrFullName'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.socialNameOrFullName') }
                                                        fluid
                                                    />
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.fiscalIdentificationNumber') }
                                                        name='fiscalIdentificationNumber'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.fiscalIdentificationNumber') }
                                                        fluid
                                                    />
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.billingAddress') }
                                                        name='billingAddress'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.billingAddress') }
                                                        fluid
                                                    />
                                                </Segment>
                                                <OButton $upper fluid type='submit' $color='#FFF' $terciary disabled={ this.props.invalid }>
                                                    <span>{ I18n.t('buttons.actions.save') }</span>
                                                </OButton>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='setup' />
            </Wrapper>
        );

    }

}

const validate = (formValues: FormProfileInterface) => {

    const errors = {
        name: undefined,
        surname: undefined,
        city: undefined,
        facebook: undefined,
        instagram: undefined
    };

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.city)) {

        errors.city = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.facebook) && !/^(?:(?:http|https):\/\/)?(?:www.|[a-z]{2}-[a-z]{2}.)?facebook.com\/[^ "]+?$/gi.test(formValues.facebook || '')) {

        errors.facebook = I18n.t('validations.facebookUrl');

    }

    if (!_.isEmpty(formValues.instagram) && !/^(?:(?:http|https):\/\/)?(?:www.|[a-z]{2}-[a-z]{2}.)?instagram.com\/[^ "]+?$/gi.test(formValues.instagram || '')) {

        errors.instagram = I18n.t('validations.instagramUrl');

    }

    return errors;

};

const form = reduxForm<{}, ProfileProps>({
    form: 'profileForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(ProfileData);

export default connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.profileForm.values', {}))

    return { reduxFormState };

}, { closeLoader, openLoader, openToast, updateProfile })(form);