// Third party libraries
import { Modal } from 'semantic-ui-react';

export type GenericModalProps = {
    open: boolean;
    renderActions?: () => JSX.Element;
    renderBody: () => JSX.Element;
    onClose?: () => void;
    title: string;
};

export const ClosedModal = {
    open: false,
    renderActions: undefined,
    renderBody: () => <></>,
    title: ''
};

const GenericModal = ({ open, title, renderBody, renderActions, onClose }: GenericModalProps) => {

    return (
        <Modal open={ open } size='mini' closeIcon onClose={ onClose }>
            <Modal.Header>{ title }</Modal.Header>
            <Modal.Content scrolling>
                { renderBody() }
            </Modal.Content>
            { renderActions ? <Modal.Actions>{ renderActions() }</Modal.Actions> : '' }
        </Modal>
    );

}

export default GenericModal;