// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Divider, Form, Icon, Image } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

// Components
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';
import Messager from '../../../components/messager/Messager';

// Styled components
import OButton from '../../../styled/button';

// Models
import { CredentialsInterface } from '../../../../models/security';

// Redux actions
import { registerUser } from '../../../../redux-store/auth';
import { getCurrentLicense } from '../../../../redux-store/license';
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Assets
import FlexibilityYourBusiness from '../../../assets/img/landing/flexibilityYourBusiness.png'
import GrowthUpValue from '../../../assets/img/landing/growthUpValue.png'
import IncreaseIncome from '../../../assets/img/landing/increaseIncome.png'
import RegisterBackground from '../../../assets/img/registerBackground.png';
import YogabotLogo from '../../../assets/img/YogabotPro.png';

// Interfaces
interface MapDispatchProps {
    closeLoader: any;
    getCurrentLicense: any;
    openLoader: any;
    registerUser: any;
};

interface RegisterProps extends MapDispatchProps {
    handleSubmit?: any;
    location?: any;
    navigate?: any;
    search?: any;
    tos: boolean;
};

interface RegisterState {
    codeError: number | string;
    correct: boolean;
    hasError: boolean;
    periodicity?: string;
    plan?: string;
};

interface TosUrlsInterface {
    [key: string]: string;
};

// Consts
const TOS_URLS: TosUrlsInterface = {
    es: 'https://yogabot-project.gitbook.io/pro/legal/terminos-de-uso',
    en: 'https://yogabot-project.gitbook.io/pro/v/english/legal/terms-of-use'
}

class Register extends React.Component<RegisterProps & InjectedFormProps<{}, RegisterProps>, RegisterState> {

    constructor(props: RegisterProps & InjectedFormProps<{}, RegisterProps>) {

        super(props);

        this.state = {
            codeError: -1,
            correct: false,
            hasError: false,
            periodicity: this.props.search.get('periodicity'),
            plan: this.props.search.get('plan')
        };

    }

    goToLanding = () => this.props.navigate(`/`);
    goToLogin = () => this.props.navigate(`/auth/login`);

    register = async ({ email, password }: CredentialsInterface) => {

        const { openLoader, closeLoader, getCurrentLicense, registerUser, navigate } = this.props;
        let isCorrect = false;

        try {

            openLoader();
            const formValues = { email: _.trim(email), password };
            await registerUser(formValues);
            getCurrentLicense();
            isCorrect = true;

        } catch (error) {

            this.setState({ hasError: true, codeError: 'register-repeat-email' });
            isCorrect = false;

        } finally {

            if (isCorrect) {

                const { periodicity, plan } = this.state;
                navigate(!!periodicity && !!plan ? `/auth/register/checkout?periodicity=${ periodicity }&plan=${ plan }` : '/students');

            }

            closeLoader();

        }

    }

    render() {

        const { codeError, hasError, periodicity, plan } = this.state;
        const { tos, handleSubmit } = this.props;
        const lang = I18n.languages[0];

        return <div className='register'>
            <Image src={ RegisterBackground } className='background' />
            <div className='registerTab'>
                <div className='registerForm'>
                    <OButton $upper type='button' $color='#00B595' $primary className='goBack' onClick={ this.goToLanding }>
                        <Icon name='arrow left' />
                        <span>{ I18n.t('register.returnBack') }</span>
                    </OButton>
                    <div className='header'>
                        <Image src={ YogabotLogo } className='logo' />
                        { !!periodicity && !!plan && <span className='stepText'>{ I18n.t('register.stepText', { currentStep: 1, totalSteps: 2 }) }</span> }
                        <span className='title'>{ I18n.t(!!periodicity && !!plan ? 'register.youAreAboutToStart' : 'register.title') }</span>
                        { (!!!periodicity || !!!plan) && <span className='subtitle'>{ I18n.t('register.subtitle') }</span> }
                    </div>
                    <Messager active={ hasError } code={ codeError } $variant='incorrect' />
                    <Form name='registerFillForm' noValidate onSubmit={ handleSubmit(this.register) }>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('register.email') }
                            name='email'
                            fieldClasses='y-input default small'
                            label={ I18n.t('register.email') }
                            restrictions={ [{ trim: true }] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ '' }
                            name='password'
                            type='password'
                            fieldClasses='y-input default small'
                            label={ I18n.t('register.password') }
                        />
                        <Field
                            component={ CustomCheckBox }
                            name='tos'
                            fieldClasses='y-checkbox small inline-block'
                            label={ '' }
                        />
                        <label className='font-secondary'>
                            <Trans i18nKey='register.acceptTos'>
                                <a target='_blank' rel='noopener noreferrer' href={ TOS_URLS[lang] }>&nbsp;</a>
                            </Trans>
                        </label>
                        <OButton type='submit' disabled={ !tos || this.props.invalid } fluid $upper $color='#FFF' $terciary>
                            <span>{ I18n.t('register.createAccount') }</span>
                        </OButton>
                    </Form>
                    <Divider />
                    <div className='hasAccount'>
                        <span>{ I18n.t('register.hasAccount') }</span>
                        <OButton $upper type='button' $color='#00B595' $primary onClick={ this.goToLogin }>
                            <span>{ I18n.t('register.loginNow') }</span>
                        </OButton>
                    </div>
                </div>
            </div>
            <div className='commercialTips'>
                <div className='benefit'>
                    <Image src={ FlexibilityYourBusiness } />
                    <div className='benefitText'>
                        <span className='title'>{ I18n.t('landing.flexibilityYourBusiness') }</span>
                        <br />
                        <span className='text'>{ I18n.t('landing.flexibilityYourBusinessText') }</span>
                    </div>
                </div>
                <div className='benefit'>
                    <Image src={ GrowthUpValue } />
                    <div className='benefitText'>
                        <span className='title'>{ I18n.t('landing.growthUpValue') }</span>
                        <br />
                        <span className='text'>{ I18n.t('landing.growthUpValueText') }</span>
                    </div>
                </div>
                <div className='benefit'>
                    <Image src={ IncreaseIncome } />
                    <div className='benefitText'>
                        <span className='title'>{ I18n.t('landing.increaseYourIncome') }</span>
                        <br />
                        <span className='text'>{ I18n.t('landing.increaseYourIncomeText') }</span>
                    </div>
                </div>
            </div>
        </div>;

    }

}

const validate = (formValues: CredentialsInterface) => {

    const errors: { email?: string; password?: string } = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

const selector = formValueSelector('registerFillForm');

const form = reduxForm<{}, RegisterProps>({
    form: 'registerFillForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: ''
    }
})(Register);

const RegisterForm = connect(state => {

    return { tos: selector(state, 'tos') };

})(form);

export default connect(null, { closeLoader, getCurrentLicense, openLoader, registerUser })(RegisterForm);