import _ from 'lodash';

import AxiosService from '../utils/AxiosService';
import Config from '../config';

const uploadFile = async (formData: any, fileType: string) => _.get(await AxiosService.postFile(`${ Config.server.api.filesApi }pro/${ fileType }`, formData, true), 'data', {});
const uploadImage = async (formData: any) => _.get(await AxiosService.postFile(`${ Config.server.api.filesApi }user/avatar`, formData, true), 'data', {});

export default {
    uploadFile,
    uploadImage
}