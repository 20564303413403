// Third party libraries
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, TextArea } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import StudentModel, { StudentInterface } from '../../../models/student';
import { UserGroupInterface } from '../../../models/group';

// Components
import { ClosedModal, GenericModalProps } from '../genericModal';

// Styled components
import OButton from '../../styled/button';

// Locales
import I18n from '../../../i18n';

type Props = {
    student?: StudentInterface | StudentInterface[] | UserGroupInterface[],
    onChangeModal: (modal: GenericModalProps) => void,
    onForceUpdate: () => void
};

const SendMessage = ({ student, onChangeModal, onForceUpdate }: Props) => {

    const dispatch = useDispatch();
    const [message, setMessage] = useState('');

    const handleSendMessageSubmit = async () => {

        try {

            closeModal();
            dispatch(openLoader());

            if (_.isArray(student)) { // Multiple students or group students

                for (let i = 0; i < _.size(student); i++) {

                    await StudentModel.updateStudent(_.get(student[i], 'userId', student[i]?._id), message);

                }

            } else { // Individual student

                await StudentModel.updateStudent('' + student?._id, message);

            }
            setMessage('');
            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <Form onSubmit={ handleSendMessageSubmit }>
            <Form.Field>
                <span style={{ paddingBottom: '10px', display: 'block' }}>
                    <Trans i18nKey='students.whatYouWantToSay'>
                        <strong></strong>
                    </Trans>
                </span>
                <TextArea
                    placeholder={ I18n.t('students.messagePlaceholder') }
                    style={{ minHeight: '112px', width: '100%', borderColor: '#F4F4F4', padding: '10px', resize: 'none' }}
                    onChange={ event => setMessage(event?.target.value) }
                    value={ message }
                />
            </Form.Field>
            <OButton fluid type='submit' $color='white' $terciary disabled={ _.isEmpty(message) }>
                <span>{ I18n.t('students.sendMessage') }</span>
            </OButton>
        </Form>
    );

}

export default SendMessage;