import { Form, Dropdown, Label, SemanticCOLORS } from 'semantic-ui-react';

export interface FormSelectOptionsInterface {
    key: string | number,
    text: string,
    value: any
};

interface FormSelectParamsInterface {
    fieldClasses?: string,
    label?: string,
    colorLabel?: SemanticCOLORS,
    meta: {
        touched?: boolean,
        invalid?: boolean,
        error?: string
    },
    input: {
        value?: string,
        onChange?: any
    },
    placeholder?: string,
    search?: boolean,
    options?: FormSelectOptionsInterface[],
    multiple?: boolean
};

const CustomSelect = (params: FormSelectParamsInterface) => {

    return (
        <Form.Field className={ params.fieldClasses } error={ params.meta.touched && params.meta.invalid }>
            <label>{ params.label }</label>
            <Dropdown
                value={ params.input.value || (params.multiple ? [] : '') }
                placeholder={ params.placeholder }
                selection
                search={ params.search || false }
                options={ params.options }
                className='fluid'
                onChange={ (e, { value }) => params.input.onChange(value) }
                onBlur={ (e, { value }) => params.input.onChange(value) }
                multiple={ params.multiple }
            />
            { params.meta.touched && params.meta.invalid && <Label basic color={ params.colorLabel || 'red' } pointing>{ params.meta.error }</Label> }
        </Form.Field>
    );

};

export default CustomSelect;