// Third party libraries
import Moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Divider, Icon, Segment } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

// Redux
import { getCurrentLicense } from '../../../../redux-store/license';
import { closeLoader } from '../../../../redux-store/loader';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../components/genericModal';
import PanelNav from '../../../components/nav/PanelNav';
import SetupNav from '../components/SetupNav';
import StripeCheckoutForm from '../../../components/payments/stripe';

// Models
import PaymentModel, { PeriodicityEnum, PlansEnum } from '../../../../models/payment';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Services
import PaymentPlansService, { PaymentPlanInterface } from '../../../../utils/PaymentPlansService';

// Config
import Config from '../../../../config';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

const stripePromise = loadStripe(Config.stripe.publicKey);

const Checkout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [modal, setModal] = useState<GenericModalProps>(ClosedModal);
    const [description, setDescription] = useState<string>('');
    const [periodicity] = useState<PeriodicityEnum>(searchParams.get('periodicity') as PeriodicityEnum);
    const [plan] = useState<PlansEnum>(searchParams.get('plan') as PlansEnum);
    const [type, setType] = useState<string>('');
    const [amount, setAmount] = useState<number>(0);
    const [expiredLicense, setExpiredLicense] = useState<boolean>(window.location.hash.includes('/expiredLicense/'));

    useEffect(() => {

        PaymentModel.getCurrentLicense().then(currentLicense => {

            const isExpiredLicense: boolean = !!!currentLicense.endDate || Moment(currentLicense.endDate).startOf('day').isBefore(Moment().startOf('day'));
            if (isExpiredLicense && !window.location.hash.includes('/expiredLicense/')) {

                navigate('/expiredLicense/plans');
                setExpiredLicense(true);

            }

        });

    }, []);

    const currency = 'USD';

    useEffect(() => {

        const planData: PaymentPlanInterface = PaymentPlansService.getPlanData(plan, periodicity);
        setAmount(planData.totalAmount);

        const descriptionText: string = I18n.t('setup.proPaymentFee', { plan, periodicity: I18n.t(`landing.${ periodicity }`).toLowerCase() });
        setDescription(descriptionText);

        const typeCode: string = plan + '.' + periodicity;
        setType(typeCode);

    }, []);

    const onError = () => {

        dispatch(closeLoader());

        setModal({
            open: true,
            title: I18n.t('popups.alert'),
            renderBody: () => <span>{ I18n.t('setup.paymentError') }</span>,
            renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
        });

    }

    const onSuccess = async () => {

        await dispatch(getCurrentLicense());
        dispatch(closeLoader());
        navigate('/setup/subscription');

    }

    const closeModal = () => setModal(ClosedModal);

    return (
        <Wrapper>
            <GenericModal
                open={ modal.open }
                title={ modal.title }
                renderBody={ modal.renderBody }
                renderActions={ modal.renderActions }
                onClose={ closeModal }
            />
            <PanelNav active='setup' disableMenu={ expiredLicense } />
            <SetupNav active='subscription' disableMenu={ expiredLicense } />
            <Container style={{ paddingTop: '20px' }}>
                <div className='inner'>
                    <div className='p-flex'>
                        <div className='p-content'>
                            <div className='a-wrapper'>
                                <div className='a-f-wrapper'>
                                    <div className='checkout'>
                                        <Segment>
                                            <h3>{ I18n.t('setup.paymentData') }</h3>
                                            <div className='stripe-block'>
                                                <div className='infoPayment'>
                                                    <Icon name='payment' className='paymentIcon' />
                                                    <div className='pay-info'>
                                                        <span className='title-price'>{ I18n.t('setup.totalAmount') }</span>
                                                        <span className='info-price'>{ `$${ amount }` }</span>
                                                    </div>
                                                </div>
                                                <div className='blocks-separator'>
                                                    <span className='title'>{ I18n.t('setup.payWithCreditOrDebitCard') }</span>
                                                    <Divider />
                                                    <Elements stripe={ stripePromise }>
                                                        <ElementsConsumer>
                                                            { ({ stripe, elements }) => (
                                                                <StripeCheckoutForm
                                                                    stripe={ stripe }
                                                                    elements={ elements }
                                                                    amount={ amount }
                                                                    currency={ currency }
                                                                    periodic={ true }
                                                                    description={ description }
                                                                    type={ type }
                                                                    onSuccess={ onSuccess }
                                                                    onError={ onError }
                                                                />
                                                            ) }
                                                        </ElementsConsumer>
                                                    </Elements>
                                                </div>
                                            </div>
                                        </Segment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <BottomNav active='setup' disableMenu={ expiredLicense } />
        </Wrapper>
    );

}

export default Checkout;