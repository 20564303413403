// Third party libraries
import { Divider } from 'semantic-ui-react';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

type Props = {
    lastPracticeDate: string;
    periodStartedOnDate: string;
    practiceHoursTotal: string;
    teacherPractices: string;
    otherPractices: string;
};

const PracticeInfo = ({ lastPracticeDate, periodStartedOnDate, practiceHoursTotal, teacherPractices, otherPractices }: Props) => {

    return (
        <div className='userPracticeInfo'>
            <span className='title'>{ I18n.t('students.practiceInfo') }</span>
            <div className='box'>
                <span className='title'>{ I18n.t('students.practiceInfo') }</span>
                <span className='subtitle'>{ I18n.t('students.practiceData') }</span>
                <span className='lastPracticeOn'>{ I18n.t('students.lastPracticeOn') }</span>
                <span className='lastPracticeOnDate'>{ lastPracticeDate }</span>
                <div className='periodStartedOn'>
                    <span className='lastPracticeOn'>{ I18n.t('students.lastPracticeOn') }</span>
                    <span className='periodStartedOn'>{ I18n.t('students.periodStartedOn') }</span>
                    <span className='lastPracticeOnDate'>{ lastPracticeDate }</span>
                    <span className='periodStartedOnDate'>{ periodStartedOnDate }</span>
                </div>
                <Divider />
                <div className='stats'>
                    <span className='practiceHoursTotal'>{ I18n.t('students.practiceHoursTotal') }</span>
                    <span className='teacherPractices'>{ I18n.t('students.teacherPractices') }</span>
                    <span className='otherPractices'>{ I18n.t('students.otherPractices') }</span>
                    <span className='practiceHoursTotalData'>{ practiceHoursTotal }</span>
                    <span className='teacherPracticesData'>{ teacherPractices }</span>
                    <span className='otherPracticesData'>{ otherPractices }</span>
                </div>
            </div>
        </div>
    );

};

export default PracticeInfo;