import _ from 'lodash';

import AxiosService from '../utils/AxiosService';

import Config from '../config';

export interface NewGroupInterface {
    name: string;
    description: string;
    level: string;
};

export interface GroupInterface extends NewGroupInterface {
    _id: string;
    imageUrl?: string;
    userId: string;
    studentsCount: number;
    createdAt: string;
    updatedAt: string;
};

export interface GroupSequenceInterface {
    _id: string;
    groupId: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    sequenceId: string;
    startOn?: string;
    endOn?: string;
};

export interface UserGroupInterface {
    _id: string;
    userId: string;
    groupId: string;
    createdAt: string;
    updatedAt: string;
};

const assignToSequence = async (id: string, sequenceId: string, startOn: string | undefined, endOn: string | undefined): Promise<void> => _.get(await AxiosService.post(Config.server.api.sessionsApi + 'pro/group/' + id + '/sequence/' + sequenceId, { startOn, endOn }, true), 'data', {});
const editGroup = async (payload: GroupInterface): Promise<void> => _.get(await AxiosService.put(Config.server.api.groupApi + 'pro/group/' + payload._id, payload, true), 'data', {});
const getGroup = async (id: string): Promise<GroupInterface> => _.get(await AxiosService.get(Config.server.api.groupApi + 'pro/group/' + id, true), 'data', []);
const getGroupSequences = async (id: string): Promise<GroupSequenceInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/group/' + id + '/sequence', true), 'data', []);
const getGroupStudents = async (id: string): Promise<UserGroupInterface[]> => _.get(await AxiosService.get(Config.server.api.groupApi + 'pro/group/' + id + '/student', true), 'data', []);
const getGroups = async (): Promise<GroupInterface[]> => _.get(await AxiosService.get(Config.server.api.groupApi + 'pro/group', true), 'data', []);
const newGroup = async (payload: NewGroupInterface): Promise<void> => _.get(await AxiosService.post(Config.server.api.groupApi + 'pro/group', payload, true), 'data', {});
const removeGroup = async (id: string): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.groupApi + 'pro/group/' + id, true), 'data', {});
const unassignSequence = async (id: string, sequenceId: string): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.sessionsApi + 'pro/group/' + id + '/sequence/' + sequenceId, true), 'data', {});

const exports = {
    assignToSequence,
    editGroup,
    getGroup,
    getGroupSequences,
    getGroupStudents,
    getGroups,
    newGroup,
    removeGroup,
    unassignSequence
};

export default exports;