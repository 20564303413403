// Third party libraries
import { Image } from 'semantic-ui-react';

// Styles
import './index.scss';

// Interfaces
interface ParamsInterface {
    image: any;
    imageOnLeft?: boolean;
    text: string;
    title: string;
};

const Functionality = ({ image, imageOnLeft = false, text, title }: ParamsInterface) => {

    return <div className='functionality'>
        <Image src={ image } className={ `left ${ imageOnLeft ? '' : 'hide' }` } />
        <div className='functionalityTextBox'>
            <span className='functionalityTitle'>{ title }</span>
            <br />
            <span className='functionalityText' dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        <Image src={ image } className={ `right ${ imageOnLeft ? 'hide' : '' }` } />
    </div>;

};

export default Functionality;