// Third party libraries
import { Divider, Icon } from 'semantic-ui-react';

// Styles
import './index.scss';

// Interfaces
interface ParamsInterface {
    active?: boolean;
    answer: string;
    index: number;
    onExpandCallback?: (index: number) => void;
    question: string;
};

const FaqItem = ({ active = false, answer, index, onExpandCallback = () => {}, question }: ParamsInterface) => {

    return <div className='faqItem'>
        <div className='question' onClick={ () => onExpandCallback(active ? 0 : index) }>
            <span>{ question }</span>
            <Icon className='expandIcon' name={ active ? 'angle up' : 'angle down' } />
        </div>
        <Divider />
        <div className={ `answer ${ active ? 'active' : '' }` }>
            <span dangerouslySetInnerHTML={{ __html: answer }}></span>
        </div>
    </div>;

};

export default FaqItem;