// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Container, Form, Segment } from 'semantic-ui-react';

// Redux
import { updateProfile } from '../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openToast } from '../../../../redux-store/toast';

// Models
import SecurityModel from '../../../../models/security';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import CustomInput from '../../../components/form/input';
import EditEmailForm from './components/editEmailForm';
import Messager from '../../../components/messager/Messager';
import PanelNav from '../../../components/nav/PanelNav';
import SetupNav from '../components/SetupNav';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface SecurityFormInterface {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
};

interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    updateProfile: any;
};

interface SecurityProps extends MapDispatchProps {
    handleSubmit?: any;
    invalid?: boolean;
    navigate?: any;
};

interface SecurityState {
    codeError: number | string;
    correctEmail: boolean;
    email: string;
    hasError: boolean;
    modifyEmail: boolean;
};

class Security extends React.Component<SecurityProps & InjectedFormProps<{}, SecurityProps>, SecurityState> {

    constructor(props: SecurityProps & InjectedFormProps<{}, SecurityProps>) {

        super(props);

        this.state = {
            codeError: -1,
            correctEmail: false,
            email: '',
            hasError: false,
            modifyEmail: false
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { closeLoader, openLoader } = this.props;

        openLoader();

        try {

            const profile = await SecurityModel.getProfile();
            this.setState({ email: profile.email });

        } catch (e) { } finally {

            closeLoader();

        }

    };

    onSubmit = async (values: SecurityFormInterface) => {

        const { closeLoader, openLoader } = this.props;

        try {

            openLoader();

            await SecurityModel.changePassword({ currentPassword: values.currentPassword, newPassword: values.newPassword });

            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });

        } finally {

            closeLoader();

        }

    }

    onModifyEmail = () => this.setState(prevState => ({ modifyEmail: !prevState.modifyEmail, correctEmail: false, hasError: false }));

    onSaveEmail = async (email: string) => {

        try {

            await SecurityModel.changeEmail(email);
            this.setState({ correctEmail: true, hasError: false });

        } catch (error) {

            this.setState({ hasError: true, codeError: '' + error, correctEmail: false });

        } finally {

            this.setState({ modifyEmail: false });

        }

    }

    goBack = () => this.props.navigate('/setup');

    render() {

        const { codeError, correctEmail, email, hasError, modifyEmail } = this.state;

        return (
            <Wrapper>
                <PanelNav active='setup' />
                <SetupNav active='security' />
                <Container style={{ paddingTop: '20px' }}>
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <div className='setupSecurity'>
                                            <Segment>
                                                <h3>{ I18n.t('setup.email') }</h3>
                                                <div className='emailBox'>
                                                    <span style={{ marginRight: '10px', color: 'var(--brand-primary-extra-light,#ccd1d7)' }} className='paragraph regular'>{ email }</span>
                                                    <OButton $terciary type='button' $color='#fff' size='small' onClick={ this.onModifyEmail } style={{ marginTop: '10px' }}>
                                                        <span>{ modifyEmail ? I18n.t('buttons.actions.cancel') : I18n.t('buttons.actions.editEmail') }</span>
                                                    </OButton>
                                                    {
                                                        modifyEmail && <EditEmailForm initialValues={{ email }} onSaveEmail={ this.onSaveEmail } />
                                                    }
                                                    <Messager code={ codeError } active={ hasError } />
                                                    { correctEmail &&
                                                        <div className='correctMessage'>
                                                            <span className='caption regular white'>{ I18n.t('messages.changedEmail') }</span>
                                                        </div>
                                                    }
                                                </div>
                                            </Segment>
                                            <Segment>
                                                <h3>{ I18n.t('setup.password') }</h3>
                                                <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name='securityForm'>
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.currentPassword') }
                                                        name='currentPassword'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.currentPassword') }
                                                        fluid
                                                        type='password'
                                                    />
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.newPassword') }
                                                        name='newPassword'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.newPassword') }
                                                        fluid
                                                        type='password'
                                                    />
                                                    <Field
                                                        component={ CustomInput }
                                                        placeholder={ I18n.t('setup.repeatPassword') }
                                                        name='repeatPassword'
                                                        fieldClasses='label-primary'
                                                        label={ I18n.t('setup.repeatPassword') }
                                                        fluid
                                                        type='password'
                                                    />
                                                    <OButton $upper fluid type='submit' $color='#FFF' $terciary disabled={ this.props.invalid }>
                                                        <span>{ I18n.t('buttons.actions.save') }</span>
                                                    </OButton>
                                                </Form>
                                            </Segment>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='setup' />
            </Wrapper>
        );

    }

}

const validate = (formValues: SecurityFormInterface) => {

    const errors = {
        currentPassword: undefined,
        newPassword: undefined,
        repeatPassword: undefined
    };

    if (_.isEmpty(formValues.currentPassword)) {

        errors.currentPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.repeatPassword)) {

        errors.repeatPassword = I18n.t('validations.required');

    } else if (formValues.newPassword !== formValues.repeatPassword) {

        errors.repeatPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};

const form = reduxForm<{}, SecurityProps>({
    form: 'securityForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        remember: false
    }
})(Security);

export default connect(null, { closeLoader, openLoader, openToast, updateProfile })(form);