// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { autofill, Field, InjectedFormProps, initialize, reduxForm } from 'redux-form';
import { Container, Form } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openToast } from '../../../../redux-store/toast';

// Models
import FilesModel from '../../../../models/files';
import GroupModel, { GroupInterface, NewGroupInterface, UserGroupInterface } from '../../../../models/group';
import SequenceModel, { SequenceInterface } from '../../../../models/sequence';
import StudentModel, { USER_PRO_STATUS, StudentInterface } from '../../../../models/student';

// Components
import AddSequence from './components/addSequence';
import AddStudent from './components/addStudent';
import AssignedList from '../../../components/assignedList';
import BottomNav from '../../../components/bottomNav/BottomNav';
import CustomInput from '../../../components/form/input';
import CustomSelect from '../../../components/form/select';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../components/genericModal';
import { ItemInterface } from '../../../components/list';
import MultimediaManage, { MultimediaTypes } from '../../../components/multimediaManage';
import PanelNav from '../../../components/nav/PanelNav';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../components/nav/SecondarySubmenu';
import SendMessage from '../../../components/sendMessage';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Utils
import HooksService from '../../../../utils/HooksService';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    reduxFormState: any;
    dispatch?: any;
};

interface GroupEditProps extends MapDispatchProps {
    handleSubmit?: any;
    invalid?: boolean;
    navigate?: any;
    dispatch?: any;
    router?: any;
};

interface GroupEditState {
    assignedSequenceList: ItemInterface[];
    assignedStudentList: ItemInterface[];
    breadcrumbs: BreadcrumbsInterface[];
    group?: NewGroupInterface;
    groupId?: string;
    groupLevels: {
        key: string,
        text: string,
        value: string
    }[],
    groupStudents: UserGroupInterface[];
    modal: GenericModalProps;
    sequences: SequenceInterface[];
    students: StudentInterface[];
};

class GroupEdit extends React.Component<GroupEditProps & InjectedFormProps<{}, GroupEditProps>, GroupEditState> {

    constructor(props: GroupEditProps & InjectedFormProps<{}, GroupEditProps>) {

        super(props);

        this.state = {
            assignedSequenceList: [],
            assignedStudentList: [],
            breadcrumbs: [],
            group: undefined,
            groupId: this.props.router.params.groupId,
            groupLevels: [{
                key: '00',
                text: I18n.t('groups.levels.00'),
                value: '00'
            }, {
                key: '01',
                text: I18n.t('groups.levels.01'),
                value: '01'
            }, {
                key: '02',
                text: I18n.t('groups.levels.02'),
                value: '02'
            }, {
                key: '03',
                text: I18n.t('groups.levels.03'),
                value: '03'
            }, {
                key: '04',
                text: I18n.t('groups.levels.04'),
                value: '04'
            }],
            groupStudents: [],
            modal: ClosedModal,
            sequences: [],
            students: []
        };

    }

    componentDidMount() {

        this.getData();

    }

    getSequencePeriod = (startOn?: string, endOn?: string) => {

        let periodText = _.isEmpty(startOn) ? '' : I18n.t('sequences.from') + Moment(startOn).format('DD/MM/YYYY');
        periodText += (_.isEmpty(startOn) || _.isEmpty(endOn)) ? '' : ' - ';
        periodText += _.isEmpty(endOn) ? '' : I18n.t('sequences.to') + Moment(endOn).format('DD/MM/YYYY');
        return periodText;

    }

    getData = async () => {

        const { openLoader, closeLoader, dispatch } = this.props;

        dispatch(openLoader());

        let initialValues = {
            description: '',
            level: '00',
            name: ''
        };

        const { groupId } = this.state;

        if (!_.isEmpty(groupId)) {

            initialValues = await GroupModel.getGroup('' + groupId);
            const groupStudents = await GroupModel.getGroupStudents('' + groupId);
            const students = await StudentModel.getStudents();

            const assignedStudentList: ItemInterface[] = [];
            _.each(groupStudents, studentGroup => {

                const student: StudentInterface | undefined = _.find(students, item => item._id === studentGroup.userId);
                assignedStudentList.push({
                    _id: '' + student?._id,
                    description: student?.surname || '',
                    header: student?.name || '',
                    isAvatar: true,
                    pictureUrl: student?.pictureUrl,
                    selectable: false,
                    selected: false
                });

            });

            const groupSequences = await GroupModel.getGroupSequences('' + groupId);
            const sequences = await SequenceModel.getSequences();
            const assignedSequenceList: ItemInterface[] = [];
            _.each(groupSequences, sequenceGroup => {

                const sequence: SequenceInterface | undefined = _.find(sequences, item => item._id === sequenceGroup.sequenceId);
                assignedSequenceList.push({
                    _id: '' + sequence?._id,
                    description: this.getSequencePeriod(sequenceGroup.startOn, sequenceGroup.endOn),
                    header: sequence?.title?.ES || '',
                    isAvatar: false,
                    pictureUrl: sequence?.thumbUrl,
                    selectable: false,
                    selected: false
                });

            });

            const breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.groups'),
                goTo: '/groups'
            },
            {
                key: 1,
                label: initialValues.name,
                goTo: ''
            }];

            this.setState({ assignedSequenceList, assignedStudentList, breadcrumbs, group: initialValues, groupStudents, sequences, students });

        } else {

            const breadcrumbs = [{
                key: 0,
                label: I18n.t('menu.groups'),
                goTo: '/groups'
            },
            {
                key: 1,
                label: I18n.t('groups.newGroup'),
                goTo: ''
            }];

            this.setState({ breadcrumbs });

        }

        dispatch(initialize(
            'groupForm',
            initialValues
        ));

        dispatch(closeLoader());

    };

    onSubmit = async (values: GroupInterface) => {

        const { openLoader, closeLoader, dispatch } = this.props;

        dispatch(openLoader());

        const { groupId } = this.state;

        const tempData = _.cloneDeep(values);

        let hasError = false;
        try {

            if (groupId) {

                const tempPictureUrlFile = _.get(window, 'imageUrl.tempFile', {});
                if (!_.isEmpty(tempPictureUrlFile)) {

                    const formData = new FormData();
                    formData.append('fileData', tempPictureUrlFile.ref);
                    formData.append('path', 'group/' + groupId);

                    try {

                        const response = await FilesModel.uploadFile(formData, 'image');

                        if (_.get(response,'data.url', false)) {

                            tempData.imageUrl = response.data.url;

                        }

                    } catch (error) {

                        delete tempData.imageUrl;

                    }

                }

                await GroupModel.editGroup(tempData);

            } else {

                await GroupModel.newGroup(tempData);

            }
            this.goBack();

        } catch (error) {

            openToast({ message: I18n.t(`messages.saveError`), type: 'error' });
            hasError = true;

        } finally {

            !hasError && _.set(window, 'imageUrl', undefined);
            dispatch(closeLoader());

        }

    }

    onSendMessage = () => {

        const { groupStudents } = this.state;

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.sendMessage'),
                renderBody: () => <SendMessage
                    student={ groupStudents }
                    onForceUpdate={ this.getData }
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                />
            }
        });

    };

    goBack = () => this.props.navigate('/groups');

    closeModal = () => this.setState({ modal: ClosedModal });

    onRemove = (path: string) => this.props.dispatch(autofill('groupForm', path, ''));

    onAddStudent = () => {

        const { assignedStudentList, groupId, students } = this.state;

        const unlinkedStudents: StudentInterface[] = [];

        _.each(students, student => {

            const exists = !!_.find(assignedStudentList, item => item._id === student._id);
            if (!exists && student.status === USER_PRO_STATUS.LINKED) {

                unlinkedStudents.push(student);

            }

        });

        this.setState({
            modal: {
                open: true,
                title: I18n.t('groups.addStudents'),
                renderBody: () => <AddStudent
                    groupId={ '' + groupId }
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                    onForceUpdate={ () => this.getData() }
                    students={ unlinkedStudents }
                />
            }
        });

    };

    onAssignSequence = () => {

        const { assignedSequenceList, groupId, sequences } = this.state;

        const unlinkedSequences: SequenceInterface[] = [];

        _.each(sequences, sequence => {

            if (!!!_.find(assignedSequenceList, item => item._id === sequence._id)) {

                unlinkedSequences.push(sequence);

            }

        });

        this.setState({
            modal: {
                open: true,
                title: I18n.t('groups.addSequence'),
                renderBody: () => <AddSequence
                    groupId={ '' + groupId }
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                    onForceUpdate={ () => this.getData() }
                    sequences={ unlinkedSequences }
                />
            }
        });

    };

    onRemoveSequence = async (item: ItemInterface) => {

        this.props.openLoader();

        try {

            await GroupModel.unassignSequence('' + this.state.groupId, item._id);
            this.getData();

        } catch (e) {

            this.props.closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

    };

    onRemoveStudent = async (item: ItemInterface) => {

        this.props.openLoader();

        try {

            await StudentModel.unassignGroup(item._id, '' + this.state.groupId);
            this.getData();

        } catch (e) {

            this.props.closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

    };

    render() {

        const { reduxFormState } = this.props;
        const { assignedSequenceList, assignedStudentList, breadcrumbs, groupId, groupLevels, modal } = this.state;

        return (
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='groups' />
                <Container style={{ paddingTop: '20px' }}>
                    <SecondarySubmenu
                        title={ I18n.t('groups.groupInfo') }
                        mainFn={{ callback: this.goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                        breadcrumbs={ breadcrumbs }
                        secondaryFn={{ callback: this.onSendMessage, icon: 'mail outline' }}
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <div className='groupEdit'>
                                            { _.isEmpty(groupId) ? <></> :
                                                <div className='pictureBox'>
                                                    <label className='primary-font regular'>{ I18n.t('groups.picture') }</label>
                                                    <MultimediaManage formFieldName='imageUrl' type={ MultimediaTypes.IMAGE } src={ reduxFormState.imageUrl } onRemove={ this.onRemove } />
                                                </div>
                                            }
                                            <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name='groupForm'>
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('groups.name') }
                                                    name='name'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('groups.name') }
                                                />
                                                <Field
                                                    component={ CustomInput }
                                                    placeholder={ I18n.t('groups.description') }
                                                    name='description'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('groups.description') }
                                                />
                                                <Field
                                                    component={ CustomSelect }
                                                    placeholder={ I18n.t('groups.level') }
                                                    name='level'
                                                    fieldClasses='label-primary'
                                                    label={ I18n.t('groups.level') }
                                                    options={ groupLevels }
                                                />
                                                <OButton $upper fluid type='submit' $color='#FFF' $terciary disabled={ this.props.invalid }>
                                                    <span>{ I18n.t('buttons.actions.save') }</span>
                                                </OButton>
                                            </Form>
                                            { _.isEmpty(groupId) ? <></> : <>
                                                <AssignedList
                                                    items={ assignedStudentList }
                                                    title={ I18n.t('groups.members') }
                                                    onClickItemFn={ (item: ItemInterface) => this.props.navigate('/students/edit/' + item._id) }
                                                    onAddNewItemFn={ this.onAddStudent }
                                                    onAddNewItemLabel={ I18n.t('groups.addStudents') }
                                                    itemFns={[{ icon: 'trash alternate outline', callback: this.onRemoveStudent }]}
                                                />
                                                <AssignedList
                                                    title={ I18n.t('groups.sequences') }
                                                    items={ assignedSequenceList }
                                                    onClickItemFn={ (item: ItemInterface) => this.props.navigate('/sequences/edit/' + item._id) }
                                                    onAddNewItemFn={ this.onAssignSequence }
                                                    onAddNewItemLabel={ I18n.t('groups.addSequence') }
                                                    itemFns={[{ icon: 'trash alternate outline', callback: this.onRemoveSequence }]}
                                                />
                                            </> }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='groups' />
            </Wrapper>
        );

    }

}

const validate = (formValues: NewGroupInterface) => {

    const errors = {
        name: undefined
    };

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    return errors;

};

const form = reduxForm<{}, GroupEditProps>({
    form: 'groupForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(GroupEdit);

export default HooksService.withRouter(connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.groupForm.values', {}))

    return { reduxFormState };

}, { openLoader, closeLoader })(form));