// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';

// Components
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Models
import { PeriodicityEnum, PlansEnum } from '../../../../../models/payment';

// Services
import PaymentPlansService, { PaymentPlanInterface } from '../../../../../utils/PaymentPlansService';

// Styles
import './index.scss';

// Interfaces
interface ParamsInterface {
    contractCallback?: () => void;
    expiredCountdown: boolean;
    periodicity: PeriodicityEnum;
    plan: PlansEnum;
};

const PlanCard = ({ contractCallback = () => {}, expiredCountdown, periodicity, plan }: ParamsInterface) => {

    const [data, setData] = useState<PaymentPlanInterface>(PaymentPlansService.getPlanData(plan, periodicity));

    useEffect(() => {

        setData(PaymentPlansService.getPlanData(plan, periodicity));

    }, [expiredCountdown, periodicity, plan]);

    return <div className={ `planCard ${ data.discount === 0 ? 'hideRibbon' : '' }` } data-ribbon={ I18n.t('landing.discount', { discount: data.discount }).toUpperCase() }>
        <Image src={ data.logo } />
        <span className='planTitle'>{ plan }</span>
        <span className='manageText'>{ I18n.t('landing.manageUpToStudents', { maxStudents: data.maxStudentsToManage }) }</span>
        { data.newPlanPrice !== data.planPrice && <span className='oldPrice'>{ '$' + data.planPrice }</span> }
        <div className='priceBox'>
            <span className='newPrice'>{ '$' + data.newPlanPrice }</span><span className='periodicity'>{ '/' + data.periodicityText }</span>
        </div>
        <span className='forEver'>{ I18n.t(periodicity === PeriodicityEnum.Annual ? 'landing.uniqPayment' : data.discount !== 0 ? 'landing.forEver' : '') }</span>
        <OButton $terciary type='button' $color='#fff' className='contractButton' onClick={ contractCallback }>
            <span>{ I18n.t('landing.contract') }</span>
        </OButton>
    </div>;

};

export default PlanCard;