// Third party libraries
import _ from 'lodash';
import { Component } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

// Components
import CustomInput from '../../../../components/form/input';

// Styled components
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Interfaces
interface ProfileInterface {
    email: string;
};

interface ProfileEmailProps {
    handleSubmit?: any;
    invalid?: boolean;
    onSaveEmail: (email: string) => void;
    initialValues: ProfileInterface;
};

class EditEmailForm extends Component<ProfileEmailProps & InjectedFormProps<{}, ProfileEmailProps>> {

    render() {

        return <Form name='profileEmailForm' noValidate onSubmit={ this.props.handleSubmit(this.props.onSaveEmail) } style={{ marginTop: '10px' }}>
            <Field
                component={ CustomInput }
                name='email'
                label={ I18n.t('setup.email') }
                value={ this.props.initialValues.email }
            />
            <OButton $terciary type='submit' $color='#fff' size='small' fluid>
                <span>{ I18n.t('buttons.actions.save' )}</span>
            </OButton>
        </Form>;

    }

}

const validate = (formValues: ProfileInterface) => {

    const errors = {
        email: undefined
    };

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    return errors;

};

export default reduxForm<{}, ProfileEmailProps>({
    form: 'profileEmailForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(EditEmailForm);