// Third party libraries
import _ from 'lodash';
import React from 'react';
import Moment from 'moment';
import { Divider } from 'semantic-ui-react';

// Models
import { StudentInterface } from '../../../../../../models/student';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

type Props = {
    student?: StudentInterface
};

const Messages = ({ student }: Props) => {

    //TODO: Make a new page for messages historic and only show last three here

    return <div className='userMessages'>
        <span className='title'>{ I18n.t('students.messages') }</span>
        <div className='box'>
            { _.size(student?.messages) === 0 ? <><span className='subtitle'>{ I18n.t('students.withoutMessages') }</span><Divider /></> :
                student?.messages.map(message => <React.Fragment key={ message.createdAt }>
                    <span className='subtitle'>{ Moment(message.createdAt).format('DD/MM/YYYY - HH:mm') }</span>
                    <span className='text'>{ message.text }</span>
                    <Divider />
                </React.Fragment>)
            }
        </div>
    </div>;

}

export default Messages;