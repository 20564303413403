// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import { Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import { List } from 'semantic-ui-react';

// Models
import { StudentInterface } from '../../../../../../models/student';

// Locales
import I18n from '../../../../../../i18n';

// Styles
import './index.scss';

type Props = {
    student?: StudentInterface
};

const StudentInfo = ({ student }: Props) => {

    const [userObjective, setUserObjective] = useState<string>('');
    const [userInjuries, setUserInjuries] = useState<string[]>([]);

    useEffect(() => {

        let userObjective = I18n.t('students.withoutObjectives');
        const userObjectivesCoded = _.get(student, 'pro.objective');
        if (!_.isEmpty(userObjectivesCoded)) {

            const hasSecondLevel = userObjectivesCoded.includes('.');
            const firstLevel = hasSecondLevel ? userObjectivesCoded.substring(0, userObjectivesCoded.indexOf('.')) : userObjectivesCoded;
            userObjective = I18n.t(`objectivesDescriptions.${ firstLevel }.label`);
            if (hasSecondLevel) {

                userObjective += ' - ' + I18n.t(`objectivesDescriptions.${ firstLevel }.childs.${ userObjectivesCoded.substring(userObjectivesCoded.indexOf('.') + 1) }`);

            }

        }

        setUserObjective(userObjective);

    }, [student]);

    useEffect(() => {

        let userInjuries: string[] = [];
        const userInjuriesCoded = student?.pro?.injuries || [];
        if (!_.isEmpty(userInjuriesCoded)) {

            userInjuries = [];
            _.each(userInjuriesCoded, userInjuryCoded => {

                const firstLevel = userInjuryCoded.substring(0, userInjuryCoded.indexOf('.'));
                let secondLevel = userInjuryCoded.substring(userInjuryCoded.indexOf('.') + 1);
                const hasThirdLevel = secondLevel.includes('.');
                let thirdLevel = '';
                if (hasThirdLevel) {

                    thirdLevel = secondLevel.substring(secondLevel.indexOf('.') + 1);
                    secondLevel = secondLevel.substring(0, secondLevel.indexOf('.'));

                }

                userInjuries.push(
                    I18n.t(`injuriesDescriptions.${ firstLevel }.label`) + ' - ' +
                    I18n.t(`injuriesDescriptions.${ firstLevel }.childs.${ secondLevel }.label`) +
                    (hasThirdLevel ? ' - ' + I18n.t(`injuriesDescriptions.${ firstLevel }.childs.${ secondLevel }.childs.${ thirdLevel }`) : '')
                );

            });
            setUserInjuries(userInjuries);

        }

    }, [student]);

    return (
        <div className='userStudentInfo'>
            <span className='title'>{ I18n.t('students.infoOf', { studentName: student?.name }) }</span>
            <span className='updatedAt'>
                <Trans i18nKey='students.updatedAt'>
                    <strong>{ Moment(_.isEmpty(student?.pro?.updatedAt) ? student?.createdAt : student?.pro?.updatedAt).format('DD/MM/YYYY - HH:mm') }</strong>
                </Trans>
            </span>
            <div className='box'>
                <span className='subtitle'>{ I18n.t('students.injuries') }</span>
                { _.isEmpty(userInjuries) ? <span>{ I18n.t('students.withoutInjuries') }</span> :
                    <List bulleted>
                        {  _.map(userInjuries, userInjury => <List.Item key={ userInjury }>{ userInjury }</List.Item>) }
                    </List>
                }
                <span className='subtitle' style={{ marginTop: '15px' }}>{ I18n.t('students.objectives') }</span>
                <span>{ userObjective }</span>
                <span className='subtitle' style={{ marginTop: '15px' }}>{ I18n.t('students.observations') }</span>
                <span>{ !_.isEmpty(student?.pro?.observations) ? student?.pro?.observations : I18n.t('students.withoutObservations') }</span>
            </div>
        </div>
    );

}

export default StudentInfo;